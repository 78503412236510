import React from 'react';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/Header';
import Insurance from './Insurance';

const InsurancePages = () => {
    return (
        <>
            <HomeHeader/>
            <Insurance/>
            <Footer/>
        </>
    );
};

export default InsurancePages;