import React, { useState, useEffect, useContext } from 'react';
import SingleSecondShop from '../../../../components/SingleSecondShop/SingleSecondShop';
import SingleShop from '../../../../components/SingleShop/SingleShop';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../../config";
import { ReactSession } from 'react-client-session';

import { UserContext } from '../../../LayoutPages/Header/UserContext';

const ShopBanner = () => {

   const { getWishlistTrigger, setGetWishlistTrigger,getAddToCartTrigger,setGetAddToCartTrigger } = useContext(UserContext);

   const [productCount, setProductCount] = useState(0);
   const [pageinationCount, setPageinationCount] = useState(0);
   const [getProductDetails, setGetProductDetails] = useState([]);
   const [getProductDetailsFilter, setGetProductDetailsFilter] = useState([]);

   const [isLoading, setIsLoading] = useState(true);

   let { currentpage } = useParams();
   let { startwith } = useParams();
   let { category } = useParams();
   let { subcategory } = useParams();
   let { producttype } = useParams();
   let { brand } = useParams();
   let { producttag } = useParams();

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");

   /**
    * let chars = ['A', 'B', 'A', 'C', 'B'];
let uniqueChars = [...new Set(chars)];

console.log(uniqueChars);
    */

   const [getSubCategoryDetails, setGetSubCategoryDetails] = useState([]);
   const [getProductTypeDetails, setGetProductTypeDetails] = useState([]);
   const [getBrandDetails, setGetBrandDetails] = useState([]);
   const [getProductTagsDetails, setGetProductTagsDetails] = useState([]);

   const [subCategoryChecked, setSubCategoryChecked] = useState([]);
   const [productTypeChecked, setProductTypeChecked] = useState([]);
   const [brandChecked, setBrandChecked] = useState([]);
   const [productTagsChecked, setProductTagsChecked] = useState([]);

   const [pageinationLoad, setPageinationLoad] = useState([]);
   const [productTypeLoad, setProductTypeLoad] = useState([]);

   const [getWishlistDetail, setGetWishlistDetail] = useState([]);
   const [getShopingCartDetail, setGetShopingCartDetail] = useState([]);

   const handleKeyDown = (event) => {
      console.log('A key was pressed', event.keyCode);
    };

   useEffect(() => {
      PageReload(currentpage);
      GetFilterBrand();
      GetFilterProductTags();
      GetFilterSubCategory();
      GetFilterProductType();
      setIsLoading(false);
      GetWishList(sessionUserId, sessionToken);
      GetShopingCart(sessionUserId, sessionToken);

      window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

   }, [])

   const PageReload = pageno => {
      getProductPageCountByFilter(pageno, brand, producttag, subcategory, producttype);
      getProductPageByFilter(pageno, brand, producttag, subcategory, producttype);
   };

   const [showFilter, setShowFilter] = useState(false);

   const handleHideShow = () => {

      if (showFilter) {
         setShowFilter(false);
      }
      else {
         setShowFilter(true);
      }

   };

   const brandFilterLoad = bfilter => {

      var brandFilterContent = [];
      var brandChecked = brand;

      var brandCheckedValue = [];
      if (brandChecked == 0) {
         brandCheckedValue = [];
      }
      else if (brandChecked != 0) {
         brandCheckedValue = brandChecked.split(",");
      }

      if (!brandCheckedValue || brandCheckedValue == "undefined") {
         brandCheckedValue = brandChecked;
      }

      if (brandCheckedValue.length == 0) {

         for (var j = 0; j < bfilter.length; j++) {

            brandFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{bfilter[j].brand.brandname} <input type="checkbox" value={bfilter[j].brand._id} onChange={brandHandleCheck} /> <span className="check"></span> </label></div>);

         }

      }
      else {

         for (var j = 0; j < bfilter.length; j++) {

            var numToString = bfilter[j]._id.toString();
            var flag = 0;

            for (var i = 0; i < brandCheckedValue.length; i++) {

               if (brandCheckedValue[i] == numToString) {
                  flag = 1;
               }

            }
            if (flag == 1) {
               brandFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{bfilter[j].brand.brandname} <input type="checkbox" value={bfilter[j].brand._id} onChange={brandHandleCheck} checked /> <span className="check"></span> </label></div>);
            }
            else {
               brandFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{bfilter[j].brand.brandname} <input type="checkbox" value={bfilter[j].brand._id} onChange={brandHandleCheck} /> <span className="check"></span> </label></div>);
            }

         }

      }

      return brandFilterContent;

   }

   const productTagFilterLoad = ptfilter => {

      var productFilterContent = [];
      var producttagChecked = producttag;

      var ptCheckedValue = [];
      if (producttagChecked == 0) {
         ptCheckedValue = [];
      }
      else if (producttagChecked != 0) {
         ptCheckedValue = producttagChecked.split(",");
      }

      if (!ptCheckedValue || ptCheckedValue == "undefined") {
         ptCheckedValue = producttagChecked;
      }

      if (ptCheckedValue.length == 0) {

         for (var j = 0; j < ptfilter.length; j++) {

            productFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttag.producttags} <input type="checkbox" value={ptfilter[j].producttag._id} onChange={productTagsHandleCheck} /> <span className="check"></span> </label></div>);

         }

      }
      else {

         for (var j = 0; j < ptfilter.length; j++) {

            var numToString = ptfilter[j].producttag._id.toString();
            var flag = 0;

            for (var i = 0; i < ptCheckedValue.length; i++) {

               if (ptCheckedValue[i] == numToString) {
                  flag = 1;
               }

            }
            if (flag == 1) {
               productFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttag.producttags} <input type="checkbox" value={ptfilter[j].producttag._id} onChange={productTagsHandleCheck} checked /> <span className="check"></span> </label></div>);
            }
            else {
               productFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttag.producttags} <input type="checkbox" value={ptfilter[j].producttag._id} onChange={productTagsHandleCheck} /> <span className="check"></span> </label></div>);
            }

         }

      }

      return productFilterContent;

   }

   const PageinationDatas = productCountData => {
      var startingpage = parseInt(parseInt(startwith) - parseInt(1));
      var startingpage2 = parseInt(parseInt(startwith) + parseInt(1));

      var productPage2 = currentpage;
      let pageinationContent = [];

      if (!productPage2) {
         productPage2 = 1;
      }

      if (productPage2 != 1) {
         var reverse = 0;
         if (startingpage == 0 || startingpage < 0) {
            reverse = 1;
         }
         else {
            reverse = startingpage;
         }
         pageinationContent.push(<li key="00"><a href={"/shop/" + (productPage2 - 1) + "/" + reverse + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + producttag}><i className="fas fa-angle-double-left"></i></a></li>);
      }

      for (var i = startingpage; i < productCountData; i++) {

         if ((productPage2 - 1) == i) {

            pageinationContent.push(<li key={i} className="active"><a href={"/shop/" + (i + 1) + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + producttag}>{i + 1}</a></li>);

         }
         else {

            pageinationContent.push(<li key={i}><a href={"/shop/" + (i + 1) + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + producttag}>{(i + 1)}</a></li>);

         }

         if (i == 4) {
            break;
         }

      }

      if (productPage2 != i) {
         pageinationContent.push(<li key="01"><i className="fas fa-ellipsis-h"></i></li>);
         pageinationContent.push(<li key="02"><a href={"/shop/" + parseInt(parseInt(productPage2) + parseInt(1)) + "/" + startingpage2 + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + producttag}><i className="fas fa-angle-double-right"></i></a></li>);
      }

      return pageinationContent;
   }

   const GetFilterBrand = () => {

      axios
         .get(`${userURL}/product/getProductBrandForFilter`)
         .then(data => {
            setGetBrandDetails(data.data.data);
         })
         .catch(error => {

         });

   };

   const GetFilterProductTags = () => {

      axios
         .get(`${userURL}/product/getProductTagsForFilter`)
         .then(data => {
            setGetProductTagsDetails(data.data.data);
         })
         .catch(error => {

         });

   };

   const brandHandleCheck = (event) => {

      if (event.target.checked) {
         brandChecked.push(event.target.value);
      } else {
         var index = brandChecked.indexOf(event.target.value);
         if (index > -1) {
            brandChecked.splice(index, 1);
         }
      }

      var loadBrand = 0

      if (brandChecked.length == 0) {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + 0 + "/" + producttag);
         loadBrand = 0;
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + 0 + "/" + producttag;
      }
      else {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brandChecked.toString() + "/" + producttag);
         loadBrand = brandChecked.toString();
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brandChecked + "/" + producttag;
      }

      getProductPageCountByFilter(currentpage, loadBrand, producttag, subcategory, producttype);
      getProductPageByFilter(currentpage, loadBrand, producttag, subcategory, producttype);

      brand = loadBrand;

   };

   const subcategoryFilterById = text => {
         
         //if(text.length != 0) {

            //for(var i=0;i<text.length;i++) {
               setGetProductDetails(getProductDetailsFilter.filter(j => j.subcategoryrefid.includes(text)));
           // }

         //}

   }

   const productTagsHandleCheck = (event) => {

      if (event.target.checked) {
         productTagsChecked.push(event.target.value);
      } else {
         var index = productTagsChecked.indexOf(event.target.value);
         if (index > -1) {
            productTagsChecked.splice(index, 1);
         }
      }

      var loadProductTag = 0

      if (productTagsChecked.length == 0) {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + 0);
         loadProductTag = 0;
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + 0;
      }
      else {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + productTagsChecked.toString());
         loadProductTag = productTagsChecked.toString();
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + producttype + "/" + brand + "/" + productTagsChecked;
      }

      getProductPageCountByFilter(currentpage, brand, loadProductTag, subcategory, producttype);
      getProductPageByFilter(currentpage, brand, loadProductTag, subcategory, producttype);

      producttag = loadProductTag;

   };

   // Product Load By Filter

   const getProductPageByFilter = (page, brandChecked, productTagsChecked, subcategoryChecked, productTypeChecked) => {

      var productPage = page;

      if (!productPage) {
         productPage = 1;
      }

      var productPageDatas = (productPage - 1) * 9;

      var brandCheckedValue = [];
      if (brandChecked == 0) {
         brandCheckedValue = [];
      }
      else if (brandChecked != 0) {
         brandCheckedValue = brandChecked.split(",");
      }

      if (!brandCheckedValue || brandCheckedValue == "undefined") {
         brandCheckedValue = brandChecked;
      }

      var productTagsCheckedValue = [];
      if (productTagsChecked == 0) {
         productTagsCheckedValue = [];
      } else if (productTagsChecked != 0) {
         productTagsCheckedValue = productTagsChecked.split(",");
      }

      if (!productTagsCheckedValue || productTagsCheckedValue == "undefined") {
         productTagsCheckedValue = productTagsChecked;
      }

      var subcategoryCheckedValue = [];
      if (subcategoryChecked == 0) {
         subcategoryCheckedValue = [];
      } else if (subcategoryChecked != 0) {
         subcategoryCheckedValue = subcategoryChecked.split(",");
      }

      if (!subcategoryCheckedValue || subcategoryCheckedValue == "undefined") {
         subcategoryCheckedValue = subcategoryChecked;
      }

      var producttypeCheckedValue = [];
      if (productTypeChecked == 0) {
         producttypeCheckedValue = [];
      } else if (productTypeChecked != 0) {
         producttypeCheckedValue = productTypeChecked.split(",");
      }

      if (!producttypeCheckedValue || producttypeCheckedValue == "undefined") {
         producttypeCheckedValue = productTypeChecked;
      }

      var productDetail = {
         page: productPageDatas,
         brandChecked: brandCheckedValue,
         productTagsChecked: productTagsCheckedValue,
         productTypeChecked: producttypeCheckedValue,
         subCategoryChecked: subcategoryCheckedValue,
         category: category
      };

      axios
         .post(`${userURL}/product/getProductByFilter`, productDetail)
         .then(data => {
            setGetProductDetails(data.data.data);
            setGetProductDetailsFilter(data.data.data);
            console.log(data.data.data);
         })
         .catch(error => {

         });

   };

   // Get Pageination and Product Count Function 

   const getProductPageCountByFilter = (page, brandChecked, productTagsChecked, subcategoryChecked, productTypeChecked) => {

      var productPage = page;

      if (!productPage) {
         productPage = 1;
      }

      var productPageDatas = (productPage - 1) * 9;

      var brandCheckedValue = [];
      if (brandChecked == 0) {
         brandCheckedValue = [];
      }
      else if (brandChecked != 0) {
         brandCheckedValue = brandChecked.split(",");
      }

      if (!brandCheckedValue || brandCheckedValue == "undefined") {
         brandCheckedValue = brandChecked;
      }

      var productTagsCheckedValue = [];
      if (productTagsChecked == 0) {
         productTagsCheckedValue = [];
      } else if (productTagsChecked != 0) {
         productTagsCheckedValue = productTagsChecked.split(",");
      }

      if (!productTagsCheckedValue || productTagsCheckedValue == "undefined") {
         productTagsCheckedValue = productTagsChecked;
      }

      var subcategoryCheckedValue = [];
      if (subcategoryChecked == 0) {
         subcategoryCheckedValue = [];
      } else if (subcategoryChecked != 0) {
         subcategoryCheckedValue = subcategoryChecked.split(",");
      }

      if (!subcategoryCheckedValue || subcategoryCheckedValue == "undefined") {
         subcategoryCheckedValue = subcategoryChecked;
      }

      var producttypeCheckedValue = [];
      if (productTypeChecked == 0) {
         producttypeCheckedValue = [];
      } else if (productTypeChecked != 0) {
         producttypeCheckedValue = productTypeChecked.split(",");
      }

      if (!producttypeCheckedValue || producttypeCheckedValue == "undefined") {
         producttypeCheckedValue = productTypeChecked;
      }


      var productDetail = {
         page: productPageDatas,
         brandChecked: brandCheckedValue,
         productTagsChecked: productTagsCheckedValue,
         productTypeChecked: producttypeCheckedValue,
         subCategoryChecked: subcategoryCheckedValue,
         category: category
      };

      axios
         .post(`${userURL}/product/getProductPageCountByFilter`, productDetail)
         .then(data => {
            setProductCount(data.data.count);
            setPageinationCount(data.data.pageCount);
            setPageinationLoad(PageinationDatas(data.data.pageCount));
         })
         .catch(error => {

         });
   };

   // Get All Sub Category Filter

   const GetFilterSubCategory = () => {
      var productDetail = {
         category: category
      };

      axios
         .post(`${userURL}/product/getSubCategoryFilter`, productDetail)
         .then(data => {
            setGetSubCategoryDetails(data.data.data);
         })
         .catch(error => {

         });

   };

   // Get All Product Type Filter

   const GetFilterProductType = () => {

      var productDetail = {
         category: category
      };

      axios
         .post(`${userURL}/product/getProductTypeFilter`, productDetail)
         .then(data => {
            setProductTypeLoad(productTypeFilterLoad(data.data.data));
         })
         .catch(error => {

         });

   };

   // Get All Product Type Filter Load

   const productTypeFilterLoad = ptfilter => {

      var productTypeFilterContent = [];
      var productypeChecked = producttype;

      var ptCheckedValue = [];
      if (productypeChecked == 0) {
         ptCheckedValue = [];
      }
      else if (productypeChecked != 0) {
         ptCheckedValue = productypeChecked.split(",");
      }

      if (!ptCheckedValue || ptCheckedValue == "undefined") {
         ptCheckedValue = productypeChecked;
      }

      if (ptCheckedValue.length == 0) {
         for (var j = 0; j < ptfilter.length; j++) {

            productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype} <input type="checkbox" value={ptfilter[j]._id} onChange={productTypeHandleCheck} /> <span className="check"></span> </label></div>);

         }

      }
      else {
         for (var j = 0; j < ptfilter.length; j++) {

            var numToString = ptfilter[j]._id.toString();
            var flag = 0;

            for (var i = 0; i < ptCheckedValue.length; i++) {

               if (ptCheckedValue[i] == numToString) {
                  flag = 1;
               }

            }
            if (flag == 1) {
               productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype} <input type="checkbox" value={ptfilter[j]._id} onChange={productTypeHandleCheck} checked /> <span className="check"></span> </label></div>);
            }
            else {
               productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype} <input type="checkbox" value={ptfilter[j]._id} onChange={productTypeHandleCheck} /> <span className="check"></span> </label></div>);
            }

         }

      }

      return productTypeFilterContent;

   }

   const productTypeFilterLoad2 = ptfilter => {

      var productTypeFilterContent = [];
      var productypeChecked = producttype;

      var ptCheckedValue = [];
      if (productypeChecked == 0) {
         ptCheckedValue = [];
      }
      else if (productypeChecked != 0) {
         ptCheckedValue = productypeChecked.split(",");
      }

      if (!ptCheckedValue || ptCheckedValue == "undefined") {
         ptCheckedValue = productypeChecked;
      }

      if (ptCheckedValue.length == 0) {
         for (var j = 0; j < ptfilter.length; j++) {

            productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype.producttype} <input type="checkbox" value={ptfilter[j].producttype._id} onChange={productTypeHandleCheck} /> <span className="check"></span> </label></div>);

         }

      }
      else {
         for (var j = 0; j < ptfilter.length; j++) {

            var numToString = ptfilter[j].producttype._id.toString();
            var flag = 0;

            for (var i = 0; i < ptCheckedValue.length; i++) {

               if (ptCheckedValue[i] == numToString) {
                  flag = 1;
               }

            }
            if (flag == 1) {
               productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype.producttype} <input type="checkbox" value={ptfilter[j].producttype._id} onChange={productTypeHandleCheck} checked /> <span className="check"></span> </label></div>);
            }
            else {
               productTypeFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{ptfilter[j].producttype.producttype} <input type="checkbox" value={ptfilter[j].producttype._id} onChange={productTypeHandleCheck} /> <span className="check"></span> </label></div>);
            }

         }

      }

      return productTypeFilterContent;

   }

   // Get All Sub Category Filter Load

   const subcategoryFilterLoad = scfilter => {

      var subCategoryFilterContent = [];
      var subcategoryChecked = subcategory;

      var scCheckedValue = [];
      if (subcategoryChecked == 0) {
         scCheckedValue = [];
      }
      else if (subcategoryChecked != 0) {
         scCheckedValue = subcategoryChecked.split(",");
      }

      if (!scCheckedValue || scCheckedValue == "undefined") {
         scCheckedValue = subcategoryChecked;
      }

      if (scCheckedValue.length == 0) {

         for (var j = 0; j < scfilter.length; j++) {

            subCategoryFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{scfilter[j].subcategory} <input type="checkbox" value={scfilter[j]._id} onChange={subCategoryHandleCheck} defaultChecked={false} /> <span className="check"></span> </label></div>);

         }

      }
      else {

         for (var j = 0; j < scfilter.length; j++) {

            var numToString = scfilter[j]._id.toString();
            var flag = 0;

            for (var i = 0; i < scCheckedValue.length; i++) {

               if (scCheckedValue[i] == numToString) {
                  flag = 1;
               }

            }
            if (flag == 1) {
               subCategoryFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{scfilter[j].subcategory} <input type="checkbox" value={scfilter[j]._id} onChange={subCategoryHandleCheck} defaultChecked={true} /> <span className="check"></span> </label></div>);
            }
            else {
               subCategoryFilterContent.push(<div key={j} className="form-inline d-flex align-items-center py-1"><label className="tick">{scfilter[j].subcategory} <input type="checkbox" value={scfilter[j]._id} onChange={subCategoryHandleCheck} defaultChecked={false} /> <span className="check"></span> </label></div>);
            }

         }

      }

      return subCategoryFilterContent;

   }

   // Product Filter On Click Function

   const productTypeHandleCheck = (event) => {

      if (event.target.checked) {
         productTypeChecked.push(event.target.value);
      } else {
         var index = productTypeChecked.indexOf(event.target.value);
         if (index > -1) {
            productTypeChecked.splice(index, 1);
         }
      }

      var loadProductType = 0

      if (productTypeChecked.length == 0) {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + 0 + "/" + brand + "/" + producttag);
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + 0 + "/" + brand + "/" + producttag;
         loadProductType = 0;
      }
      else {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + productTypeChecked.toString() + "/" + brand + "/" + producttag);
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subcategory + "/" + productTypeChecked + "/" + brand + "/" + producttag;
         loadProductType = productTypeChecked.toString();
      }

      getProductPageCountByFilter(currentpage, brand, producttag, subcategory, loadProductType);
      getProductPageByFilter(currentpage, brand, producttag, subcategory, loadProductType);

      producttype = loadProductType;

   };

   // Sub Category On Click Function

   const subCategoryHandleCheck = (event) => {

      if (event.target.checked) {

         subCategoryChecked.push(event.target.value);

      } else {

         var index = subCategoryChecked.indexOf(event.target.value);
         if (index > -1) {
            subCategoryChecked.splice(index, 1);
         }

      }

      var loadSUbcategory = 0

      if (subCategoryChecked.length == 0) {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + 0 + "/" + producttype + "/" + brand + "/" + producttag);
         loadSUbcategory = 0;
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + 0 + "/" + producttype + "/" + brand + "/" + producttag;
      }
      else {
         window.history.pushState({}, null, "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subCategoryChecked.toString() + "/" + producttype + "/" + brand + "/" + producttag);
         loadSUbcategory = subCategoryChecked.toString();
         //window.location.href = "/shop/" + currentpage + "/" + startwith + "/" + category + "/" + subCategoryChecked.toString() + "/" + producttype + "/" + brand + "/" + producttag;
      }

      //subcategoryFilterById(subCategoryChecked);

      GetFilterProductTypeById(subCategoryChecked);

      getProductPageCountByFilter(currentpage, brand, producttag, loadSUbcategory, producttype);
      getProductPageByFilter(currentpage, brand, producttag, loadSUbcategory, producttype);

      subcategory = loadSUbcategory;

   };

   const GetFilterProductTypeById = (subcategoryId) => {
      var productDetail = {
         id: subcategoryId
      };

      axios
         .post(`${userURL}/product/getProductTypeById`, productDetail)
         .then(data => {
            setProductTypeLoad(productTypeFilterLoad2(data.data.data));
         })
         .catch(error => {
            alert("Error");
         });

   };

   function titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
   }

   const GetWishList = (sessionUserId, sessionToken) => {

      if (sessionToken) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId
         };

         axios
            .post(`${userDetailsURL}/userDetails/showWishlist`, productDetail, authToken)
            .then(data => {
               setGetWishlistDetail(data.data.data);
            })
            .catch(error => {

            });

      }

   };

   const GetShopingCart = (sessionUserId, sessionToken) => {

      if (sessionToken) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId
         };

         axios
            .post(`${userDetailsURL}/userDetails/showShopingCart`, productDetail, authToken)
            .then(data => {
               setGetShopingCartDetail(data.data.data);

            })
            .catch(error => {

            });

      }

   };

   return (
      <>
         {
            isLoading ?

               <div className="loader">Loading...</div>

               :

               <section className="shop-banner-area pb-120">
                  <div className="container">
                     <div className="row mt-20">
                        <div className="col-xl-4 col-lg-5 col-md-6">
                           <div className="product-showing mb-40">
                              <p>Showing {currentpage}–{pageinationCount} of {productCount} results</p>
                           </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-6">
                           <div className="shop-tab f-right">
                              <ul className="nav text-center myTabForProduct" style={{display:"none"}} id="myTab" role="tablist">
                                 <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                       aria-controls="home" aria-selected="true"><i className="fas fa-th-large"></i> </a>
                                 </li>
                                 <li className="nav-item">
                                    <a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                                       aria-controls="profile" aria-selected="false"><i className="fas fa-list-ul"></i></a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="row">

                        <div className="col-xs-12 col-sm-4">
                           <div className="d-sm-flex align-items-sm-center pt-2 clear" style={{ paddingBottom: "15px" }}>
                              <div className="text-muted filter-label">Applied Filters:</div>
                           </div>
                           <div className="filters">
                              <button className="btn btn-success" onClick={handleHideShow} type="button" data-toggle="collapse" data-target="#mobile-filter" aria-expanded="true" aria-controls="mobile-filter">Filter<span className="px-1 fas fa-filter"></span></button>
                           </div>
                           {showFilter ?
                              <div id="mobile-filter">
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Sub Category</h5>
                                    <form className="brand sideFilterScroll">

                                       {subcategoryFilterLoad(getSubCategoryDetails)}

                                    </form>
                                 </div>
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Product Type</h5>
                                    <form className="brand sideFilterScroll">

                                       {productTypeLoad}

                                    </form>
                                 </div>
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Brands</h5>
                                    <form className="brand sideFilterScroll">

                                       {brandFilterLoad(getBrandDetails)}

                                    </form>
                                 </div>
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Product Tags</h5>
                                    <form className="brand sideFilterScroll">

                                       {productTagFilterLoad(getProductTagsDetails)}

                                    </form>
                                 </div>
                              </div>
                              : null}
                           <div className="content py-md-0 py-3">
                              <section id="sidebar">
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Sub Category</h5>
                                    <form className="brand sideFilterScroll">

                                       {subcategoryFilterLoad(getSubCategoryDetails)}

                                    </form>
                                 </div>
                              </section>
                              <section id="sidebar">
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Product Type</h5>
                                    <form className="brand sideFilterScroll">

                                       {productTypeLoad}

                                    </form>
                                 </div>
                              </section>
                              <section id="sidebar">
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Brands</h5>
                                    <form className="brand sideFilterScroll">

                                       {brandFilterLoad(getBrandDetails)}

                                    </form>
                                 </div>
                              </section>
                              <section id="sidebar">
                                 <div className="py-3">
                                    <h5 className="font-weight-bold">Product Tags</h5>
                                    <form className="brand sideFilterScroll">

                                       {productTagFilterLoad(getProductTagsDetails)}

                                    </form>
                                 </div>
                              </section>
                           </div>
                        </div>
                        <div className="col-xs-12 col-sm-8">
                           <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                 <div className="row">

                                    {getProductDetails.map((options, index) => {

                                       if (sessionToken) {

                                          var addwish = 0;
                                          for (var i = 0; i < getWishlistDetail.length; i++) {

                                             if (getWishlistDetail[i].productid == options._id) {
                                                addwish = 1;
                                                break;
                                             }
                                             else {
                                                addwish = 0;
                                             }

                                          }

                                          var addcart = 0;
                                          for (var i = 0; i < getShopingCartDetail.length; i++) {
                                            
                                             if (getShopingCartDetail[i].productid == options._id) {
                                                addcart = 1;
                                                break;
                                             }
                                             else {
                                                addcart = 0;
                                             }

                                          }

                                          return <SingleShop notAllowed={addwish} cartAllowed={addcart} key={index} image={baseurl + '/uploadFiles/product/' + options.image1} productId={options._id} mrp={options.mrp} originalrate={options.originalrate} title={options.product} producttype={options.producttype.producttype} />

                                       }
                                       else {
                                          return <SingleShop notAllowed={0} cartAllowed={0} key={index} image={baseurl + '/uploadFiles/product/' + options.image1} productId={options._id} mrp={options.mrp} originalrate={options.originalrate} title={options.product} producttype={options.producttype.producttype} />
                                       }

                                    })}

                                 </div>
                              </div>
                              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                                 {getProductDetails.map((options, index) => {

                                    if (sessionToken) {

                                       var addwish = 0;
                                       for (var i = 0; i < getWishlistDetail.length; i++) {

                                          if (getWishlistDetail[i]._id == options._id) {
                                             addwish = 1;
                                             break;
                                          }
                                          else {
                                             addwish = 0;
                                          }

                                       }

                                       var addcart = 0;
                                       for (var i = 0; i < getShopingCartDetail.length; i++) {

                                          if (getShopingCartDetail[i]._id == options._id) {
                                             addcart = 1;
                                             break;
                                          }
                                          else {
                                             addcart = 0;
                                          }

                                       }

                                       return <>
                                          <div className="row" key={index}>

                                             <SingleSecondShop notAllowed={addwish} cartAllowed={addcart} image={baseurl + '/uploadFiles/product/' + options.image1} productId={options._id} mrp={options.mrp} originalrate={options.originalrate} title={options.product} producttype={options.producttype.producttype} />

                                          </div>
                                       </>

                                    }
                                    else {
                                       return <>
                                          <div className="row" key={index}>

                                             <SingleSecondShop notAllowed={0} cartAllowed={0} image={baseurl + '/uploadFiles/product/' + options.image1} productId={options._id} mrp={options.mrp} originalrate={options.originalrate} title={options.product} producttype={options.producttype.producttype} />

                                          </div>
                                       </>
                                    }



                                 })}

                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12">
                           <div className="basic-pagination basic-pagination-2 text-center mt-20">
                              <ul id="loadPageination">

                                 {pageinationLoad}

                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>


         }
      </>
   );
};

export default ShopBanner;