import React from 'react';
import { Link } from 'react-router-dom';
import useGlobalContext from '../../hooks/useGlobalContext';
// import img1 from '../../../public/img/project/slider/lysander_hospital.png'

const HomeHeroSingleSlide = ({ bg_className }) => {
   const videoPopup = useGlobalContext();
   const { setIsOpen } = videoPopup
   return (
      <>
         <div className={`single-slider slider-height d-flex align-items-center slider_bg_${bg_className}`}>
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-8 col-md-10 col-sm-12">
                     <div className="hero-text">
                        <div className="hero-slider-caption ">
                           {

                              bg_className == 1 ?
                                 <>
                                 <h5>We are here for your care.</h5>
                                    <h1 style={{ fontSize: "50px" }}>Lysander Hospital</h1>
                                    {/* <img src='img/project/slider/lysander_hospital.png'/> */}
                                 </>
                                 :
                                 null

                           }
                           {

                              bg_className == 2 ?
                                 <>
                                    <h5>We are here for your care.</h5>
                                    <h1 style={{ fontSize: "50px" }}>Serving, Caring, Healing</h1>
                                 </>
                                 :
                                 null

                           }

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeHeroSingleSlide;