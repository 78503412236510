import React from 'react';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/Header';
import SurgeriesList from './SurgeriesList';
const Surgeries = () => {           
    return (
        <>
            <HomeHeader/>
            <SurgeriesList />
            <Footer/>
        </>
    );
};

export default Surgeries;