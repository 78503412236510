import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31472.77654897041!2d77.951993!3d9.586901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc62877870ee2ef93!2sLysander%20Hospital!5e0!3m2!1sen!2sin!4v1657025970234!5m2!1sen!2sin" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;