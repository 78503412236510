import React, { useState } from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../components/Shared/Footer';
//import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/ShopHeader';
import CheckoutArea from './CheckoutArea/CheckoutArea';

import { UserContext } from '../LayoutPages/Header/UserContext';

import HomeHeader2 from '../LayoutPages/Header/ShopCategoryHeader';
import Footer2 from '../LayoutPages/Footer/PharmacyFooter';

import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';

const CheckoutPage = () => {

   const sessionToken = ReactSession.get("token");

   if (!sessionToken) {
      Swal.fire('Oops..', 'Please Login Your Account', 'error')
         .then((result) => {
            console.log(result);
            if (result) {
               window.location.href = "/login";
            } else {

            }
         });
   }

   const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
   const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

   return (
      <>
         <UserContext.Provider value={{ getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger }}>
            <HomeHeader2 /><br />
            <CheckoutArea />
         </UserContext.Provider>
         <Footer2 />
      </>
   );
};

export default CheckoutPage;