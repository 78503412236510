import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { ReactSession } from 'react-client-session';

import axios from 'axios';

import { userURL, userDetailsURL } from "../../config";

import { UserContext } from './UserContext';

const HomeHeader = () => {

   const { getWishlistTrigger,setGetWishlistTrigger,getAddToCartTrigger,setGetAddToCartTrigger } = useContext(UserContext);

   const token = ReactSession.get("token");
   const username = ReactSession.get("username");

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");
   console.log(sessionUserId);
   //alert(sessionUserId);

   const [getProductDetails1, setGetProductDetails1] = useState([]);
   const [getProductDetails2, setGetProductDetails2] = useState([]);

   const [changeHeaderStyle, setChangeHeaderStyle] = useState();
   const [changeHeaderStyle2, setChangeHeaderStyle2] = useState();
   const [changeHeaderStyle3, setChangeHeaderStyle3] = useState();
   const [searchValue, setSearchValue] = useState();

   const [cartCount, getCartCount] = useState(0);
   const [countWishlist, getCountWishlist] = useState(0);

   useEffect(() => {

      getAllProductForAutocomplete();
      setChangeHeaderStyle(false);
      document.getElementById("page_menu_content").style.height = '0';
      categoryDatas();
      autocomplete(document.getElementById("searchField"), []);
      GetShopingCart(sessionUserId, sessionToken);
      GetWishList(sessionUserId, sessionToken);

      showAllCartProduct();
      showAllWishlistProduct();

   }, [])

   const [getHeaderDatas, setGetHeaderDatas] = useState([]);

   const toggleHeaderChange1 = () => {

      if (changeHeaderStyle) {
         document.getElementById("page_menu_content").style.height = '0';
         setChangeHeaderStyle(false);
      }
      else {
         document.getElementById("page_menu_content").style.height = 'auto';
         setChangeHeaderStyle(true);
      }

   };

   const toggleHeaderChange2 = id => {

      const div = document.querySelector("#page_menu_selection" + id);
      if (div.classList.contains('toggleClassHeader2')) {
         div.classList.remove("toggleClassHeader2");
         div.classList.add("toggleClassHeader1");
         div.classList.add("active");
      }
      else {
         div.classList.remove("toggleClassHeader1");
         div.classList.add("toggleClassHeader2");
         div.classList.remove("active");
      }

   };

   const categoryDatas = () => {
      axios
         .get(userURL + '/header/getAllHeaderValues')
         .then(data => {
            setGetHeaderDatas(data.data.data);
         })
         .catch(error => {

         });
   }

   const getAllProductForAutocomplete = () => {
      axios
         .get(userURL + '/header/getProducts')
         .then(data => {
            var productValue = [];
            for (var i = 0; i < data.data.data.length; i++) {
               productValue.push(data.data.data[i].product);
            }
            autocomplete(document.getElementById("searchField"), productValue);
         })
         .catch(error => {

         });
   }



   function titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
   }

   function autocomplete(searchEle, arr) {
      var currentFocus;
      searchEle.addEventListener("input", function (e) {
         var divCreate,
            b,
            i,
            fieldVal = this.value;
         closeAllLists();
         if (!fieldVal) {
            return false;
         }
         currentFocus = -1;
         divCreate = document.createElement("DIV");
         divCreate.setAttribute("id", this.id + "autocomplete-list");
         divCreate.setAttribute("class", "autocomplete-items");
         this.parentNode.appendChild(divCreate);
         for (i = 0; i < arr.length; i++) {
            if (arr[i].substr(0, fieldVal.length).toUpperCase() == fieldVal.toUpperCase()) {
               b = document.createElement("DIV");
               b.innerHTML = "<strong>" + arr[i].substr(0, fieldVal.length) + "</strong>";
               b.innerHTML += arr[i].substr(fieldVal.length);
               b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
               b.addEventListener("click", function (e) {
                  searchEle.value = this.getElementsByTagName("input")[0].value;
                  setSearchValue(searchEle.value);
                  closeAllLists();
               });
               divCreate.appendChild(b);
            }
         }
      });
      searchEle.addEventListener("keydown", function (e) {
         var autocompleteList = document.getElementById(
            this.id + "autocomplete-list"
         );
         if (autocompleteList)
            autocompleteList = autocompleteList.getElementsByTagName("div");
         if (e.keyCode == 40) {
            currentFocus++;
            addActive(autocompleteList);
         }
         else if (e.keyCode == 38) {
            //up
            currentFocus--;
            addActive(autocompleteList);
         }
         else if (e.keyCode == 13) {
            e.preventDefault();
            if (currentFocus > -1) {
               if (autocompleteList) autocompleteList[currentFocus].click();
            }
         }
      });
      function addActive(autocompleteList) {
         if (!autocompleteList) return false;
         removeActive(autocompleteList);
         if (currentFocus >= autocompleteList.length) currentFocus = 0;
         if (currentFocus < 0) currentFocus = autocompleteList.length - 1;
         autocompleteList[currentFocus].classList.add("autocomplete-active");
      }
      function removeActive(autocompleteList) {
         for (var i = 0; i < autocompleteList.length; i++) {
            autocompleteList[i].classList.remove("autocomplete-active");
         }
      }
      function closeAllLists(elmnt) {
         var autocompleteList = document.getElementsByClassName(
            "autocomplete-items"
         );
         for (var i = 0; i < autocompleteList.length; i++) {
            if (elmnt != autocompleteList[i] && elmnt != searchEle) {
               autocompleteList[i].parentNode.removeChild(autocompleteList[i]);
            }
         }
      }
      document.addEventListener("click", function (e) {
         closeAllLists(e.target);
      });
   }


   const setSearchInputValue = event => {
      const result = event.target.value;
      setSearchValue(result);
   };

   const searchProductValue = () => {

      if (searchValue) {
         window.location.href = "/searchProduct/" + searchValue;
      }
      else {

      }

   }

   const showAllCartProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showCartDetails`, productDetail, authToken)
         .then(data => {
            console.log(data.data.data);
            setGetProductDetails2(data.data.data);
         })
         .catch(error => {

         });

   }

   const showAllWishlistProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showWishlistDetails`, productDetail, authToken)
         .then(data => {
            setGetProductDetails1(data.data.data);
         })
         .catch(error => {

         });

   }

   const setLogout = () => {

      for (var pi = 0; pi < getProductDetails2.length; pi++) {
         ReactSession.set(`addToCartProduct${getProductDetails2[pi].productid._id}`, '');
      }
      for (var pi2 = 0; pi2 < getProductDetails1.length; pi2++) {
         ReactSession.set(`wishlistProduct${getProductDetails1[pi2].productid._id}`, '');
      }

      ReactSession.set("userid", '');
      ReactSession.set("username", '');
      ReactSession.set("email", '');
      ReactSession.set("phone", '');
      ReactSession.set("token", '');
      window.location.href = "/";
   }

   const GetShopingCart = (sessionUserId, sessionToken) => {

      if (sessionToken) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId
         };

         axios
            .post(`${userDetailsURL}/userDetails/showShopingCart`, productDetail, authToken)
            .then(data => {

               getCartCount(data.data.data.length);
               setGetAddToCartTrigger(data.data.data.length);

            })
            .catch(error => {

            });

      }

   };

   const GetWishList = (sessionUserId, sessionToken) => {

      if (sessionToken) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId
         };

         axios
            .post(`${userDetailsURL}/userDetails/showWishlist`, productDetail, authToken)
            .then(data => {
               getCountWishlist(data.data.data.length);
               setGetWishlistTrigger(data.data.data.length);
            })
            .catch(error => {

            });

      }

   };

   return (
      <>
         <div className="super_container">

            <header className="header">

               <div className="header_main">
                  <div className="container">
                     <div className="row">

                        <div className="col-lg-3 col-sm-3 col-3 order-1">
                           <div className="logo_container">
                              <div className="logo2"><a href="/"><img src="../../../../../../../img/project/logo/lysander_hospital.png" alt="" /></a></div>
                           </div>
                        </div>

                        <div className="col-lg-5 col-12 order-lg-2 order-3 text-lg-left text-right">
                           <div className="header_search">
                              <div className="header_search_content">
                                 <div className="header_search_form_container">
                                    <form action="#" className="header_search_form clearfix">
                                       <input type="search" required="required" id="searchField" value={searchValue} className="header_search_input" onChange={setSearchInputValue} autoComplete="off" style={{ width: "90%" }} placeholder="Search for products..." />
                                       <div className="custom_dropdown" style={{ display: "none" }}>
                                          <div className="custom_dropdown_list">
                                             <span className="custom_dropdown_placeholder clc">All Categories</span>
                                             <i className="fas fa-chevron-down"></i>
                                             <ul className="custom_list clc">
                                                <li><a className="clc" href="#">All Categories</a></li>
                                                <li><a className="clc" href="#">Computers</a></li>
                                                <li><a className="clc" href="#">Laptops</a></li>
                                                <li><a className="clc" href="#">Cameras</a></li>
                                                <li><a className="clc" href="#">Hardware</a></li>
                                                <li><a className="clc" href="#">Smartphones</a></li>
                                             </ul>
                                          </div>
                                       </div>
                                       <button type="button" className="header_search_button trans_300" onClick={searchProductValue} value="Submit"><img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560918770/search.png" alt="" /></button>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="col-lg-4 col-9 order-lg-3 order-2 text-lg-left text-right">
                           <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                              <div className="wishlist d-flex flex-row align-items-center justify-content-end">
                                 <div className="wishlist_icon"><FavoriteBorderIcon style={{ color: "#e12454", fontSize: "30px" }} /></div>
                                 <div className="wishlist_content" style={{ marginLeft: "0px" }}>
                                    <div className="wishlist_text"><a href="/wishList">Wishlist</a></div>
                                    <div class="wishlist_count">{getWishlistTrigger}</div>
                                 </div>
                              </div>

                              <div className="cart" style={{ marginRight: "20px" }}>
                                 <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                                    <div className="cart_icon">
                                       <ShoppingCartIcon style={{ color: "#e12454", fontSize: "30px" }} />
                                    </div>
                                    <div class="cart_content" style={{ marginLeft: "0px" }}>
                                       <div class="cart_text"><a href="/shoppingCart">Cart</a></div>
                                       <div class="wishlist_count">{getAddToCartTrigger}</div>
                                    </div>
                                 </div>
                              </div>

                              <div class="top_bar_menu">
                                 <ul class="standard_dropdown top_bar_dropdown" style={{ marginBottom: "0px" }}>

                                    {token ?


                                       <li>
                                          <a href="#">
                                             <PersonOutlineIcon style={{ color: "#e12454", fontSize: "30px" }} />
                                             &nbsp;<span>{username}</span>
                                          </a>
                                          <ul style={{ width: "120px" }}>
                                             <li><a href="/profileDetails">Profile</a></li>
                                             <li><a href="/orderdetails">Orders</a></li>
                                             <li><a href="#" onClick={setLogout}>Logoutaa</a></li>
                                          </ul>
                                       </li>


                                       :

                                       <li>
                                          <a href="/login">
                                             <PersonOutlineIcon style={{ color: "#e12454", fontSize: "30px" }} />
                                             &nbsp;<span>Sigin In</span>
                                          </a>
                                       </li>

                                    }



                                 </ul>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <nav className="main_nav">
                  <div className="container">
                     <div className="row">
                        <div className="col">

                           <div className="main_nav_content d-flex flex-row">

                              <div className="main_nav_menu">
                                 <ul className="standard_dropdown main_nav_dropdown">

                                    {getHeaderDatas.map((categoryOptions, categoryIndex) => (
                                       categoryOptions.subcategory.length != 0 ?
                                          <li key={categoryOptions.categoryId} className="hassubs">
                                             <a href={"/shop/1/1/" + categoryOptions.categoryId + "/0/0/0/0"}>
                                                {titleCase(categoryOptions.categoryName)}<i className="fas fa-chevron-down"></i>
                                             </a>
                                             <ul>
                                                {
                                                   categoryOptions.subcategory.map((subcategoryData, j) =>
                                                      <li key={subcategoryData.subCategoryId}>
                                                         <a href={"/shop/1/1/" + categoryOptions.categoryId + "/" + subcategoryData.subCategoryId + "/0/0/0"}>
                                                            {titleCase(subcategoryData.subCategoryName)}<i className="fas fa-chevron-down"></i>
                                                         </a>
                                                         <ul>
                                                            {
                                                               subcategoryData.productType.length != 0 ?
                                                                  subcategoryData.productType.map((productTypeData, k) =>
                                                                     <li key={productTypeData.productTypeId}><a href={"/shop/1/1/" + categoryOptions.categoryId + "/" + subcategoryData.subCategoryId + "/" + productTypeData.productTypeId + "/0/0"}>{titleCase(productTypeData.productType)}<i className="fas fa-chevron-down"></i></a></li>
                                                                  )
                                                                  :
                                                                  null
                                                            }
                                                         </ul>
                                                      </li>
                                                   )
                                                }
                                             </ul>
                                          </li>
                                          :
                                          <li key={categoryOptions.categoryId}>
                                             <a href={"/shop/1/1/" + categoryOptions.categoryId + "/0/0/0/0"}>{titleCase(categoryOptions.categoryName)}<i className="fas fa-chevron-down"></i></a>
                                          </li>

                                    ))}

                                 </ul>
                              </div>

                              <div className="menu_trigger_container ml-auto">
                                 <div className="menu_trigger d-flex flex-row align-items-center justify-content-end">
                                    <div className="menu_burger">
                                       <div className="menu_trigger_text">menu</div>
                                       <div className="cat_burger menu_burger_inner" onClick={toggleHeaderChange1}><span></span><span></span><span></span></div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </nav>
               <div className="page_menu">
                  <div className="container">
                     <div className="row">
                        <div className="col">

                           <div className="page_menu_content" id="page_menu_content">

                              <div className="page_menu_search">
                                 <form action="#">
                                    <input type="search" required="required" className="page_menu_search_input" placeholder="Search for products..." />
                                 </form>
                              </div>
                              <ul className="page_menu_nav">

                                 {getHeaderDatas.map((categoryOptions, categoryIndex) => (

                                    categoryOptions.subcategory.length != 0 ?

                                       <li key={categoryIndex} className="page_menu_item has-children">

                                          <a href="#">{titleCase(categoryOptions.categoryName)}<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(categoryIndex)}></i></a>

                                          <ul className="page_menu_selection toggleClassHeader2" id={"page_menu_selection" + categoryIndex} style={{ background: "white" }}>

                                             {

                                                categoryOptions.subcategory.map((subcategoryData, j) =>

                                                   subcategoryData.productType.length != 0 ?

                                                      <li key={j} className="page_menu_item has-children">

                                                         <a href="#" style={{ color: "black", fontWeight: "700" }} onClick={() => toggleHeaderChange2(11 + subcategoryData.subCategoryId)}>{subcategoryData.subCategoryName}<i className="fa fa-angle-down"></i></a>

                                                         <ul className="page_menu_selection toggleClassHeader2" style={{ background: "#e12454" }} id={"page_menu_selection" + parseInt(11 + subcategoryData.subCategoryId)}>

                                                            {
                                                               subcategoryData.productType.length != 0 ?
                                                                  subcategoryData.productType.map((productTypeData, k) =>
                                                                     <li key={k}><a href="#">{productTypeData.productType}<i className="fa fa-angle-down"></i></a></li>
                                                                  )
                                                                  :
                                                                  null
                                                            }

                                                         </ul>

                                                      </li>

                                                      :

                                                      <li key={j}>

                                                         <a href="#" style={{ color: "black", fontWeight: "700" }}>{subcategoryData.subCategoryName}<i className="fa fa-angle-down"></i></a>

                                                      </li>


                                                )

                                             }

                                          </ul>

                                       </li>

                                       :

                                       <li className="page_menu_item" key={categoryIndex}>

                                          <a href="#">{titleCase(categoryOptions.categoryName)}<i className="fa fa-angle-down"></i></a>

                                       </li>

                                 ))}

                                 <li className="page_menu_item has-children">
                                    <a href="#">Language<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(1)}></i></a>
                                    <ul className="page_menu_selection toggleClassHeader2" id="page_menu_selection1" style={{ background: "white" }}>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>English<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Italian<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Spanish<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Japanese<i className="fa fa-angle-down"></i></a></li>
                                    </ul>
                                 </li>
                                 <li className="page_menu_item has-children">
                                    <a href="#">Currency<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(2)}></i></a>
                                    <ul className="page_menu_selection toggleClassHeader2" style={{ background: "white" }} id="page_menu_selection2">
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>US Dollar<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>EUR Euro<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>GBP British Pound<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>JPY Japanese Yen<i className="fa fa-angle-down"></i></a></li>
                                    </ul>
                                 </li>
                                 <li className="page_menu_item">
                                    <a href="#">Home<i className="fa fa-angle-down"></i></a>
                                 </li>
                                 <li className="page_menu_item has-children">
                                    <a href="#">Super Deals<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(3)}></i></a>
                                    <ul className="page_menu_selection toggleClassHeader2" style={{ background: "white" }} id="page_menu_selection3">
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Super Deals<i className="fa fa-angle-down"></i></a></li>
                                       <li className="page_menu_item has-children">
                                          <a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(6)}></i></a>
                                          <ul className="page_menu_selection toggleClassHeader2" style={{ background: "#e12454" }} id="page_menu_selection6">
                                             <li><a href="#">Menu Item<i className="fa fa-angle-down"></i></a></li>
                                             <li><a href="#">Menu Item<i className="fa fa-angle-down"></i></a></li>
                                             <li><a href="#">Menu Item<i className="fa fa-angle-down"></i></a></li>
                                             <li><a href="#">Menu Item<i className="fa fa-angle-down"></i></a></li>
                                          </ul>
                                       </li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                    </ul>
                                 </li>
                                 <li className="page_menu_item has-children">
                                    <a href="#">Featured Brands<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(4)}></i></a>
                                    <ul className="page_menu_selection toggleClassHeader2" style={{ background: "white" }} id="page_menu_selection4">
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Featured Brands<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                    </ul>
                                 </li>
                                 <li className="page_menu_item has-children">
                                    <a href="#">Trending Styles<i className="fa fa-angle-down" onClick={() => toggleHeaderChange2(5)}></i></a>
                                    <ul className="page_menu_selection toggleClassHeader2" style={{ background: "white" }} id="page_menu_selection5">
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Trending Styles<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                       <li><a href="#" style={{ color: "black", fontWeight: "700" }}>Menu Item<i className="fa fa-angle-down"></i></a></li>
                                    </ul>
                                 </li>
                                 <li className="page_menu_item"><a href="blog.html">blog<i className="fa fa-angle-down"></i></a></li>
                                 <li className="page_menu_item"><a href="contact.html">contact<i className="fa fa-angle-down"></i></a></li>
                              </ul>

                              <div className="menu_contact">
                                 <div className="menu_contact_item"><div className="menu_contact_icon"><img src="images/phone_white.png" alt="" /></div>+38 068 005 3570</div>
                                 <div className="menu_contact_item"><div className="menu_contact_icon"><img src="images/mail_white.png" alt="" /></div><a href="mailto:fastsales@gmail.com">fastsales@gmail.com</a></div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </header>
         </div>
      </>
   );
};

export default HomeHeader;