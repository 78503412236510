import React from 'react';
import HomeAboutArea from './HomeAboutArea/HomeAboutArea';
import HomeFooter from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/Header';
import HomeHeroSection from './HomeHeroSection/HomeHeroSection';
import HomeServices from './HomeServices/HomeServices';
import HomeOurTeam from './HomeOurTeam/HomeOurTeam';
import { ReactSession } from 'react-client-session';
import { Link, useParams } from 'react-router-dom';
const Home = () => {

    let { code} = useParams();
    console.log(code,"code this is user")

    return (
        <>
            <HomeHeader/>
            <HomeHeroSection/>
            <HomeAboutArea/>
            <HomeServices/>
            <HomeOurTeam/>
            <HomeFooter/>
        </>
    );
};

export default Home;