import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../components/Shared/Footer';
//import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import HomeHeader from '../LayoutPages/Header/Header';
import Footer from '../LayoutPages/Footer/Footer';
import LoginArea from './LoginArea/LoginArea';

import { ReactSession } from 'react-client-session';

const Login = () => {

    const token = ReactSession.get("token");

    if (token) {
        window.location.href = "/shop/1/1/0/0/0/0/0";
    }

    return (
        <>
           <HomeHeader/>
           <CommonPageHeader title="Login Page" subtitle="Login" />
           <LoginArea/>
           <Footer/>
        </>
    );
};

export default Login;