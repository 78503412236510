import React from 'react';

const HomeAboutArea = () => {
   return (
      <>
         <section className="about-area pt-50 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5 about_left">
                     <div className="medical-icon-brand-2">
                        <img src="img/about/medical-brand-icon-border.png" alt=""/>
                     </div>
                     <div className="about-left-side pos-rel mb-30">
                        <div className="about-front-img">
                           <img src="img/project/about/about-img.jpg" alt=""/>
                        </div>
                        <div className="about-shape">
                           <img src="img/about/about-shape.png" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h3>About Us</h3>
                           <h2>Hospital History</h2>
                        </div>
                        <div className="about-text textAlign">
                           <p>Dr. Herbert Lysander started the hospital in 1952, as a small clinic in Virudhunagar, Tamil Nadu state, India. Now it has grown into a multi-speciality hospital. It is situated in the heart of the town easily accessible to bus stand and Railway station.</p>
                           <p>Motivated by his keen interest in the field of medicine, he went to Nagpur, where he completed his medical studies and acquired his L.M.P. degree. At that time, he had the good fortune of meeting Dr. Yoder, an American missionary, who had come to their college to give a guest lecture. On his advice, he decided to work and do service before he settled down to private medical practice. He went to Damtiri in North India where he worked for three years under the guidance of Dr. Yoder. It was through him that Dr. Lysander mastered his surgical skills and also learnt the importance of treating patients with kindness and a smile. That made him the man we know him to be.</p>
                           <a href="/founder">Read More..</a>
                        </div>
                        <div className="about-author d-flex align-items-center">
                           <div className="author-ava">
                              <img src="img/project/about/ly.png" style={{ width: "50px"}} alt=""/>
                           </div>
                           <div className="author-desination">
                              <h4>Dr. Herbert Lysander</h4>
                              <h6><a href="/founder">founder</a></h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAboutArea;