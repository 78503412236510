import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import AllContext from './context/AllContext';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import Contact from './pages/Contact/Contact';
import DoctorDetails from './pages/Doctors/DoctorDetails/DoctorDetails';
import DoctorsOne from './pages/Doctors/DoctorsOne/DoctorsOne';
import Makeappointment from './pages/ShoppingCart/ShoppingCart';
import ShopDetails from './pages/ShopDetails/ShopDetails/ShopDetails';
import ShoppingCart from './pages/User/ShoppingCart/ShoppingCart';
import WishList from './pages/User/wishList/WishList';
import ProfileDetails from './pages/User/UserDetails/UserDetails';
import './App.css';
import './Loader.css';
import './Autocomplete.css';
import NotFound from './pages/NotFound/NotFound';

import Home from './pages/HomePages/Home';
import Founder from './pages/FounderPages/Founder';
import HospitalFacility from './pages/HospitalFacilityPages/HospitalFacility';
import Insurance from './pages/InsurancePages';
import Surgeries from './pages/Surgeries';
import LabPages from './pages/LabPages/Lab';
import Paramedical from './pages/ParamedicalPages/Paramedical';
import Gallery from './pages/GalleryPages/Gallery';

import ShopPage from './pages/ShopPage/ShopPage/ShopPage';
import SearchShop from './pages/ShopPage/ShopPage/ShopBanner/SearchShop';

import Register from './pages/Register/Register';
import EmailOtp from './pages/Register/EmailOtp';
import PhoneOtp from './pages/Register/PhoneOtp';
import FPassword from './pages/Register/forgotpassword';
import Login from './pages/Login/Login';

import Privacy from './pages/Policies/PrivacyDetails/PrivacyDetails';
import Terms from './pages/Policies/TermsDetails/TermsDetails';
import Shipping from './pages/Policies/ShippingCharges/ShippingDetails';
import Refund from './pages/Policies/RefundProcess/RefundProcessDetails';
import Return from './pages/Policies/ReturnProcess/ReturnProcessDetails';

import OrderDetails from './pages/User/Order/Order';
import OrderView from './pages/User/Order/OrderArea/OrderView';

import PaymentLanding from './pages/Landingpage/payment';

import { ReactSession } from 'react-client-session';

import Pharmacy from './pages/ShopPage/ShopPage/Pharmacy';
import PharmacyShop from './pages/ShopPage/ShopPage/PharmacyShop';
import PharmacyProducts from './pages/ShopPage/ShopPage/PharmacyProducts';

import Categorys from './pages/categoryListViewPage/CategoryViewPage'
import Services from './pages/Services';

function App() {

  ReactSession.setStoreType("localStorage");

  return (
    <>
      <AllContext>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
       
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/founder" element={<Founder />} /> 
            <Route path='/surgeries' element={<Surgeries />} />
            <Route path='/insurance' element={<Insurance />} /> 
            <Route path="/hospitalfacility" element={<HospitalFacility />} />
            <Route path="/lab" element={<LabPages />} />
            <Route path="/paramedical" element={<Paramedical />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/shop/:currentpage/:startwith/:category/:subcategory/:producttype/:brand/:producttag" element={<ShopPage />} />
            <Route path="/searchProduct/:product" element={<SearchShop />} />
            <Route path="/shopDetails/:id" element={<ShopDetails />} />
            <Route path="/register" element={<Register />} />
            <Route path="/emailOtp/:id" element={<EmailOtp />} />
            <Route path="/phoneOtp/:id" element={<PhoneOtp />} />
            <Route path="/wishList" element={<WishList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/shoppingCart" element={<ShoppingCart />} />
            <Route path="/profileDetails" element={<ProfileDetails />} />
            <Route path="/forgotpasword" element={<FPassword />} />

            <Route path="/checkout/:mid/:mtid" element={<CheckoutPage />} />

            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/shippingCharge" element={<Shipping />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/return" element={<Return />} />

            <Route path="/orderdetails" element={<OrderDetails />} />
            <Route path="/orderview/:orderid" element={<OrderView />} />

            <Route path="/doctors" element={<DoctorsOne />} />
            <Route path="/makeappointment" element={<Makeappointment />} />
            <Route path="/doctorDetails" element={<DoctorDetails />} />

            <Route path="/paymentLanding" element={<PaymentLanding />} />

            <Route path="/pharmacy" element={<Pharmacy />} />
            <Route path="/onlinePharmacy/:brandId/:cateId" element={<PharmacyShop />} />
            <Route path="/onlinePharmacyView/:id" element={<PharmacyProducts />} />
            <Route path="/services/:id" element={<Services />} />

            <Route path="/CategoryViewPage" element={<Categorys />} />

            <Route path="/notMatch" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
