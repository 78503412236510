
const portfolioData = [
    {
        id: 1,
        img: 'img/project/lab/img1.png',
        category: 'general',
    },

    {
        id: 2,
        img: 'img/project/lab/img2.png',
        category: 'general',
    },
    {
        id: 3,
        img: 'img/project/lab/img3.png',
        category: 'general',
    },
    {
        id: 4,
        img: 'img/project/lab/img4.png',
        category: 'general',
    },
    {
        id: 5,
        img: 'img/project/lab/img5.png',
        category: 'general',
    },
    {
        id: 6,
        img: 'img/project/lab/img6.png',
        category: 'general',
    },
    {
        id: 7,
        img: 'img/project/lab/img7.png',
        category: 'general',
    },
    {
        id: 8,
        img: 'img/project/lab/img8.png',
        category: 'general',
    },
    {
        id: 9,
        img: 'img/project/lab/img9.png',
        category: 'general',
    },
    {
        id: 10,
        img: 'img/project/lab/img10.png',
        category: 'general',
    },
    {
        id: 11,
        img: 'img/project/lab/img11.png',
        category: 'general',
    },
    {
        id: 12,
        img: 'img/project/lab/img12.png',
        category: 'general',
    },
    {
        id: 13,
        img: 'img/project/lab/img13.png',
        category: 'general',
    },
    {
        id: 14,
        img: 'img/project/lab/img14.png',
        category: 'general',
    },
    {
        id: 15,
        img: 'img/project/lab/img15.png',
        category: 'general',
    },
    {
        id: 16,
        img: 'img/project/lab/img16.png',
        category: 'general',
    },
    
]
export default portfolioData;