import React from 'react';
import { Link } from 'react-router-dom';

const HomeSIngleService = ({ height,format, heightStyle, widthStyle, icon, path, title,content, border_class}) => {
   return (
      <>
         <div className="col-xl-4 col-lg-6 col-md-6">
            <div className={border_class ? `service-box ${border_class} text-center mb-30` : 'service-box text-center mb-30'} style={{height: height,borderStyle: 'groove',borderColor: 'rgb(225, 36, 84)'}}>
               <div className="service-thumb">
                  <img src={`img/project/category/service${icon}.${format}`} style={{width:widthStyle,height:heightStyle}} alt=""/>
               </div>
               <div className="service-content">
                  <h3><Link to={path}>{title}</Link></h3>
                  <p className="textAlign">{content}</p>
                  <Link className="service-link" to={path}>Read More</Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeSIngleService;