import React, { useState } from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
import HomeHeader from '../../LayoutPages/Header/ShopHeader';
import ShopBanner from './ShopBanner/ShopBanner';

import Footer from '../../LayoutPages/Footer/Footer';

import { UserContext } from '../../LayoutPages/Header/UserContext';

const ShopPage = () => {

    const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
    const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

    return (
        <>
            <UserContext.Provider value={{getWishlistTrigger,setGetWishlistTrigger,getAddToCartTrigger,setGetAddToCartTrigger}}>
                <HomeHeader/>
                <ShopBanner/>
            </UserContext.Provider>
            <Footer/>
        </>
    );
};

export default ShopPage;