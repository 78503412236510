import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../pages/config";
import { ReactSession } from 'react-client-session';

const SingleSecondShop = ({ notAllowed, addcart, key, image, producttype, title, mrp, originalrate, productId }) => {


   const [productWishAllowed, setProductWishAllowed] = useState();
   const [shopingAddToCart, setShopingAddToCart] = useState();

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");

   useEffect(() => {

      if (!ReactSession.get(`wishlistProduct${productId}`)) {
         if (notAllowed == 1) {
            ReactSession.set(`wishlistProduct${productId}`, 1);
            setProductWishAllowed(1);
         }
         else {
            setProductWishAllowed('');
         }
      }
      else if (ReactSession.get(`wishlistProduct${productId}`)) {
         ReactSession.set(`wishlistProduct${productId}`, 1);
         setProductWishAllowed(1);
      }

      if (!ReactSession.get(`addToCartProduct${productId}`)) {
         if (notAllowed == 1) {
            ReactSession.set(`addToCartProduct${productId}`, 1);
            setShopingAddToCart(1);
         }
         else {
            setShopingAddToCart('');
         }
      }
      else if (ReactSession.get(`addToCartProduct${productId}`)) {
         ReactSession.set(`addToCartProduct${productId}`, 1);
         setShopingAddToCart(1);
      }

   }, [])


   const addtocartDetails = () => {

      const addToCartProduct = ReactSession.get(`addToCartProduct${productId}`);

      if (addToCartProduct) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId,
            productid: productId
         };

         axios
            .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
            .then(data => {
               ReactSession.set(`addToCartProduct${productId}`, '');
               setShopingAddToCart('');
            })
            .catch(error => {

            });

      }
      else {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId,
            productid: productId
         };

         axios
            .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetail, authToken)
            .then(data => {
               ReactSession.set(`addToCartProduct${productId}`, 1);
               setShopingAddToCart(1);
            })
            .catch(error => {

            });

      }

   }

   const wishlistDetails = () => {

      const wishlistProduct = ReactSession.get(`wishlistProduct${productId}`);

      if (wishlistProduct) {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId,
            productid: productId
         };

         axios
            .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
            .then(data => {
               ReactSession.set(`wishlistProduct${productId}`, '');
               setProductWishAllowed('');
            })
            .catch(error => {

            });

      }
      else {

         const authToken = {
            headers: { Authorization: `Bearer ${sessionToken}` }
         };

         const productDetail = {
            userid: sessionUserId,
            productid: productId
         };

         axios
            .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
            .then(data => {
               ReactSession.set(`wishlistProduct${productId}`, 1);
               setProductWishAllowed(1);
            })
            .catch(error => {

            });

      }

   }


   return (
      <>
         <div className="col-lg-4 col-md-6">
            <div className="product mb-30">
               <div className="product__img">
                  <Link to={"/shopDetails/" + productId}><img src={image} alt="" style={{ width: "224px", height: "179px" }} /></Link>
               </div>
            </div>

         </div>
         <div className="col-lg-8">
            <div className="product-list-content pt-10 mb-30">
               <div className="product__content mb-20">
                  {/* <span className="pro-cat"><Link to={"/shopDetails/" + productId}>{producttype}</Link></span> */}
                  <h4 className="pro-title"><Link to={"/shopDetails/" + productId}>{title}</Link></h4>
                  <div className="price">
                     <span>&#x20B9;{originalrate}</span>
                     <span className="old-price">&#x20B9;{mrp}</span>
                  </div>
               </div>
               <div className="product-action-list">

                  {
                     shopingAddToCart == 1 ?
                     <><a style={{ background: "#e12454", color: "white", cursor: "pointer" }} onClick={addtocartDetails} className="primary_btn btn-theme">Remove</a></>
                     :
                     <><a style={{ background: "#8fb569", color: "white", cursor: "pointer" }} onClick={addtocartDetails} className="primary_btn btn-theme">Add to cart</a></>
                  }

                  <a className="action-btn" href="#"><i className="fas fa-heart"></i></a>
                  <Link className="action-btn" to={"/shopDetails/" + productId}><i
                     className="fas fa-expand"></i></Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleSecondShop;