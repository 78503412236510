import React, { useState } from 'react';
import SingleGalleryItem from './GalleryDesign';
import portfolioData from './FacilityGallery';
// import SimpleReactLightbox from 'simple-react-lightbox'

const arr = portfolioData;
const uniqueItem = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PortfolioThreeColGallery = () => {
    const [galleryItems, setGalleryItems] = useState(portfolioData);
    const [filterGalleryItems, setFilterGalleryItems] = useState(uniqueItem);
    const [active, setActive] = useState('all')
    // console.log(galleryItems)

    // filter item
    const filterItems = (category) => {
        setActive(category)
        if (category === 'all') {
            return setFilterGalleryItems(uniqueItem)
        }
        const remainingItem = galleryItems.filter((item) => {
            return item.category === category
        })
        setFilterGalleryItems(remainingItem)

    }


    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>LYSANDER INSTITUTE OF PARAMEDICAL SCIENCE</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Paramedical</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
           <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h4>Lysander Insititute Of Paramedical Science</h4>
                        <p className="textAlign">Lysander Institute of paramedical science, Virudhunagar, Tamilnadu state, India approved by Bharath Sevak Samaj, Affiliated to Central Government of India and functioning from 2008.Approval number.BSSNo.TN/1399</p>
                        <h4>1.NURSING COURSE:</h4>
                        <ul class="professinals-list pt-30" style={{paddingTop: "1px"}}>
                            <li className="textAlign"><i class="fa fa-check"></i>Diploma in First aid and Health Care – 2 years</li>
                        </ul>
                        <h4>2.PARAMEDICAL COURSE:</h4>
                        <ul class="professinals-list pt-30" style={{paddingTop: "1px"}}>
                            <li className="textAlign"><i class="fa fa-check"></i>DMLT – 2 years</li>
                            <li className="textAlign"><i class="fa fa-check"></i>X-Ray and ECG Technician – 1 year.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Diploma in Physiotherapy – 2 years.</li>
                        </ul>
                        <h4>3.WORK SHOP :</h4>
                        <ul class="professinals-list pt-30" style={{paddingTop: "1px"}}>
                            <li className="textAlign"><i class="fa fa-check"></i>Female Nursing Assistant – 1 year (Workshop)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Home Nursing – 6 months (Workshop)</li>
                        </ul>
                        <p>Monthly stipend is being given to trainee students along with accommodation and food.</p>
                    </div>
                </div>
           </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>

                    <div id="portfolio-grid" className="row row-portfolio">

                        {
                            filterGalleryItems.map((item, index) => {
                                return <SingleGalleryItem key={item.id} galleryItem={item} index={index}
                                    filterGalleryItems={filterGalleryItems} column="4" />
                            })
                        }

                    </div>

                </div>
            </section>
        </>
    );
};

export default PortfolioThreeColGallery;