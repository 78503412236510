import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';

import { userURL, baseurl, userDetailsURL, masterUrl } from "../../../config";
import { ReactSession } from 'react-client-session';

import Select from 'react-select';

const UserDetailsForm = () => {

   const [userFirstname, setUserFirstname] = useState('');
   const [userLastname, setUserLastname] = useState('');
   const [userAddress, setUserAddress] = useState('');
   const [userApartment, setUserApartment] = useState('');
   const [userCity, setUserCity] = useState('');
   const [userState, setUserState] = useState('');
   const [userZipcode, setUserZipcode] = useState('');
   const [userEmail, setUserEmail] = useState('');
   const [userPhone, setUserPhone] = useState('');
   const [userDetailsId, setUserDetailsId] = useState(0);

   const [userDistrict, setUserDistrict] = useState('');

   const [getStateData, setGetStateData] = useState([]);
   const [getDistrictData, setDistrictData] = useState([]);
   const [getCityData, setCityData] = useState([]);

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");
   const sessionEmail = ReactSession.get("email");
   const sessionPhone = ReactSession.get("phone");

   useEffect(() => {

      setUserEmail(sessionEmail);
      setUserPhone(sessionPhone);
      showUserDetails();
      getState();

   }, [])

   const showUserDetails = () => {

      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/selectUserDetails`, productDetail, authToken)
         .then(data => {
            if (data.data.success) {
               setUserFirstname(data.data.userInfo[0].firstname);
               setUserLastname(data.data.userInfo[0].lastname);
               setUserAddress(data.data.userInfo[0].street);
               setUserApartment(data.data.userInfo[0].apportment);
               setUserCity(data.data.userInfo[0].city);
               setUserState(data.data.userInfo[0].state);
               setUserDistrict(data.data.userInfo[0].district)
               setUserZipcode(data.data.userInfo[0].postcode);
               setUserDetailsId(data.data.userInfo[0]._id);
            }
         })
         .catch(error => {

         });

   }

   const submitFormAction = async (e) => {
      e.preventDefault();
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };
      if (userDetailsId == 0) {

         const userDetail = {
            userid: sessionUserId,
            firstname: userFirstname,
            lastname: userLastname,
            address: userAddress,
            apportment: userApartment,
            city: userCity,
            district: userDistrict,
            state: userState,
            pincode: userZipcode,
            email: userEmail,
            phonenumber: userPhone
         };

         axios
            .post(`${userDetailsURL}/userDetails/addUserDetails`, userDetail, authToken)
            .then(data => {
               console.log(data);
               if (data.data.status == 200) {
                  setUserDetailsId(data.data.lastid);
                  Swal.fire({
                     position: 'top-center',
                     icon: 'success',
                     title: 'User Details Added Successfully',
                  })
                  window.location.href = "/shoppingCart";

               }
               else {
                  Swal.fire({
                     icon: 'error',
                     title: 'Oops...',
                     text: "Something Went Wrong",
                  })
               }

            })
            .catch(error => {

               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: "Try Again Later",
               })

            });

      }
      else {
         const userDetail = {
            // id: userDetailsId,
            // userid: sessionUserId,
            firstname: userFirstname,
            lastname: userLastname,
            address: userAddress,
            apportment: userApartment,
            city: userCity,
            district: userDistrict,
            state: userState,
            pincode: userZipcode,
            email: userEmail,
            phonenumber: userPhone
        };
        
        try {
            const response = await axios.put(`${userDetailsURL}/userDetails/updateUserDetails/${userDetailsId}`, userDetail);
            console.log('data', response.data);
    
            if (response.data.success == true) {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'User Details Updated Successfully'
                });
                // window.location.href = "/shoppingCart";
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something Went Wrong"
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Please Try Again Later"
            });
        }
      }

   }

   const setFormFirstname = event => {
      var result = event.target.value;
      setUserFirstname(result);
   }

   const setFormLastname = event => {
      var result = event.target.value;
      setUserLastname(result);
   }
   const setFormAddress = event => {
      var result = event.target.value;
      setUserAddress(result);
   }
   const setFormApartment = event => {
      var result = event.target.value;
      setUserApartment(result);
   }
   const setFormCity = event => {
      var result = event.target.value;
      setUserCity(result);
   }
   const setFormState = event => {
      var result = event.target.value;
      setUserState(result);
      getDistrictById(result);
   }
   const setFormPostcode = event => {
      var result = event.target.value;
      setUserZipcode(result);
   }
   const setFormEmail = event => {
      var result = event.target.value;
      setUserEmail(result);
   }
   const setFormPhone = event => {
      var result = event.target.value;
      setUserPhone(result);
   }

   const setFormDistrict = event => {
      var result = event.target.value;
      setUserDistrict(result);
      getCityById(result);
   }

   const getState = () => {

      axios
         .get(`${masterUrl}/shipping/getState`)
         .then(data => {
            setGetStateData(data.data.data);
         })
         .catch(error => { });

   }

   const getDistrictById = stateId => {

      var districtDetail = {
         staterefid: stateId,
      };

      axios
         .post(`${masterUrl}/shipping/getDistrictById`, districtDetail)
         .then(data => {
            setDistrictData(data.data.data);
         })
         .catch(error => { });

   }

   const getCityById = districtId => {

      var districtDetail = {
         districtrefid: districtId,
      };

      axios
         .post(`${masterUrl}/shipping/getCityById`, districtDetail)
         .then(data => {
            setCityData(data.data.data);
         })
         .catch(error => { });

   }

   return (
      <>
         <section className="checkout-area pb-70">
            <div className="container">
               <form onSubmit={submitFormAction}>
                  <div className="row">
                     <div className="col-lg-2">
                     </div>
                     <div className="col-lg-8">
                        <div style={{height:20}}></div>
                        <div className="your-order mb-30">
                           <h3>Profile Details</h3>
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="checkout-form-list">
                                    <label>First Name <span className="required">*</span></label>
                                    <input type="text" value={userFirstname} required onChange={setFormFirstname} placeholder="" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="checkout-form-list">
                                    <label>Last Name <span className="required"></span></label>
                                    <input type="text" value={userLastname} onChange={setFormLastname} placeholder="" />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="checkout-form-list">
                                    <label>Address <span className="required">*</span></label>
                                    <input type="text" value={userAddress} required onChange={setFormAddress} placeholder="Street address" />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="checkout-form-list">
                                    <input type="text" value={userApartment} onChange={setFormApartment} placeholder="Apartment, suite, unit etc. (optional)" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="checkout-form-list">

                                    <label>State <span className="required">*</span></label>
                                    <select value={userState} required onChange={setFormState} style={{ fontSize: "14px" }}>
                                       <option style={{ display: "none" }}>{userState.state ? userState.state.charAt(0).toUpperCase() + userState.state.slice(1).toLowerCase() : 'Select State'}</option>
                                       {getStateData.map((options, index) => (
                                          <option value={options._id} style={{ fontSize: "14px" }}>{options.state.charAt(0).toUpperCase() + options.state.slice(1).toLowerCase()}</option>
                                       ))}
                                    </select>

                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="checkout-form-list">

                                    <label>District <span className="required">*</span></label>
                                    <select value={userDistrict} required onChange={setFormDistrict} style={{ fontSize: "14px" }}>
                                       <option style={{ display: "none" }}>{userDistrict.district ? userDistrict.district.charAt(0).toUpperCase() + userDistrict.district.slice(1).toLowerCase() : 'Select District'}</option>
                                       {getDistrictData.map((options, index) => (
                                          <option value={options._id} style={{ fontSize: "14px" }}>{options.district.charAt(0).toUpperCase() + options.district.slice(1).toLowerCase()}</option>
                                       ))}
                                    </select>

                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="checkout-form-list">

                                    <label>Town / City <span className="required">*</span></label>
                                    <select value={userCity} required onChange={setFormCity} style={{ fontSize: "14px" }}>
                                       <option style={{ display: "none" }}> {userCity.city ? userCity.city.charAt(0).toUpperCase() + userCity.city.slice(1).toLowerCase() : 'Select City'}</option>
                                       {getCityData.map((options, index) => (
                                          <option value={options._id} style={{ fontSize: "14px" }}>{options.city.charAt(0).toUpperCase() + options.city.slice(1).toLowerCase()}</option>
                                       ))}
                                    </select>

                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="checkout-form-list">
                                    <label>Postcode / Zip <span className="required">*</span></label>
                                    <input type="text" value={userZipcode} required onChange={setFormPostcode} placeholder="Postcode / Zip" />
                                 </div>
                              </div>
                              <div className="col-md-6" style={{ display: "none" }}>
                                 <div className="checkout-form-list">
                                    <label>Email Address <span className="required">*</span></label>
                                    <input type="email" value={userEmail} onChange={setFormEmail} disabled placeholder="" />
                                 </div>
                              </div>
                              <div className="col-md-6" style={{ display: "none" }}>
                                 <div className="checkout-form-list">
                                    <label>Phone <span className="required">*</span></label>
                                    <input type="text" value={userPhone} onChange={setFormPhone} disabled placeholder="Postcode / Zip" />
                                 </div>
                              </div>
                              <div class="order-button-payment mt-20"><button type="submit" class="primary_btn theme-btn">Save</button></div>
                           </div>
                        </div>
                     </div>

                  </div>
               </form>
            </div>
         </section>
         <br />
      </>
   );
};

export default UserDetailsForm;