//export const baseurl = "http://localhost:8080/api";

//export const ImgUpUrl = "http://localhost:8080";

//export const imageUrlIs = "http://localhost:8080/api/commonApi/viewImage";

export const baseurl = "https://lysanderhospital.com/api";

export const ImgUpUrl = "https://lysanderhospital.com";

export const imageUrlIs = "https://lysanderhospital.com/api/commonApi/viewImage";

export const userURL = baseurl + "/userApi";
export const masterUrl = baseurl + '/masterApi';

export const userDetailsURL = baseurl + "/validUserApi";

export const CommonUserApi = baseurl + "/CommonUserApi";

export const CheckoutURL = baseurl + "/validUserApi";

export const PaymentGatewayURL = baseurl + "/userApi/paymentGateway";

export const ProductURL = baseurl + "/product";

export const RAZOR_PAY_KEY_ID = "rzp_test_xlMcuTZatRXUBe";
export const RAZOR_PAY_KEY_SECRET = "ctYv4863UkY9h5gY2F8y6i3o";

export const website = "http://localhost:3001/";