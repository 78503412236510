import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { imageUrlIs } from "./index";

const LoadPreviewImage = ({imagePath,image,style}) => {

    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {

        loadImageUrl();

    }, [image]);

    const loadImageUrl = () => {

        axios
            .get(`${imageUrlIs}/${imagePath}/${image}`)
            .then(data => {

                if (data.data.status == 200) {

                    setImageUrl(data.data.url);
                }

            })
            .catch(error => {

            });
    }

    return (
        <>
            <img src={`${imageUrl}`} style={style} />
        </>
    );

}

export default LoadPreviewImage;