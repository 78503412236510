import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../../config";

import { ReactSession } from 'react-client-session';

import LoadPreviewImage from '../../../config/LoadPreviewImage';

const WishListArea = () => {

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");
   const [getProductDetails, setGetProductDetails] = useState([]);

   const wishListProductViewImageStyle = { width: '120px', height: '90px' };

   useEffect(() => {

      showAllWishlistProduct();

   }, [])

   const showAllWishlistProduct = async () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };
      console.log(authToken, productDetail)

      await axios
         .post(`${userDetailsURL}/userDetails/showWishlistDetails`, productDetail)
         .then(data => {

            console.log('dfwewfewfeffwefwe',data.data.wishlistInfo)

            setGetProductDetails(data.data.wishlistInfo);
         })
         .catch(error => {

         });

   }

   const wishlistRemoveDetails = async (selectProductId) => {

      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId,
         productid: selectProductId
      };

      await axios
         .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
         .then(data => {
            ReactSession.set(`wishlistProduct${selectProductId}`, '');
            showAllWishlistProduct();
            window.location.reload(false);
         })
         .catch(error => {

         });

   }

   return (
      <>
         <div className="axil-breadcrumb-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6 col-md-8">
                     <div className="inner">
                        <ul className="axil-breadcrumb">
                           <li className="axil-breadcrumb-item"><a href="/">Home</a></li>
                           <li className="separator"></li>
                           <li className="axil-breadcrumb-item active" aria-current="page">Wishlist</li>
                        </ul>
                        <h1 className="title">Wishlist</h1>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <section className="cart-area pt-120 pb-120">
            <div className="container">
               <div className="row" style={{ padding:'50px',boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '25px' }}>
                  <div className="col-12">
                     <form action="#">
                        <div className="table-content table-responsive">
                           <table className="table">
                              <thead>
                                 <tr>
                                    <th className="product-thumbnail" style={{ fontSize: '15px' }}>Images</th>
                                    <th className="cart-product-name" style={{ fontSize: '15px' }}>Product</th>
                                    <th className="product-subtotal" style={{ fontSize: '15px' }}>Price</th>
                                    <th className="product-remove" style={{ fontSize: '15px' }}>Remove</th>
                                 </tr>
                              </thead>
                              <tbody className="border-0">

                                 {getProductDetails.map((options, index) => {

                                    return <><tr>
                                       <td className="product-thumbnail">
                                          <Link to={`/onlinePharmacyView/${options.productid._id}`}>
                                          <LoadPreviewImage imagePath={'product'} image={options.productid.image1} alt="" style={wishListProductViewImageStyle}/>

                                             {/* <img src={baseurl + '/uploadFiles/product/' + options.productid.image1} alt="" style={{ width: '60px' }} /> */}
                                             </Link></td>
                                       <td className="product-name"><Link to={`/onlinePharmacyView/${options.productid._id}`}>{options.productid.product}</Link></td>
                                       <td className="product-subtotal"><span className="amount">RS. {options.productid.rate}</span></td>
                                       <td className="product-remove"><a style={{ cursor: "pointer" }} onClick={() => wishlistRemoveDetails(options.productid._id)}><i className="fa fa-times"></i></a></td>
                                    </tr></>

                                 })}

                              </tbody>
                           </table>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default WishListArea;