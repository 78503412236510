
const portfolioData = [
    {
        id: 1,
        img: 'img/project/gallery/1.jpg',
        category: 'general',
    },
    {
        id: 2,
        img: 'img/project/gallery/2.jpg',
        category: 'general',
    },
    {
        id: 3,
        img: 'img/project/gallery/3.jpg',
        category: 'general',
    },
    {
        id: 4,
        img: 'img/project/gallery/4.jpg',
        category: 'general',
    },
    {
        id: 5,
        img: 'img/project/gallery/5.jpg',
        category: 'general',
    },
    {
        id: 6,
        img: 'img/project/gallery/6.jpg',
        category: 'general',
    },
    // {
    //     id: 7,
    //     img: 'img/project/gallery/7.jpg',
    //     category: 'general',
    // },
    {
        id: 8,
        img: 'img/project/gallery/8.jpg',
        category: 'general',
    },
    // {
    //     id: 9,
    //     img: 'img/project/gallery/9.jpg',
    //     category: 'general',
    // },
    // {
    //     id: 10,
    //     img: 'img/project/gallery/10.jpg',
    //     category: 'general',
    // },
    {
        id: 11,
        img: 'img/project/gallery/11.jpg',
        category: 'general',
    },
    {
        id: 12,
        img: 'img/project/gallery/12.jpg',
        category: 'general',
    },
    {
        id: 13,
        img: 'img/project/gallery/13.jpg',
        category: 'general',
    },
    {
        id: 14,
        img: 'img/project/gallery/14.jpg',
        category: 'general',
    },
    {
        id: 15,
        img: 'img/project/gallery/15.jpg',
        category: 'general',
    },
    {
        id: 16,
        img: 'img/project/gallery/16.jpg',
        category: 'general',
    },
    {
        id: 17,
        img: 'img/project/gallery/17.jpg',
        category: 'general',
    },
    {
        id: 18,
        img: 'img/project/gallery/18.jpg',
        category: 'general',
    },
    {
        id: 19,
        img: 'img/project/gallery/19.jpg',
        category: 'general',
    },
    {
        id: 20,
        img: 'img/project/gallery/20.jpg',
        category: 'general',
    },
    {
        id: 21,
        img: 'img/project/gallery/21.jpg',
        category: 'general',
    },
    {
        id: 22,
        img: 'img/project/gallery/22.jpg',
        category: 'general',
    },
    {
        id: 23,
        img: 'img/project/gallery/23.jpg',
        category: 'general',
    },
    {
        id: 24,
        img: 'img/project/gallery/24.jpg',
        category: 'general',
    },
    {
        id: 25,
        img: 'img/project/gallery/25.jpg',
        category: 'general',
    },
    {
        id: 26,
        img: 'img/project/gallery/26.jpg',
        category: 'general',
    },
    {
        id: 27,
        img: 'img/project/gallery/27.jpg',
        category: 'general',
    },
    {
        id: 28,
        img: 'img/project/gallery/28.jpg',
        category: 'general',
    },
    {
        id: 29,
        img: 'img/project/gallery/29.jpg',
        category: 'general',
    },
    {
        id: 30,
        img: 'img/project/gallery/30.jpg',
        category: 'general',
    },
    {
        id: 31,
        img: 'img/project/gallery/31.jpg',
        category: 'general',
    },
    {
        id: 32,
        img: 'img/project/gallery/32.jpg',
        category: 'general',
    },
    {
        id: 33,
        img: 'img/project/gallery/33.jpg',
        category: 'general',
    },
    {
        id: 34,
        img: 'img/project/gallery/34.jpg',
        category: 'general',
    },
    {
        id: 35,
        img: 'img/project/gallery/35.jpg',
        category: 'general',
    },
    {
        id: 36,
        img: 'img/project/gallery/36.jpg',
        category: 'general',
    },
    {
        id: 37,
        img: 'img/project/gallery/37.jpg',
        category: 'general',
    },
    {
        id: 38,
        img: 'img/project/gallery/38.jpg',
        category: 'general',
    },
    {
        id: 39,
        img: 'img/project/gallery/39.jpg',
        category: 'general',
    },
    {
        id: 40,
        img: 'img/project/gallery/40.jpg',
        category: 'general',
    },
    {
        id: 41,
        img: 'img/project/gallery/41.jpg',
        category: 'general',
    },
    {
        id: 42,
        img: 'img/project/gallery/42.jpg',
        category: 'general',
    },
    {
        id: 43,
        img: 'img/project/gallery/43.jpg',
        category: 'general',
    },
    {
        id: 44,
        img: 'img/project/gallery/44.jpg',
        category: 'general',
    },
    {
        id: 45,
        img: 'img/project/gallery/45.jpg',
        category: 'general',
    },
    {
        id: 46,
        img: 'img/project/gallery/46.jpg',
        category: 'general',
    },
    {
        id: 47,
        img: 'img/project/gallery/47.jpg',
        category: 'general',
    },
    {
        id: 48,
        img: 'img/project/gallery/48.jpg',
        category: 'general',
    },
    {
        id: 49,
        img: 'img/project/gallery/49.jpg',
        category: 'general',
    },
    {
        id: 50,
        img: 'img/project/gallery/50.jpg',
        category: 'general',
    },
    {
        id: 51,
        img: 'img/project/gallery/51.jpg',
        category: 'general',
    },
    {
        id: 52,
        img: 'img/project/gallery/52.jpg',
        category: 'general',
    },
    {
        id: 53,
        img: 'img/project/gallery/53.jpg',
        category: 'general',
    },
]
export default portfolioData;