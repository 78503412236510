import React from 'react';

const SurgeriesList = () => {           
    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>Surgeries</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Surgeries</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
           <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h3>ENT</h3>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Tonsillectomy/Adenoidectomy(Unilateral/ Bilateral/ Laser/ Coblation)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Adenotonsillectomy.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tympanoplasty</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Mastoidectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Mastoidectomy & Tympanoplasty</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Peritonsillar abscess drainage (day care)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Microlaryngeal surgeries for cysts and polyps.</li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xl-12">
                        <h3>General Surgery</h3>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Myringotomy with grommet insertion</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Haemorrhoidectomy (stapler/ tackers included).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Haemorrhoidectomy + fissurectomy (stapler / tackers included)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Fissurectomy and fissure dilatation</li>
                            <li className="textAlign"><i class="fa fa-check"></i>High end fistulectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Low end fistulectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Appendectomy -LAP</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Appendectomy -Open</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Cholecystectomy (LAP)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Cholecystectomy (open)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Excision of pilonidal sinus with FLAP COVER</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Excision of pilonidal sinus with primary closure</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Mastectomy(simple)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Mastectomy(radical) or Modified Radical Mastectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Thyroidectomy (Total/Subtotal/Enucleation/Partial/Lingual/Isthmectomy)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Inguinal/ femoral hernioplasty-unilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Inguinal/ femoral hernioplasty-bilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Umblical hernioplasty</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Incisional hernioplasty</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Circumcision (day care)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Perianal abscess</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Breast lumpectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>AV fistula (day care)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Hydrocele</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Right or left hemi colectomy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Resection and anastomosis of small intestine (single)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Vericose veins (surgical)</li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xl-12">
                        <h3>OBSTETRCS & GYNE</h3>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Instrumental delivery with well baby care(single/twins)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Ovarian cystectomy Lap/ Open</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Dilatation and curettage ( D & C) ( DAY CARE)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Vaginal vault prolapse repair</li>
                            <li className="textAlign"><i class="fa fa-check"></i>ORTHOPAEDICS</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Total knee replacement- unilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Hip replacement bilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Fracture neck femur</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Hemiarthroplasty</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Femur shaft fracture-proximal /middle/distal</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tibia fracture proximal Unicondylar/middle/distal-ORIF/ ORIF</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tibia fracture proximal Bicondylar-ORIF/ ORIF</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Ankle fracture-ORIF/ORIF with screws/TBW</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Arthrodesis - wrist/ankle subtalar</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Hand or Foot fractures -with plates or screws</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Calcaneal fracture - with plates</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Open Reduction and Internal Fixation of shoulder / humerous.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Open Reduction and Internal Fixation of elbow</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Open Reduction and Internal Fixation -fracture of both bones forearm</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Open Reduction and Internal Fiaxation -fracture of single bone forearm/wrist</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Scaphoid fracture fixation</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Shoulder-arthroscopy / open- sub acromial decompression</li>
                            <li className="textAlign"><i class="fa fa-check"></i>ACL reconstruction /repair</li>
                            <li className="textAlign"><i class="fa fa-check"></i>MCL reconstruction/repair</li>
                            <li className="textAlign"><i class="fa fa-check"></i>ACL  & PCL reconstruction /repair</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Carpel tunnel release- unilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Carpel tunnel release- bilateral</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Close reduction of fractures / dislocations (day care)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Implant removal of small bones</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Implant removal of large bones</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Implant removal of spine</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Bone grafting for non union of small bones</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Bone grafting for non union of large bones</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Acetabular fracture fixation</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Pelvis fracture- external fixation</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Reduction of dislocation in GA</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Amputation of Digit -single</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Amputation of Digit -multiple</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Amputation above elbow/ knee</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Amputation below elbow/ knee</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Small Wound Debridement (Day Care)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Large Wound Debridement</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tendon Repair Single</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tendon Repair Multiple</li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xl-12">
                        <h3>UROLOGY</h3>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Prostate removal- TURP</li>
                            <li className="textAlign"><i class="fa fa-check"></i>DJ stent removal (day care).</li>
                        </ul>
                    </div>
                </div>
           </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>

                    

                </div>
            </section>
        </>
    );
};

export default SurgeriesList;