import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import axios from 'axios';
import { userURL } from "../../config";
import { ReactSession } from 'react-client-session';

const LoginArea = () => {

   const [showLoginError, setShowLoginError] = useState(false);
   const [showLoginError2, setShowLoginError2] = useState(false);

   const { register, handleSubmit, reset } = useForm();


   function authKeyGeneration(id, userName, email, phone) {

      var productDetail = {
         userId: id,
         userName: userName,
         email: email,
         phone: phone
      };

      axios
         .post(`${userURL}/user/api/tokenCreate`, productDetail)
         .then(data => {

            if (data.data.message == "Done") {

               ReactSession.set("token", data.data.token);

               const token = ReactSession.get("token");

               if (token) {
                  window.location.href = "/";
               }

            }

         })
         .catch(error => {

         });

   }

   const onSubmit = data => {

      console.log(data)

      if (data.email && data.password) {

         var productDetail = {
            email: data.email,
            password: data.password
         };

         axios
            .post(`${userURL}/user/checkLogin`, productDetail)
            .then(data => {

               console.log(data.data);

               if (data.data.success) {


                  if (Object.keys(data.data.data[0]).length != 0) {

                     ReactSession.set("userid", data.data.data[0]._id);
                     ReactSession.set("username", data.data.data[0].username);
                     ReactSession.set("email", data.data.data[0].email);
                     ReactSession.set("phone", data.data.data[0].phone);

                     const username = ReactSession.get("username");

                     if (username) {
                        authKeyGeneration(data.data.data[0]._id, data.data.data[0].username, data.data.data[0].email, data.data.data[0].phone);
                     }

                  }
                  else {

                     setShowLoginError2(true);

                  }

               }
               else {

                  setShowLoginError(true);

               }

            })
            .catch(error => {

               setShowLoginError(true);

            });

      }
      else {



      }

   };

   return (
      <>
         <section className="login-area pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                     <div className="basic-login">
                        <h3 className="text-center mb-60">Login From Here</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <label htmlFor="name" className='mb-2'>Email Address <span>**</span></label>
                           <input {...register("email")} required id="name" type="email"
                              placeholder="Email address..." autoComplete="off" />

                           <label htmlFor="pass" className='mb-2'>Password <span>**</span></label>
                           <input {...register("password")} required id="pass" autoComplete="off" type="password" placeholder="Enter password..." />

                           {showLoginError ? <p style={{ color: "red" }}>Please Try Again Later</p> : null}
                           {showLoginError2 ? <p style={{ color: "red" }}>InValid Email Or Password</p> : null}
                           <br />
                           <div className="login-action mb-20 fix">
                              <span className="log-rem f-left">
                                 <a href="/forgotpasword">Lost your password?</a>
                              </span>
                           </div>
                           <div className='col' style={{ margin: '50px' }}></div>

                           <button type="submit" className="primary_btn btn-icon-green w-100">Login Now</button>
                           <div className="or-divide"><span>or</span></div>
                           <Link to="/register"><button className="primary_btn theme-btn w-100">Register Now</button></Link>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default LoginArea;