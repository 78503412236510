import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-8 col-lg-7">
                     <div className="about-right-side mb-30">
                        <div className="about-title mb-20">
                           <h3>FOUNDER OF LYSANDER HOSPITAL</h3>
                        </div>
                        <div className="about-text mb-50">
                           <p style={{textAlign: "justify"}}>Dr. Herbert Lysander started the hospital in 1952, as a small clinic in Virudhunagar, 
                            Tamil Nadu state, India. Now it has grown into a multi-speciality hospital. 
                            It is situated in the heart of the town easily accessible to bus stand and Railway station.
                            </p>
                            <p style={{textAlign: "justify"}}>Motivated by his keen interest in the field of medicine, 
                                he went to Nagpur, where he completed his medical studies and acquired his L.M.P. degree. 
                                At that time, he had the good fortune of meeting Dr. Yoder, an American missionary, 
                                who had come to their college to give a guest lecture. On his advice, 
                                he decided to work and do service before he settled down to private medical practice. 
                                He went to Damtiri in North India where he worked for three years under the guidance of Dr. 
                                Yoder. It was through him that Dr. Lysander mastered his surgical skills and also learnt 
                                the importance of treating patients with kindness and a smile. 
                                That made him the man we know him to be.</p>
                                <p style={{textAlign: "justify"}}>In 1953, he came to Virudhunagar to set up his medical practice with 
                                    nothing more than a few surgical instruments given to him by 
                                    his mentor, Dr. Yoder, a second hand bicycle and Rs.110 in his pocket. 
                                    He was a total stranger and a newcomer to the close knit community in Virudhunagar. 
                                    It is with this meagre and humble beginning that he not only established a thriving practice and a 
                                    name for himself but also earned the love and esteem of so many people in and around Virudhunagar. 
                                    The first three years were the most difficult ones he had to face. He had to work very, very hard. 
                                    His total dedication to his work, his sincerity and his tireless service to humanity soon began to 
                                    bear fruit. His popularity began to spread far and near. He was philanthropic by nature and was 
                                    always available for anyone in need at any time of day or night. He had a cheerful smile and a 
                                    word of encouragement for everyone he met each day. There was not a single family in the 
                                    then small population of Virudhunagar, which was not touched by his kindness and generosity. 
                                    He also served as the Family Doctor for most of the local families.</p>
                                    <p style={{textAlign: "justify"}}>Despite his long hours at the hospital, he also found time for church activities, 
                                        club and social activities, counselling and always extending a helping hand to those in need. 
                                        He was the President of the Y.M.C.A., President of the I.M.A., 
                                        the Y's Men's Club and also of the Lions Club. As if this were not enough, 
                                        Dr. Lysander showed keen enthusiasm in Sports. He was a good athlete, 
                                        taking part in several events and even winning some prizes. 
                                        His favourite game was football.</p>
                                        <p style={{textAlign: "justify"}}>He was married to Mrs. Glory Lysander and was blessed with three sons and two daughters. 
                                            He was a loving and caring father.</p>
                                            <p style={{textAlign: "justify"}}>Mrs. Glory Lysander and Dr. Herbert Lysander were a God fearing and a truly blessed couple. 
                                                All through their lives, they strove to better the lives of the people around them. 
                                                They were able to reach out to many oppressed and downtrodden people of the society. 
                                                They will live forever in the hearts of those whose lives they have touched.</p>

                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;