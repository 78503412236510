import React from 'react';
import { Link } from 'react-router-dom';

import '../../privacy.css';

const BlogDetailsLeftSide3 = ({ details_topBar }) => {
    return (
        <>
            <div className="col-lg-12" style={{ textAlign: "justify"}}>
                <article className="postbox post format-image mb-40">


                    <div className="postbox__text bg-none">
                        <h3 className="blog-title">
                            SHIPPING POLICY
                        </h3>

                        <p className="para1 m-t-1-5">
                        Estimated shipping charges are calculated as per the value of the order and can be viewed in the cart section at the time of checkout. <br /> For any further shipping related information, please write to <a>lysanderhospitalaws@gmail.com</a>.                        
                        </p>
                        {/* <p className="para1 m-t-1">
                            You are advised to carefully read this Privacy Notice before using or availing any of our products and/or services.
                        </p> */}
                        <br/>


                    </div>

                </article>
            </div>
        </>
    );
};

export default BlogDetailsLeftSide3;