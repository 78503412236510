import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../../config";
import { ReactSession } from 'react-client-session';

const ShopDetailsBanner = () => {

   const [productName, setProductName] = useState("");
   const [image1, setImage1] = useState("");
   const [image2, setImage2] = useState("");
   const [image3, setImage3] = useState("");
   const [productRate, setProductRate] = useState(0);
   const [productmrp, setProductMRP] = useState(0);
   const [productdis, setProductDis] = useState(0);
   const [productType, setProductType] = useState("");
   const [productDes, setProductDes] = useState("");
   const [productAI, setProductAI] = useState("");

   let { id } = useParams();

   const [productWishAllowed, setProductWishAllowed] = useState();
   const [shopingAddToCart, setShopingAddToCart] = useState();

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");

   useEffect(() => {
      GetProductById(id);

      if (!ReactSession.get(`wishlistProduct${id}`)) {
         setProductWishAllowed('');
      }
      else if (ReactSession.get(`wishlistProduct${id}`)) {
         setProductWishAllowed(1);
      }

      if (!ReactSession.get(`addToCartProduct${id}`)) {
         setShopingAddToCart('');
      }
      else if (ReactSession.get(`addToCartProduct${id}`)) {
         setShopingAddToCart(1);
      }

   }, [])

   const GetProductById = id => {
      var productDetail = {
         id: id
      };

      axios
         .post(`${userURL}/product/getProductById`, productDetail)
         .then(data => {
            setProductName(data.data.data[0].product);
            setImage1(data.data.data[0].image1);
            setImage2(data.data.data[0].image2);
            setImage3(data.data.data[0].image3);
            setProductRate(data.data.data[0].originalrate);
            setProductDis(data.data.data[0].discount.discount);
            setProductMRP(data.data.data[0].mrp);
            setProductType(data.data.data[0].producttype.producttype);
            setProductDes(data.data.data[0].productdescription);
            setProductAI(data.data.data[0].additionalimformation);

         })
         .catch(error => {

         });

   }

   const addtocartDetails = () => {

      const addToCartProduct = ReactSession.get(`addToCartProduct${id}`);

      if (sessionUserId) {
         if (addToCartProduct) {

            const authToken = {
               headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
               userid: sessionUserId,
               productid: id
            };

            axios
               .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
               .then(data => {
                  ReactSession.set(`addToCartProduct${id}`, '');
                  setShopingAddToCart('');
               })
               .catch(error => {

               });

         }
         else {

            const authToken = {
               headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
               userid: sessionUserId,
               productid: id,
               producttotal: productRate
            };

            axios
               .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetail, authToken)
               .then(data => {
                  ReactSession.set(`addToCartProduct${id}`, 1);
                  setShopingAddToCart(1);
               })
               .catch(error => {

               });

         }
      }
      else {
         window.location.href = "/login";
      }


   }

   const wishlistDetails = () => {

      const wishlistProduct = ReactSession.get(`wishlistProduct${id}`);

      if (sessionUserId) {

         if (wishlistProduct) {

            const authToken = {
               headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
               userid: sessionUserId,
               productid: id
            };

            axios
               .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
               .then(data => {
                  ReactSession.set(`wishlistProduct${id}`, '');
                  setProductWishAllowed('');
               })
               .catch(error => {

               });

         }
         else {

            const authToken = {
               headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
               userid: sessionUserId,
               productid: id
            };

            axios
               .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
               .then(data => {
                  ReactSession.set(`wishlistProduct${id}`, 1);
                  setProductWishAllowed(1);
               })
               .catch(error => {

               });

         }

      }
      else {
         window.location.href = "/login";
      }
      
   }

   return (
      <>
         <section className="shop-banner-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7">
                     <div className="shop-thumb-tab mb-30">
                        <ul className="nav" id="myTab2" role="tablist">
                           <li className="nav-item">
                              <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                 aria-selected="true"><img src={baseurl + '/uploadFiles/product/' + image1} alt="" style={{ width: "160px", height: "107px" }} /> </a>
                           </li>
                           <li className="nav-item">
                              <a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                                 aria-selected="false"><img src={baseurl + '/uploadFiles/product/' + image2} alt="" style={{ width: "160px", height: "107px" }} /></a>
                           </li>
                           <li className="nav-item">
                              <a className="nav-link" id="profile-tab2" data-bs-toggle="tab" href="#profile1" role="tab"
                                 aria-selected="false"><img src={baseurl + '/uploadFiles/product/' + image3} alt="" style={{ width: "160px", height: "107px" }} /></a>
                           </li>
                        </ul>
                     </div>
                     <div className="product-details-img mb-30">
                        <div className="tab-content" id="myTabContent2">
                           <div className="tab-pane fade show active" id="home" role="tabpanel">
                              <div className="product-large-img">
                                 <img src={baseurl + '/uploadFiles/product/' + image1} alt="" style={{ width: "800px", height: "333px" }} />
                              </div>
                           </div>
                           <div className="tab-pane fade" id="profile" role="tabpanel">
                              <div className="product-large-img">
                                 <img src={baseurl + '/uploadFiles/product/' + image2} alt="" style={{ width: "800px", height: "333px" }} />
                              </div>
                           </div>
                           <div className="tab-pane fade" id="profile1" role="tabpanel">
                              <div className="product-large-img">
                                 <img src={baseurl + '/uploadFiles/product/' + image3} alt="" style={{ width: "800px", height: "333px" }} />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5">
                     <div className="product-details mb-30">
                        <div className="product-details-title">
                           <h1>{productName}</h1>
                           <div className="price details-price pb-30 mb-20">
                              <span>&#x20B9;{productRate}</span>
                              <span className="old-price" style={{ fontSize: "15px" }}>&#x20B9;{productmrp}</span>

                              <span className="" style={{ fontSize: "15px", color: "#8fb569" }}>{productdis}% Off</span>
                           </div>
                        </div>
                        <div className="product-cat mt-30 mb-30">
                           <span>Product Type: </span>
                           <span style={{ color: "#8fb569" }}>{productType}</span>
                        </div>
                        <div className="product-details-action">
                           <form action="#">
                              <div className="plus-minus">
                                 <div className="cart-plus-minus"><input type="text" defaultValue="1" /></div>
                              </div>
                              {
                                 shopingAddToCart == 1 ?
                                    <><button className="primary_btn btn-black" type="button" style={{ background: "#e12454" }} onClick={addtocartDetails}>remove cart</button></>
                                    :
                                    <><button className="primary_btn btn-black" type="button" style={{ background: "#8fb569" }} onClick={addtocartDetails}>add to cart</button></>
                              }

                              {
                                 productWishAllowed == 1 ?
                                    <><button className="action-btn" type="button" style={{ background: "#e12454" }} onClick={wishlistDetails}><i className="fas fa-heart"></i></button></>
                                    :
                                    <><button className="action-btn" type="button" style={{ background: "#8fb569" }} onClick={wishlistDetails}><i className="fas fa-heart"></i></button></>
                              }
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="product-desc-area pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="bakix-details-tab">
                        <ul className="nav text-center justify-content-center pb-30 mb-50" id="myTab" role="tablist">
                           <li className="nav-item">
                              <a className="nav-link active" id="desc-tab" data-bs-toggle="tab" href="#id-desc" role="tab"
                                 aria-controls="home" aria-selected="true">Description </a>
                           </li>
                           <li className="nav-item">
                              <a className="nav-link" id="id-add-in" data-bs-toggle="tab" href="#id-add" role="tab"
                                 aria-controls="profile" aria-selected="false">Additional Information</a>
                           </li>
                        </ul>
                     </div>
                     <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="id-desc" role="tabpanel" aria-labelledby="desc-tab">
                           <div className="event-text mb-40">
                              <div dangerouslySetInnerHTML={{ __html: productDes }} />
                           </div>
                        </div>

                        <div className="tab-pane fade" id="id-add" role="tabpanel" aria-labelledby="id-add-in">
                           <div className="additional-info">
                              <div className="table-responsive">
                                 <div dangerouslySetInnerHTML={{ __html: productAI }} />
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section><br /><br /><br />
      </>
   );
};

export default ShopDetailsBanner;