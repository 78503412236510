import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL, imageUrlIs } from "../../../config";

import { UserContext } from '../../../LayoutPages/Header/UserContext';

import { ReactSession } from 'react-client-session';

import Swal from 'sweetalert2';

import Zoom from 'react-img-zoom'

import LoadPreviewImage from "../../../config/LoadPreviewImage";
import { Height } from '@material-ui/icons';

const PharmactProductView = () => {

    const { getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger } = useContext(UserContext);

    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");
    const [productRate, setProductRate] = useState(0);
    const [productmrp, setProductMRP] = useState(0);
    const [productdis, setProductDis] = useState(0);
    const [productType, setProductType] = useState("");
    const [productDes, setProductDes] = useState("");
    const [productAI, setProductAI] = useState("");
    const [productQty, setProductQty] = useState(1);
    const [increaseQtyCount, setIncreaseQtyCount] = useState('');



    const [productDetails, setProductDetails] = useState("");

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");

    const [qtyLimit, setQtyLimit] = useState();
    const [productUOM, setProductUOM] = useState('');

    let { id } = useParams();

    const [totalUserWishlist, setTotalUserWishlist] = useState([]);
    const [totalUserCart, setTotalUserCart] = useState([]);
    const [inStock, setInstock] = useState(false);
    const [checkScreen, setCheckScreen] = useState(0);

    const [imageUrl1, setImageUrl1] = useState();
    const [imageUrl2, setImageUrl2] = useState();
    const [imageUrl3, setImageUrl3] = useState();

    const ProductViewImageStyle = { width: '450px', height: '450px' };

    const leftSideProductViewImageStyle = { width: '150px', height: '150px' };

    useEffect(() => {
        GetProductById(id);
        totalWishlist();
        totalCart();
        if (window.innerWidth < 860) {
            setCheckScreen(0);
        }
        else {
            setCheckScreen(1);
        }
    }, [])

    const GetProductById = id => {
        var productDetail = {
            id: id
        };

        axios
            .post(`${userURL}/product/getProductById`, productDetail)
            .then(data => {
                console.log('data', data);

                setProductId(data.data.data[0]._id);
                setProductName(data.data.data[0].product);
                setImage1(data.data.data[0].image1);
                setImage2(data.data.data[0].image2);
                setImage3(data.data.data[0].image3);
                setProductRate(data.data.data[0].rate);
                setProductDis(data.data.data[0].discountrate);
                setProductMRP(data.data.data[0].mrp);
                setProductType(data.data.data[0].producttype.producttype);
                setProductDes(data.data.data[0].productdescription);
                setProductAI(data.data.data[0].additionalimformation);
                setProductDetails(data.data.data[0].productdetails);
                setProductUOM(data.data.data[0].uom.uom);
                setInstock(data.data.data[0].stock);

                if (data.data.data[0].qtyLimit) {
                    // alert('1');
                    setProductQty(data.data.data[0].qtyLimit);
                    console.log('data.data.data[0].qtyLimitdata.data.data[0].qtyLimitdata.data.data[0].qtyLimit', data.data.data[0].qtyLimit)
                    setQtyLimit(data.data.data[0].qtyLimit);
                }
                else {
                    // alert('2')
                    setQtyLimit(1);
                }

            })
            .catch(error => {

            });

    }

    const wishlistActions = (getIsWishlistHere, productId) => {

        if (sessionToken) {
            if (getIsWishlistHere == 1) {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                axios
                    .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
                    .then(data => {
                        setGetWishlistTrigger(getWishlistTrigger - 1);
                        totalWishlist();
                    })
                    .catch(error => {

                    });

            }
            else if (getIsWishlistHere == 0) {
                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                axios
                    .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
                    .then(data => {
                        setGetWishlistTrigger(getWishlistTrigger + 1);
                        totalWishlist();
                    })
                    .catch(error => {

                    });

            }
        }
    }

    const wishlistDetails = (productId) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/checkProductWishlist`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        wishlistActions(data.data.data, productId);
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    console.log(result);
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const addToCartDetails = (productId, originalRate) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/checkProductCart`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        console.log('dsyiguyi', data.data.data, productId, originalRate)
                        AddToCartActions(data.data.data, productId, originalRate);
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    console.log(result);
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const AddToCartActions = (getIsAddToCartHere, productId, originalrate) => {

        if (sessionToken) {
            if (getIsAddToCartHere == 1) {


                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                        axios
                            .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
                            .then(data => {
                                setGetAddToCartTrigger(getAddToCartTrigger - 1);
                                totalCart();
                            })
                            .catch(error => {

                            });
                    // })

            }
            else if (getIsAddToCartHere == 0) {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };
                
                const productDetail = { 
                    userid: sessionUserId,
                    productid: productId,
                    producttotal: originalrate * productQty,
                    productqty: productQty,
                    
                };

                console.log('productDetailproductDetailproductDetailproductDetail', productDetail)

                axios
                    .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetail, authToken)
                    .then(data => {
                        console.log(data.data)
                        setGetAddToCartTrigger(getAddToCartTrigger + 1);
                        totalCart();
                    })
                    .catch(error => {

                    });

            }
        }
    }

    /** Cart and WIshlist */

    const totalWishlist = () => {

        if (sessionUserId) {
            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/productWishlist`, productDetail)
                .then(data => {
                    setTotalUserWishlist(data.data.data);
                })
                .catch(error => {

                });
        }

    }

    const totalCart = () => {

        if (sessionUserId) {
            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/productCart`, productDetail)
                .then(data => {
                    setTotalUserCart(data.data.data);
                })
                .catch(error => {

                });
        }

    }

    const checkInWishlist = (id) => {

        if (totalUserWishlist.length != 0) {
            for (var i = 0; i < totalUserWishlist.length; i++) {
                if (totalUserWishlist[i].productid == id) {
                    return 1;
                    break;
                }
            }
            return 0;
        }
        else {
            return 0;
        }

    }

    var proQty = 1;
    const checkInCart = (id) => {
        if (totalUserCart.length != 0) {

            for (var i = 0; i < totalUserCart.length; i++) {
                if (totalUserCart[i].productid == id) {
                    return 1;
                    break;
                }
            }
            return 0;

        }
        else {
            return 0;
        }
    }

    var wishlistIsHere = checkInWishlist(productId);
    var CartIsHere = checkInCart(productId);

    const increaseQty = () => {

        setProductQty(productQty + 1);

    }

    const decreaseQty = () => {

        if ((productQty - 1) != 0 && (productQty - 1) > 0 && (qtyLimit <= (productQty - 1))) {

            setProductQty(productQty - 1);

        }

    }

    useEffect(() => {
        loadImageUrl1();
        loadImageUrl2();
        loadImageUrl3();
    }, [image1, image2, image3])

    const loadImageUrl1 = () => {

        axios
            .get(`${imageUrlIs}/product/${image1}`)
            .then(data => {
                // console.log("Image Load Is");
                // console.log(data);
                if (data.data.status == 200) {

                    setImageUrl1(data.data.url);
                }
                else {
                    console.log("Error Is a 1");
                }

            })
            .catch(error => {
                console.log("Error Is a 2 " + image1);
            });
    }

    const loadImageUrl2 = () => {
        // if(image2=='-' || image2==''){
        //     console.log('212343');
        //     axios
        //     .get(`${imageUrlIs}/product/1423.png`)
        //     .then(data => {
        //         // console.log("Image Load Is");
        //         // console.log('12212112', data);
        //         if (data.data.status == 200) {

        //             setImageUrl2(data.data.url);
        //         }
        //         else {
        //             console.log("Error Is a 1");
        //         }

        //     })
        //     .catch(error => {
        //         console.log("Error Is a 2434 " + image2);
        //     });
        // }
        // else{
        axios
            .get(`${imageUrlIs}/product/${image2}`)
            .then(data => {
                // console.log("Image Load Is");
                // console.log('12212112', data);
                if (data.data.status == 200) {

                    setImageUrl2(data.data.url);
                }
                else {
                    console.log("Error Is a 1");
                }

            })
            .catch(error => {
                console.log("Error Is a 25555 " + image2);
            });
        // }

    }

    const loadImageUrl3 = () => {

        axios
            .get(`${imageUrlIs}/product/${image3}`)
            .then(data => {
                // console.log("Image Load Is");
                // console.log(data);
                if (data.data.status == 200) {

                    setImageUrl3(data.data.url);
                }
                else {
                    console.log("Error Is a 1");
                }

            })
            .catch(error => {
                console.log("Error Is a 2 " + image3);
            });
    }

    return (
        <>
            <main className="main-wrapper">
                <div className="axil-single-product-area bg-color-white">
                    <div className="single-product-thumb axil-section-gap pb--20 pb_sm--0 bg-vista-white" style={{ background: "white" }}>
                        <div className="container">
                            <div className="row row--25">
                                <div className="col-lg-7 mb--40">
                                    <div className="h-100">
                                        <div className="position-sticky sticky-top">

                                            <div className="row row--10">

                                                <div className="shop-thumb-tab mb-30">
                                                    <ul className="nav" id="myTab2" role="tablist">

                                                        {
                                                            image1 != "-" && image2 != "-" && image3 != "-"
                                                                ?
                                                                <>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" new={image1} id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                                                            aria-selected="true"><img src={imageUrl1} style={leftSideProductViewImageStyle} /> </a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                                                                            aria-selected="false"><img src={imageUrl2} style={leftSideProductViewImageStyle} /></a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="profile-tab2" data-bs-toggle="tab" href="#profile1" role="tab"
                                                                            aria-selected="false"><img src={imageUrl3} style={leftSideProductViewImageStyle} /></a>
                                                                    </li>
                                                                </>
                                                                :
                                                                image1 != "-" && image2 == "-" && image3 == "-"
                                                                    ?
                                                                    <>
                                                                        <li className="nav-item">
                                                                            <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                                                                aria-selected="true"><img src={imageUrl1} style={leftSideProductViewImageStyle} /> </a>
                                                                        </li>
                                                                    </>
                                                                    :
                                                                    image1 != "-" && image2 != "-" && image3 == "-"
                                                                        ?
                                                                        <>
                                                                            <li className="nav-item">
                                                                                <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                                                                    aria-selected="true"><img src={imageUrl1} style={leftSideProductViewImageStyle} /> </a>
                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                                                                                    aria-selected="false"><img src={imageUrl2} style={leftSideProductViewImageStyle} /></a>
                                                                            </li>
                                                                        </>
                                                                        :
                                                                        image1 != "-" && image2 == "-" && image3 != "-"
                                                                            ?
                                                                            <>
                                                                                <li className="nav-item">
                                                                                    <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                                                                        aria-selected="true"><img src={imageUrl1} style={leftSideProductViewImageStyle} /> </a>
                                                                                </li>
                                                                                <li className="nav-item">
                                                                                    <a className="nav-link" id="profile-tab2" data-bs-toggle="tab" href="#profile1" role="tab"
                                                                                        aria-selected="false"><img src={imageUrl3} style={leftSideProductViewImageStyle} /></a>
                                                                                </li>
                                                                            </>
                                                                            :
                                                                            null
                                                        }

                                                    </ul>
                                                </div>
                                                <div className="product-details-img mb-30" style={{
                                                    width: "66%",
                                                    marginLeft: "40px"
                                                }}>
                                                    <div className="tab-content" id="myTabContent2">
                                                        {
                                                            image1 != "-"
                                                                ?
                                                                <div className="tab-pane fade show active" id="home" role="tabpanel">
                                                                    <div className="product-large-img">

                                                                        {
                                                                            checkScreen != 0
                                                                                ?
                                                                                image1
                                                                                    ?
                                                                                    /* <Zoom
                                                                                         img={baseurl + '/uploadFiles/product/' + image1}
                                                                                         zoomScale={3}
                                                                                         width={450}
                                                                                         height={450}
                                                                                         className=""
                                                                                     /> */
                                                                                    <LoadPreviewImage imagePath={'product'} image={image1} style={ProductViewImageStyle} />
                                                                                    :
                                                                                    <h3>Not FOund</h3>
                                                                                :
                                                                                <LoadPreviewImage imagePath={'product'} image={image1} />
                                                                        }


                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            image2 != "-"
                                                                ?
                                                                <div className="tab-pane fade" id="profile" role="tabpanel">
                                                                    <div className="product-large-img">

                                                                        {
                                                                            checkScreen != 0
                                                                                ?
                                                                                image2
                                                                                    ?
                                                                                    /*   <Zoom
                                                                                           img={baseurl + '/uploadFiles/product/' + image2}
                                                                                           zoomScale={3}
                                                                                           width={450}
                                                                                           height={450}
                                                                                           className=""
                                                                                       /> */
                                                                                    <LoadPreviewImage imagePath={'product'} image={image2} style={ProductViewImageStyle} />
                                                                                    :
                                                                                    <h3>Not FOund</h3>
                                                                                :
                                                                                <LoadPreviewImage imagePath={'product'} image={image2} />
                                                                        }

                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            image3 != "-"
                                                                ?
                                                                <div className="tab-pane fade" id="profile1" role="tabpanel">
                                                                    <div className="product-large-img">

                                                                        {
                                                                            checkScreen != 0
                                                                                ?
                                                                                image3
                                                                                    ?
                                                                                    /*  <Zoom
                                                                                          img={baseurl + '/uploadFiles/product/' + image3}
                                                                                          zoomScale={3}
                                                                                          width={450}
                                                                                          height={450}
                                                                                          className=""
                                                                                      /> */
                                                                                    <LoadPreviewImage imagePath={'product'} image={image3} style={ProductViewImageStyle} />
                                                                                    :
                                                                                    <h3>Not FOund</h3>
                                                                                :
                                                                                <LoadPreviewImage imagePath={'product'} image={image3} style={ProductViewImageStyle} />
                                                                        }

                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 mb--40">
                                    <div className="h-100">
                                        <div className="position-sticky sticky-top">
                                            <div className="single-product-content">
                                                <div className="inner">
                                                    <h2 className="product-title">{productName.charAt(0).toUpperCase() + productName.slice(1).toLowerCase()}</h2>
                                                    <span className="price-amount">&#x20B9;{Math.round(productRate).toFixed(2)}</span>
                                                    <span className="price old-price" style={{ fontSize: "17px", color: "black", textDecoration: "line-through" }}>MRP: ₹ {Math.round(productmrp).toFixed(2)}</span>&nbsp;&nbsp;&nbsp;&nbsp;

                                                    {
                                                        productdis != 0.00
                                                            ?
                                                            <span style={{ fontSize: "15px", color: "rgb(26, 171, 42)" }}>₹ {parseFloat(productdis).toFixed(2)} Off</span>
                                                            :
                                                            null
                                                    }

                                                    <br /><br />

                                                    <ul className="product-meta" style={{ marginBottom: "0" }}>

                                                        {
                                                            productDetails
                                                                ?
                                                                <li style={{ color: "black", fontWeight: "700" }}><i className="fal fa-check"></i>{productDetails}</li>
                                                                :
                                                                null
                                                        }

                                                        <li><i className="fal fa-check"></i>Inclusive of all taxes</li>

                                                        {
                                                            inStock
                                                                ?
                                                                <li><i className="fal fa-check"></i>In stock</li>
                                                                :
                                                                <li style={{ color: "red" }}><i className="fal fa-check"></i>Out Of Stock</li>
                                                        }

                                                        {
                                                            qtyLimit != 1
                                                                ?
                                                                <li><i className="fal fa-check"></i>Minimum number of quantity is {qtyLimit} {productUOM.toLowerCase()}</li>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            inStock
                                                                ?
                                                                CartIsHere == 1
                                                                    ?
                                                                    null
                                                                    :
                                                                    <li>
                                                                        <div style={{ marginLeft: "40px", color: "GREEN" }}><br />{productUOM}</div>
                                                                    </li>
                                                                :
                                                                null
                                                        }

                                                    </ul>


                                                    <div className="product-action-wrapper d-flex-center">



                                                        {
                                                            inStock
                                                                ?
                                                                <>
                                                                    {
                                                                        CartIsHere == 1
                                                                            ?
                                                                            null
                                                                            :

                                                                            <div className='row' style={{ marginTop: '-250px' }}>
                                                                                <div className='col' style={{ margin: '10px' }}>
                                                                                    <div className='col-s' style={{ margin: '250px', marginLeft: '0px' }}>
                                                                                        <div className="pro-qty mr--20">
                                                                                            <span className="dec qtybtn" onClick={() => decreaseQty()}>-</span><input type="text" value={productQty} /><span className="inc qtybtn" onClick={() => increaseQty()}>+</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                    }

                                                                    <div className='col-s' style={{ marginTop: '90px' }}>

                                                                        <div className='row' style={{ margin: '150px' }}>
                                                                            <div className="product-action d-flex-center mb--0">
                                                                                <div className='row'>
                                                                                    <div className='row add-to-cart'>
                                                                                        <button onClick={() => addToCartDetails(productId, productRate)} className="axil-btn btn-bg-primary" style={{ marginBottom: '440px', marginRight: '100px' }}>
                                                                                            {CartIsHere == 1 ? 'Remove' : 'Add to Cart'}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row' style={{ margin: '150px' }}>
                                                                            <div className="product-action d-flex-center mb--0">
                                                                                <div className='row'>
                                                                                    <div className='col wishlist'>
                                                                                        <button href="#" onClick={() => wishlistDetails(productId)} className="axil-btn wishlist-btn" style={{ padding: '15px 13px 10px', marginRight: '-240px', marginLeft: '240px', marginBottom: '440px' }}>
                                                                                            {wishlistIsHere == 1 ? <i className="far fa-heart" style={{ fontWeight: 600, color: 'rgb(225, 36, 84)' }}></i> : <i className="far fa-heart"></i>}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                                :
                                                                null
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col' style={{ margin: '-380px' }}></div>
                {/* <div className='col-s' style={{ margin: '-720px' }}></div> */}
                {/* <div className='col-m' style={{ margin: '250px' }}></div>  */}
                {/* <div className='col-l' style={{ margin: '-420px' }}></div> */}

                <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white" style={{ padding: "0px 0 35px" }}>
                    <br /><br /><div className="container">
                        <ul className="nav tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true" style={{ fontSize: "18px" }}>Description</a>
                            </li>
                            <li className="nav-item " role="presentation">
                                <a id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab" aria-controls="additional-info" aria-selected="false" className="" style={{ fontSize: "18px" }}>Additional Information</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="description" role="tabpanel" aria-labelledby="description-tab">
                                <div className="product-desc-wrapper">


                                    <div dangerouslySetInnerHTML={{ __html: productDes }} />

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ul className="pro-des-features pro-desc-style-two">
                                                <li className="single-features" style={{ fontSize: "18px" }}>
                                                    <center><div className="icon">
                                                        <img src="../assets/images/product/product-thumb/icon-3.png" alt="icon" />
                                                    </div></center>
                                                    Secure Delivery
                                                </li>
                                                <li className="single-features" style={{ fontSize: "18px" }}>
                                                    <center><div className="icon">
                                                        <img src="../assets/images/product/product-thumb/icon-2.png" alt="icon" />
                                                    </div></center>
                                                    100% Guarantee
                                                </li>
                                                <li className="single-features" style={{ fontSize: "18px" }}>
                                                    <center><div className="icon">
                                                        <img src="../assets/images/product/product-thumb/icon-1.png" alt="icon" />
                                                    </div></center>
                                                    Next Level Quality
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="additional-info" role="tabpanel" aria-labelledby="additional-info-tab">
                                <div className="product-additional-info">
                                    <div dangerouslySetInnerHTML={{ __html: productAI }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </main>
        </>
    );

};

export default PharmactProductView;