import React, { useState } from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
//import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Footer from '../../LayoutPages/Footer/Footer';
import HomeHeader from '../../LayoutPages/Header/ShopHeader';
import OrderArea from './OrderArea/OrderArea';

import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';

import ShopCategoryHeader from '../../LayoutPages/Header/ShopCategoryHeader';
import PharmacyFooter from '../../LayoutPages/Footer/PharmacyFooter';

import { UserContext } from '../../LayoutPages/Header/UserContext';

const WishList = () => {

   const sessionToken = ReactSession.get("token");

   if (!sessionToken) {
      Swal.fire('Oops..', 'Please Login Your Account', 'error')
         .then((result) => {
            console.log(result);
            if (result) {
               window.location.href = "/login";
            } else {

            }
         });
   }

   const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
   const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

   return (
      <>
         <UserContext.Provider value={{ getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger }}>
            <ShopCategoryHeader />
            <OrderArea />
         </UserContext.Provider>
         <PharmacyFooter />
      </>
   );
};

export default WishList;