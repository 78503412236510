import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../../config";
import { ReactSession } from 'react-client-session';

const WishListArea = () => {

   // const uploadImg = 'http://localhost:8080/';
    const uploadImg = 'http://202.21.38.171:8080/';

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");

   const [getOrderDetails, setGetOrderDetails] = useState([]);

   useEffect(() => {

      showAllOrder();

   }, [])

   const showAllOrder = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showorderlist`, productDetail, authToken)
         .then(data => {
            console.log('5544545',data.data.data);
            setGetOrderDetails(data.data.data);
         })
         .catch(error => {

         });

   }

   return (
      <>
         <section className="cart-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <form action="#">
                        <div className="table-content table-responsive">
                           <table className="table">
                              <thead>

                                 <tr>
                                    <th className="product-thumbnail">S.no</th>
                                    <th className="product-thumbnail">Order Id</th>
                                    <th className="cart-product-name">Order Date</th>
                                    <th className="product-subtotal">Amount</th>
                                    <th className="product-subtotal">View</th>
                                 </tr>

                              </thead>
                              <tbody className="border-0">

                                 {

                                    getOrderDetails.map((options, index) => {

                                       return <>


                                          <tr>
                                             <td className="product-thumbnail">{index + 1}</td>
                                             <td className="product-thumbnail">{options.ordergenerateid}</td>
                                             <td className="product-name">{options.orderdate}</td>
                                             <td className="product-subtotal"><span className="amount">{options.totalamount}</span></td>
                                             <td className="product-subtotal">
                                             <a className="primary_btn theme-btn" href={'orderview/'+options._id}>View</a>&nbsp;
                                             <a className="primary_btn theme-btn" href={`${uploadImg}uploadFiles/pdf/${options._id}.pdf`}>Invoice View</a>
                                             </td>
                                          </tr>

                                       </>

                                    })}

                                 <tr>
                                    <td className="product-thumbnail"></td>
                                    <td className="product-name"></td>
                                    <td className="product-subtotal"><span className="amount"></span></td>
                                    <td className="product-subtotal"></td>
                                    <td className="product-subtotal"></td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default WishListArea;