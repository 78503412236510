import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';

import { userURL, baseurl, userDetailsURL, PaymentGatewayURL, RAZOR_PAY_KEY_ID, masterUrl } from "../../../../src/pages/config";
import { ReactSession } from 'react-client-session';
import UserDetailsForm from '../../User/UserDetails/UserDetails/UserDetailsForm';
import BillDetailsForm from '../../User/UserDetails/UserDetails/BillingDetailsForm';
const CheckoutArea = () => {

   const { mid, mtid } = useParams()
   const [addressShow, setAddressShow] = useState(false);
   const [loader, setLoader] = useState(false);
   const [createAccountShow, setCreateAccoutShow] = useState(false);

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");
   const sessionEmail = ReactSession.get("email");
   const sessionPhone = ReactSession.get("phone");
   const sessionUsername = ReactSession.get("username");

   const [getProductDetails, setGetProductDetails] = useState([]);
   const [getProductTotalWeights, setGetProductTotalWeights] = useState(0);

   const [userFirstname, setUserFirstname] = useState('');
   const [userLastname, setUserLastname] = useState('');
   const [userAddress, setUserAddress] = useState('');
   const [userApartment, setUserApartment] = useState('');
   const [userCity, setUserCity] = useState('');
   const [userState, setUserState] = useState('');
   const [userZipcode, setUserZipcode] = useState('');
   const [userEmail, setUserEmail] = useState(sessionEmail);
   const [userPhone, setUserPhone] = useState(sessionPhone);
   const [userDetailsId, setUserDetailsId] = useState(0);
   const [holeTotal, setHoleTotal] = useState(0);

   const [userAddress2, setUserAddress2] = useState();
   const [userApartment2, setUserApartment2] = useState();
   const [userCity2, setUserCity2] = useState();
   const [userState2, setUserState2] = useState();
   const [userZipcode2, setUserZipcode2] = useState();
   const [orderNotes, setOrderNotes] = useState();

   const [userDistrict, setUserDistrict] = useState('');

   const [useDifferentaddress, setUseDifferentaddress] = useState(false);

   const [productTotalRate, setProductTotalRate] = useState();

   const [userCityID, setUserCityId] = useState('');
   const [userStateId, setUserStateId] = useState('');
   const [userDistrictId, setUserDistrictId] = useState('');

   const [shippingCost, setShippingCost] = useState(0);
   const [shippingDetails, setShippingDetails] = useState();
   const [orderTotal, setOrderTotal] = useState(0);

   useEffect(() => {
      showAllCartProduct();
      showUserDetails();
   }, [])

   useEffect(() => {
      if (mid == "new" && mtid == "page") {
         return;
      } else {
         const merhcantId = alphanumericDecode(mid)
         const merchantTransactionId = alphanumericDecode(mtid)
         if (merhcantId == "MERCHANTUAT" || merhcantId == "LYSANDERONLINE") {
            const authToken = {
               headers: { Authorization: `Bearer ${sessionToken}` }
            };

            var totalAmount2 = 0;
            var getProductDetails2 = ReactSession.get("getProductDetails");

            for (var i = 0; i < getProductDetails2.length; i++) {
               totalAmount2 = totalAmount2 + getProductDetails2[i].producttotal;
            }

            const body = {
               merhcantId: merhcantId,
               merchantTransactionId: merchantTransactionId,

               userid: sessionUserId,

               firstname: ReactSession.get("userFirstname"),
               lastname: ReactSession.get("userLastname"),
               address: ReactSession.get("userAddress"),
               apportment: ReactSession.get("userApartment"),
               city: ReactSession.get("userCity"),
               district: ReactSession.get("userDistrict"),
               state: ReactSession.get("userState"),
               pincode: ReactSession.get("userZipcode"),
               email: ReactSession.get("email"),
               phonenumber: ReactSession.get("phone"),

               shippingdifferentaddress: addressShow,
               onlinepayment: true,
               productTotalAmount: totalAmount2,
               shippingCost: ReactSession.get("shippingCost"),
               totalAmount: (parseInt(totalAmount2) + parseInt(ReactSession.get("shippingCost"))).toFixed(2),
               ordernotes: orderNotes,
               shippingstageid: 1,

               productDatas: ReactSession.get("getProductDetails"),
               email: ReactSession.get("email"),

            }
            setLoader(true);
            axios
               .post(`${PaymentGatewayURL}/paymentstatus`, body, authToken)
               .then(async res => {

                  if (res.data.success) {
                     await redirectAfterPayment();
                     // await afterPayment(res.data.data);

                     /*   showAllCartProduct();
                        showUserDetails();
                        Swal.fire({
                           icon: 'success',
                           title: 'Success',
                           text: res.data.message,
                           footer: 'go to order page'
                        }).then(async result => {
                           //window.location.href = "/orderdetails";
                           console.log("Payment Details Success");
                           console.log(res.data.data);
   
                           var dataDetail1 = {
                              userid: sessionUserId,
                              firstname: userFirstname,
                              lastname: userLastname,
                              address: userAddress,
                              apportment: userApartment,
                              city: userCity,
                              district: userDistrict,
                              state: userState,
                              pincode: userZipcode,
                              email: userEmail,
                              phonenumber: userPhone
                           };
   
                           console.log("dataDetail1:");
                           console.log(dataDetail1);
                           await afterPayment(res.data.data);
                           
                        }) */


                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: res.data.message,
                        footer: 'Try again'
                     }).then(result => {
                        window.location.href = "/checkout/new/page";
                     })
                  }

               })
               .catch(error => {
                  console.log(error)
               });
         }


      }
   }, [])

   const redirectAfterPayment = async () => {
      window.location.href = "/paymentLanding";
   }
   function alphanumericDecode(encodedString) {
      const base64 = encodedString.replace(/[^A-Za-z0-9]/g, '');
      return atob(base64);
   }
   useEffect(() => {

      getShippingCost();

   }, [userCityID, userStateId, userDistrictId])

   const showAllCartProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showCartDetails`, productDetail, authToken)
         .then(data => {
            setGetProductDetails(data.data.data);
            ReactSession.set("getProductDetails", data.data.data);
         })
         .catch(error => {

         });

   }

   const showUserDetails = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/selectUserDetails`, productDetail, authToken)
         .then(data => {
            ReactSession.set("userFirstname", data.data.userInfo[0].firstname);
            ReactSession.set("userLastname", data.data.userInfo[0].lastname);
            ReactSession.set("userAddress", data.data.userInfo[0].street);
            ReactSession.set("userApartment", data.data.userInfo[0].apportment);
            ReactSession.set("userCity", data.data.userInfo[0].city.city);
            ReactSession.set("userState", data.data.userInfo[0].state.state);
            ReactSession.set("userDistrict", data.data.userInfo[0].district.district);
            ReactSession.set("userZipcode", data.data.userInfo[0].postcode);
            setUserFirstname(data.data.userInfo[0].firstname);
            setUserLastname(data.data.userInfo[0].lastname);
            setUserAddress(data.data.userInfo[0].street);
            setUserApartment(data.data.userInfo[0].apportment);
            //setUserCity(data.data.data[0].city);
            //setUserState(data.data.data[0].state);
            setUserZipcode(data.data.userInfo[0].postcode);
            setUserDetailsId(data.data.userInfo[0]._id);

            getStateById(data.data.userInfo[0].state);
            getDistrictById(data.data.userInfo[0].district);
            getCityById(data.data.userInfo[0].city);

            setUserStateId(data.data.userInfo[0].state);
            setUserDistrictId(data.data.userInfo[0].district);
            setUserCityId(data.data.userInfo[0].city);

         })
         .catch(error => {
            setAddressShow(true);
         });

   }

   const paymentSubmit = async () => {

      var totalCalculationAmount = Math.round(parseInt(orderTotal) + parseInt(shippingCost));

      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };
      const body = {
         amount: totalCalculationAmount,
         phone: userPhone
      }
      axios
         .post(`${PaymentGatewayURL}/phonepay`, body, authToken)
         .then(data => {
            const checkoutRes = data.data.data;
            window.location.replace(checkoutRes.instrumentResponse.redirectInfo.url);
         })
         .catch(error => {
            console.log(error)
         });


   };

   const getStateById = stateId => {

      var stateDetail = {
         id: stateId,
      };

      axios
         .post(`${masterUrl}/shipping/getOneStateById`, stateDetail)
         .then(data => {

            setUserState(data.data.data);
         })
         .catch(error => { });

   }

   const getDistrictById = districtId => {

      var districtDetail = {
         id: districtId,
      };

      axios
         .post(`${masterUrl}/shipping/getOneDistrictById`, districtDetail)
         .then(data => {
            setUserDistrict(data.data.data);
         })
         .catch(error => { });

   }

   const getCityById = cityId => {

      var cityDetail = {
         id: cityId,
      };

      axios
         .post(`${masterUrl}/shipping/getOneCityById`, cityDetail)
         .then(data => {
            setUserCity(data.data.data);
         })
         .catch(error => { });

   }

   const getShippingCost = () => {

      var shippingCostDetail = {

         city: userCityID,
         district: userDistrictId,
         state: userStateId

      };

      axios
         .post(`${masterUrl}/shipping/getShippingCostForCalculate`, shippingCostDetail)
         .then(data => {
            setShippingDetails(data.data.data);

         })
         .catch(error => { });

   }

   const afterPayment = async (datas) => {

      var dataDetail2 = {
         userid: sessionUserId,
         firstname: ReactSession.get("userFirstname"),
         lastname: ReactSession.get("userLastname"),
         address: ReactSession.get("userAddress"),
         apportment: ReactSession.get("userApartment"),
         city: ReactSession.get("userCity"),
         district: ReactSession.get("userDistrict"),
         state: ReactSession.get("userState"),
         pincode: ReactSession.get("userZipcode"),
         email: ReactSession.get("email"),
         phonenumber: ReactSession.get("phone")
      };

      const API_URL = `${PaymentGatewayURL}/`;

      const dataDetail1 = {
         userid: sessionUserId,
         paymentdetail: datas
      };

      const url1 = `${API_URL}paymentdetail`;
      const captureResponse1 = await axios.post(url1, dataDetail1);

      if (captureResponse1.data.lastid) {

         var dataDetail2;

         dataDetail2 = {
            userid: sessionUserId,
            firstname: ReactSession.get("userFirstname"),
            lastname: ReactSession.get("userLastname"),
            address: ReactSession.get("userAddress"),
            apportment: ReactSession.get("userApartment"),
            city: ReactSession.get("userCity"),
            district: ReactSession.get("userDistrict"),
            state: ReactSession.get("userState"),
            pincode: ReactSession.get("userZipcode"),
            email: ReactSession.get("email"),
            phonenumber: ReactSession.get("phone")
         };


         const url2 = `${API_URL}shippingaddress`;

         const captureResponse2 = await axios.post(url2, dataDetail2);

         if (captureResponse2.data.lastid) {

            var totalAmount2 = 0;
            var getProductDetails2 = ReactSession.get("getProductDetails");

            for (var i = 0; i < getProductDetails2.length; i++) {
               totalAmount2 = totalAmount2 + getProductDetails2[i].producttotal;
            }

            var shippingCost2 = ReactSession.get("shippingCost");

            const dataDetail3 = {
               userid: sessionUserId,
               shippingdifferentaddress: addressShow,
               shippingaddressid: captureResponse2.data.lastid,
               onlinepayment: true,
               paymentdetailsid: captureResponse1.data.lastid,
               productTotalAmount: totalAmount2,
               shippingCost: shippingCost2,
               totalAmount: (parseInt(totalAmount2) + parseInt(shippingCost2)).toFixed(2),
               ordernotes: orderNotes,
               shippingstageid: 1
            };

            const url3 = `${API_URL}order`;
            const captureResponse3 = await axios.post(url3, dataDetail3);
            if (captureResponse3.data.lastid) {

               const dataDetail4 = {
                  productDatas: ReactSession.get("getProductDetails"),
                  orderid: captureResponse3.data.lastid,
                  email: ReactSession.get("email"),
               };

               const url4 = `${API_URL}shippingproduct`;
               const captureResponse4 = await axios.post(url4, dataDetail4);
               if (captureResponse4.data.status == 200) {
                  window.location.href = "/paymentLanding";
               }

            }

         }

      }
   }


   useEffect(() => {
      var orderTotalIs = 0;
      var orderProductWeight = 0;
      for (var i = 0; i < getProductDetails.length; i++) {
         orderTotalIs = orderTotalIs + getProductDetails[i].producttotal;
         if (getProductDetails[i].productid.productWeight)
            orderProductWeight = orderProductWeight + getProductDetails[i].productid.productWeight;
      }

      setOrderTotal(orderTotalIs);
      setGetProductTotalWeights(orderProductWeight);

   }, [getProductDetails])

   useEffect(() => {

      console.log("shippingDetails A");
      console.log(getProductTotalWeights);
      var tempVar = shippingDetails;

      ReactSession.set("shippingCost", 0);
      if (tempVar && getProductTotalWeights != 0) {
         for (var i = 0; i < shippingDetails.length; i++) {

            if (shippingDetails[i].productWeight >= getProductTotalWeights) {
               setShippingCost(shippingDetails[i].cost);
               ReactSession.set("shippingCost", shippingDetails[i].cost);
            }

         }
      }

   }, [shippingDetails, getProductTotalWeights])

   return (
      <>
         <section className="">
            <div className="container">
               <form action="#">
                  <div className="row">
                     <div className="col-lg-7" style={{}}>
                        <div className="">
                           {/* <h3>Billing Details</h3> */}
                           <BillDetailsForm
                              districtData={userDistrict.charAt(0).toUpperCase() + userDistrict.slice(1).toLowerCase()}
                              stateData={userState.charAt(0).toUpperCase() + userState.slice(1).toLowerCase()}
                              cityData={userCity.charAt(0).toUpperCase() + userCity.slice(1).toLowerCase()} />
                        </div>
                     </div>
                     <div className="col-lg-5">
                        <div className="your-order mb-40">
                           <h3>Your order</h3>
                           <div className="your-order-table table-responsive">
                              <table>
                                 <thead>
                                    <tr>
                                       <th className="product-name">Product</th>
                                       <th className="product-total">Total</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {getProductDetails.map((options, index) => (
                                       <tr className="cart_item" key={index}>
                                          <td className="product-name">
                                             {options.productid.product.substring(0, 27) + "..."} <strong className="product-quantity"> × {options.productqty}</strong>
                                          </td>
                                          <td className="product-total">
                                             <span className="amount">Rs {Math.round(options.producttotal).toFixed(2)}</span>
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                                 <tfoot>
                                    <tr className="order-total">
                                       <th>Order Total</th>
                                       <td><strong><span className="amount">Rs {Math.round(orderTotal).toFixed(2)}</span></strong></td>
                                    </tr>
                                    <tr className="order-total">
                                       <th>Shipping Cost</th>
                                       <td><strong><span className="amount">Rs {Math.round(shippingCost).toFixed(2)}</span></strong></td>
                                    </tr>
                                    <tr className="order-total">
                                       <th>Total Amount</th>
                                       <td><strong><span className="amount">Rs {Math.round(parseInt(orderTotal) + parseInt(shippingCost)).toFixed(2)}</span></strong></td>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                           <div className="payment-method">
                              <div className="order-button-payment mt-20">
                                 {loader ? (
                                    <img src="../../../../../../../image/loader.gif" alt="Loading" />
                                 ) : (
                                    <button type="button" className="primary_btn theme-btn" onClick={paymentSubmit}>Place order</button>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </section>

      </>
   );
};

export default CheckoutArea;