import React, { useState } from 'react';
import SingleGalleryItem from './GalleryDesign';
import portfolioData from './FacilityGallery';
// import SimpleReactLightbox from 'simple-react-lightbox'

const arr = portfolioData;
const uniqueItem = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PortfolioThreeColGallery = () => {
    const [galleryItems, setGalleryItems] = useState(portfolioData);
    const [filterGalleryItems, setFilterGalleryItems] = useState(uniqueItem);
    const [active, setActive] = useState('all')
    // console.log(galleryItems)

    // filter item
    const filterItems = (category) => {
        setActive(category)
        if (category === 'all') {
            return setFilterGalleryItems(uniqueItem)
        }
        const remainingItem = galleryItems.filter((item) => {
            return item.category === category
        })
        setFilterGalleryItems(remainingItem)

    }


    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>Hospital Facilities</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Hospital Facilities</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
           <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h4>HOSPITAL FACILITIES</h4>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>It has 130 beds, of which 110 rooms are single rooms with bath attached, 17 deluxe rooms, and 5 beds in common wards and 8 beds as free ward.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>It has Two major A/C theater with all the modern equipment, two minor operation theaters and one Labor room.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>It has one intensive care unit, which is in service all 24 hours.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>It has it's own 24hrs working Pharmacy</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Operatory, X-ray and ECG facilities are also available.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>It houses altogether 85 staff, including medical , paramedics and helpers, who work all 24 hours in shifts.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Ambulance service is also available</li>
                            <li className="textAlign"><i class="fa fa-check"></i>The hospital is approved by Govt. of Tamil Nadu for Tubectomy, Vasectomy and IUD Insertion</li>
                            <li className="textAlign"><i class="fa fa-check"></i>We have a separate wing for those senior citizens who need daily medical care</li>
                            <li className="textAlign"><i class="fa fa-check"></i>We also take care of the elderly who are dependent on having someone to attend on them regularly. Here, apart from medical care we also provide food, physiotherapy and nursing care for 24 hours.</li>
                        </ul>
                    </div>
                </div>
           </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>

                    <div id="portfolio-grid" className="row row-portfolio">

                        {
                            filterGalleryItems.map((item, index) => {
                                return <SingleGalleryItem key={item.id} galleryItem={item} index={index}
                                    filterGalleryItems={filterGalleryItems} column="4" />
                            })
                        }

                    </div>

                </div>
            </section>
        </>
    );
};

export default PortfolioThreeColGallery;