import React, { useState } from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../components/Shared/Footer';
//import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import Footer from '../../LayoutPages/Footer/Footer';
import HomeHeader from '../../LayoutPages/Header/ShopHeader';
import ShoppingCartArea from './ShoppongCartArea/ShoppingCartArea';

import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';

import HomeHeader2 from '../../LayoutPages/Header/ShopCategoryHeader';
import Footer2 from '../../LayoutPages/Footer/PharmacyFooter';

import { UserContext } from '../../LayoutPages/Header/UserContext';

const ShoppingCart = () => {

    const sessionToken = ReactSession.get("token");

    if (!sessionToken) {
        Swal.fire('Oops..', 'Please Login Your Account', 'error')
            .then((result) => {
                console.log(result);
                if (result) {
                    window.location.href = "/login";
                } else {

                }
            });
    }

    const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
    const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

    return (
        <>
            <UserContext.Provider value={{ getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger }}>
                <HomeHeader2 />
                {/*  <CommonPageHeader title="Shopping Cart" subtitle="Shopping Cart" /> */}
                <ShoppingCartArea />
            </UserContext.Provider>
            <Footer2 />
        </>
    );
};

export default ShoppingCart;