import React, { useState, useEffect, useContext } from 'react';

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// //import Carousel2 from 'react-multi-carousel';

// //import ShoppingBagIcon from '@material-ui/icons/ShoppingBag';
// import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

// import Carousel2 from 'react-grid-carousel';

import axios from 'axios';
import { masterUrl, ProductURL, userDetailsURL } from "../config";

import LoadPreviewImage from '../config/LoadPreviewImage';

import Swal from 'sweetalert2';

// import { UserContext } from '../../../LayoutPages/Header/UserContext';

// import { ReactSession } from 'react-client-session';

const FrontCategoryView = () => {

    useEffect(() => {
        getCategoriesForFetch();
    }, []);

    const [getProductCategories, setGetProductCategories] = useState([]);


    const getCategoriesForFetch = () => {

        axios
            .get(`${masterUrl}/category/getCategories`)
            .then(data => {

                if (data.data.status == 200) {
                    console.log('category', data.data.data);
                    setGetProductCategories(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    return (
        <>
            <main className="main-wrapper">

                <div className="axil-breadcrumb-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="inner">
                                    <ul className="axil-breadcrumb">
                                        <li className="axil-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="separator"></li>
                                        <li className="axil-breadcrumb-item active" aria-current="page">Category List Page</li>
                                    </ul>
                                    <h1 className="title">Explore Categories</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div class={mobileMenuNavigationHide}></div> */}
                <div className="axil-shop-area axil-section-gap bg-color-white" style={{ background: "#f0f0f5" }}>
                    {/** className="container" */}
                    <div className='col' style={{ marginLeft: "30px" }}>
                        <div className="row">
                            <div className="row row--15">
                                {getProductCategories.map((options, index) => {
                                    return <>
                                        <div className="col-xl-3 col-sm-6" style={{marginRight:'-70px'}}>
                                            <div className="axil-product product-style-one mb--30" style={{ background: "white", width: '240px' }}>
                                                <div className="thumbnail">
                                                    <a href={`/onlinePharmacy/0/${options._id}`} tabindex={index}>
                                                        <LoadPreviewImage imagePath={'category'} image={options.categoryImg} style={{ height: '160px' }} />
                                                    </a>
                                                    {/* <div className="label-block label-right">

                                                            {
                                                                options.discount.discount != 0
                                                                    ?
                                                                    <div className="product-badget">{options.discount.discount}% OFF</div>
                                                                    :
                                                                    null
                                                            }

                                                        </div> */}
                                                    {/* <div className="product-hover-action">
                                                            <ul className="cart-action">
                                                                <li className="wishlist"><a href="#" onClick={() => wishlistDetails(options._id)}>
                                                                    {wishlistIsHere == 1 ? <i className="far fa-heart" style={{ fontWeight: 600, color: 'rgb(225, 36, 84)' }}></i> : <i className="far fa-heart"></i>}

                                                                </a></li>
                                                                <li className="select-option">
                                                                    <a href="#" onClick={() => addToCartDetails(options._id, options.originalrate)} style={{ fontSize: "12px", padding: "0", width: "80px", textAlign: "center" }}>

                                                                        {CartIsHere == 1 ? 'Remove' : 'Add to Cart'}

                                                                    </a>
                                                                </li>
                                                                <li className="quickview"><a href={`/onlinePharmacyView/${options._id}`}><i className="far fa-eye"></i></a></li>
                                                            </ul>
                                                        </div> */}
                                                </div>
                                                <div className="product-content">
                                                    <div className="inner">

                                                        <center>
                                                            <h5 className="title">
                                                                <a href={`/onlinePharmacy/0/${options._id}`} style={{ fontSize: "14px" }}>
                                                                    {
                                                                        options.category.length > 25
                                                                            ?
                                                                            options.category.charAt(0).toUpperCase()+ options.category.slice(1).toLowerCase().substring(0, 17) + "..."
                                                                            :
                                                                            options.category.charAt(0).toUpperCase()+ options.category.slice(1).toLowerCase()
                                                                    }

                                                                </a>
                                                            </h5>
                                                        </center>

                                                        <div className="product-price-variant">
                                                            <div className='row' style={{ padding: '10px' }}></div>
                                                            {/*<center> */}
                                                            {/* <span className="price current-price" style={{ fontSize: "12px" }}>₹ {Math.round(options.originalrate).toFixed(2)}</span>
                                                    <br /><span class="price old-price" style={{ fontSize: "12px", color: "red" }}>MRP: ₹ {Math.round(options.mrp).toFixed(2)}</span>

                                                    {
                                                        options.discount.discount != 0
                                                            ?
                                                            <>
                                                                <span style={{ fontSize: "12px", color: "#1aab2a" }}>{options.discount.discount}% Off</span>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    <br />


                                                    {
                                                        CartIsHere == 1
                                                            ?
                                                            <a href="#" onClick={() => addToCartDetails(options._id, options.originalrate)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "14px", color: "white", backgroundColor: "green", position: "relative", borderRadius: "4px" }}>Remove</a>
                                                            :
                                                            <a href="#" onClick={() => addToCartDetails(options._id, options.originalrate)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "14px", color: "white", backgroundColor: "#ff497c", position: "relative", borderRadius: "4px" }}>Add</a>
                                                    } */}



                                                            {/* </center> */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col' style={{ margin: '20px' }}></div>
                                            </div>
                                        </div>
                                    </>

                                }
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );

};

export default FrontCategoryView;