import React from 'react';
//import Footer from '../../../components/Shared/Footer';
import HomeHeader from '../../LayoutPages/Header/ShopHeader';
import ShopDetailsBanner from './ShopDetailsBanner/ShopDetailsBanner';

import Footer from '../../LayoutPages/Footer/Footer';

const ShopDetails = () => {
    return (
        <>
            <HomeHeader/>
            <ShopDetailsBanner/>
            <Footer/>
        </>
    );
};

export default ShopDetails;