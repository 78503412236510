import React, { useState, useEffect } from 'react';
//import Footer from '../../../../components/Shared/Footer';
import HomeHeader from '../../../LayoutPages/Header/ShopHeader';
import { Link, useParams } from 'react-router-dom';

import SingleSecondShop from '../../../../components/SingleSecondShop/SingleSecondShop';
import Footer from '../../../LayoutPages/Footer/Footer';

import axios from 'axios';

import { userURL, baseurl } from "../../../config";

const SearchShop = () => {

    const [getProductDetails, setGetProductDetails] = useState([]);

    let { product } = useParams();

    useEffect(() => {

        GetFilterProductTypeById();

    }, [])

    const GetFilterProductTypeById = () => {

        var productDetail = {
            searchValue: product
        };

        axios
            .post(`${userURL}/product/getProductBySearch`, productDetail)
            .then(data => {
                setGetProductDetails(data.data.data);
            })
            .catch(error => {

            });

    };

    return (
        <>
            <HomeHeader />
            <section className="shop-banner-area pb-120">
                <div className="container">

                    <div className="row mt-20">
                        <div className="col-xl-4 col-lg-5 col-md-6">
                            <div className="product-showing mb-40">
                                <p>Searching: "{product}"</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-sm-10">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    {getProductDetails.map((options, index) => (

                                        <div className="row" key={index}>

                                            <SingleSecondShop image={baseurl + '/uploadFiles/product/' + options.image1} productId={options._id} mrp={options.mrp} originalrate={options.originalrate} title={options.product} producttype={options.producttype} />

                                        </div>

                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SearchShop;