import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
//import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import BlogDetailsArea3 from '../RefundProcess/refundProcessDetailsArea/RefundProcessDetailsArea';
import Footer from '../../LayoutPages/Footer/Footer';
import HomeHeader from '../../LayoutPages/Header/Header';

const BlogDetails = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="REFUND POLICY" subtitle="REFUND POLICY" />
         <BlogDetailsArea3/>
         <Footer/>
      </>
   );
};

export default BlogDetails;