import React, { forwardRef, useState, useEffect } from 'react';
import { userURL, masterUrl, baseurl } from '../../config';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';
const ShoppingCartArea = () => {

   var sessionUserId = ReactSession.get("userid");

   var sessionEmail = ReactSession.get("email");
   var sessionPhone = ReactSession.get("phone");
   var sessionUsername = ReactSession.get("username");

   useEffect(() => {
      refreshDatas();
      loadGetDoctorDatas();
   }, []);

   const [data2, setData] = useState([]);
   const [doctor, setDoctor] = useState([]);

   const refreshDatas = () => {
      axios
         .get(`${userURL}/appointment/getAppointment`)
         .then(data => {
            setData(data.data.data);
         })
         .catch(error => { });
   };

   const loadGetDoctorDatas = () => {
      axios
         .get(`${masterUrl}/doctor/getDoctor`)
         .then(data => {
            if (data.data.status == 200) {
               setDoctor(data.data.data);
            }
         })
         .catch(error => { });
   };

   const bookingappointment = (phoneno) => {
      Swal.fire({
         title: 'Phone number is: '+phoneno,
         showCancelButton: false,
         showConfirmButton: true,
         confirmButtonText: 'Close',
      }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
            
           

         }
      })

   };
   function dateFormat(inputDate, format) {
      //parse the input date
      const date = new Date(inputDate);

      //extract the parts of the date
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      //replace the month
      format = format.replace('MM', month.toString().padStart(2, '0'));

      //replace the year
      if (format.indexOf('yyyy') > -1) {
         format = format.replace('yyyy', year.toString());
      } else if (format.indexOf('yy') > -1) {
         format = format.replace('yy', year.toString().substr(2, 2));
      }

      //replace the day
      format = format.replace('dd', day.toString().padStart(2, '0'));

      return format;
   }
   function timeConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
         // If time format correct
         time = time.slice(1); // Remove full string match value
         time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
         time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
   }
   const loginpage = () => {
      window.location.assign('/login');
   }
   return (
      <>
         <section className="cart-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <form action="#">
                        <div className="table-content table-responsive">
                           {/*<table className="table">
                              <thead>
                                 <tr>
                                    <th style={{fontSize: "14px"}} className="appointment-specialist">Specialist</th>
                                    <th style={{fontSize: "14px"}} className="appointment-doctor-name">Doctor name</th>
                                    <th style={{fontSize: "14px"}} className="appointment-date">Appointment Date</th>
                                    <th style={{fontSize: "14px"}} className="appointment-starting-time">Starting time</th>
                                    <th style={{fontSize: "14px"}} className="appointment-ending-time">Ending time</th>
                                    <th style={{fontSize: "14px"}} className="appointment-status">Status</th>
                                 </tr>
                              </thead>
                              <tbody className='border-0'>
                                 {
                                    data2.length != 0
                                    ?
                                    data2.map((options, index) => {
                                       return <><tr>
                                          <td> {options.specialist.specialist} </td>
                                          <td> {options.doctor.doctorname} </td>
                                          <td> {dateFormat(options.date, 'dd-MM-yyyy')} </td>
                                          <td> {timeConvert(options.startingtime)} </td>
                                          <td> {timeConvert(options.endingtime)} </td>
                                          {options.bookingflag == false ?
                                             <td>{sessionUserId ? <a style={{ cursor: "pointer", color: "green" }} onClick={() => bookingappointment(options._id, sessionUserId, timeConvert(options.startingtime), timeConvert(options.endingtime),dateFormat(options.date, 'dd-MM-yyyy'))}>Booking</a> : <a style={{ cursor: "pointer", color: "green" }} onClick={() => loginpage()}>Booking</a>}</td>
                                             :
                                             <td><span style={{ color: "red" }}>Booked</span></td>
                                          }

                                       </tr></>
                                    })
                                    :
                                    <tr>
                                       <td colspan="6">There is no appointment today</td>
                                    </tr>
                                 }
                              </tbody>
                           </table>  */}

                           <table className="table">
                              <thead>
                                 <tr>
                                    <th style={{ fontSize: "14px" }} className="appointment-specialist">S.no</th>
                                    <th style={{ fontSize: "14px" }} className="appointment-doctor-name"></th>
                                    <th style={{ fontSize: "14px" }} className="appointment-date">Doctor</th>
                                    <th style={{ fontSize: "14px" }} className="appointment-starting-time">Specialist</th>
                                    <th style={{ fontSize: "14px" }} className="appointment-ending-time">Appointment</th>
                                 </tr>
                              </thead>
                              <tbody className='border-0'>
                                 {
                                    doctor.length != 0
                                       ?
                                       doctor.map((options, index) => {
                                          return <><tr>
                                             <td> {(index + 1)} </td>
                                             <td> <img style={{width: "100px"}} src={`${baseurl}/uploadFiles/doctor/${options.profile}`} /> </td>
                                             <td> {options.doctorname},{options.qualification}  </td>
                                             <td> {options.specialist.specialist} </td>
                                             <td>
                                                <a style={{ cursor: "pointer", color: "green" }}
                                                   onClick={() => bookingappointment(options.phoneno)}>Book Appointment</a>
                                             </td>

                                          </tr></>
                                       })
                                       :
                                       <tr>
                                          <td colspan="6">There is no appointment today</td>
                                       </tr>
                                 }
                              </tbody>
                           </table>

                        </div>


                     </form>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ShoppingCartArea;