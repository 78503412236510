import React, { useState, useEffect, useContext } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import Carousel2 from 'react-multi-carousel';

//import ShoppingBagIcon from '@material-ui/icons/ShoppingBag';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import Carousel2 from 'react-grid-carousel';

import axios from 'axios';
import { masterUrl, ProductURL, userDetailsURL } from "../../../config";

import LoadPreviewImage from "../../../config/LoadPreviewImage";


import Swal from 'sweetalert2';

import { UserContext } from '../../../LayoutPages/Header/UserContext';

import { ReactSession } from 'react-client-session';

const FrontPage = () => {

    const { getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger } = useContext(UserContext);

    const [getProductCategories, setGetProductCategories] = useState([]);
    const [getProductBrand, setGetProductBrand] = useState([]);
    const [getLiveProducts, setGetLiveProducts] = useState([]);
    const [getSlider, setGetSlider] = useState([]);

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");

    const [totalUserWishlist, setTotalUserWishlist] = useState([]);
    const [totalUserCart, setTotalUserCart] = useState([]);

    const productViewStyle = { width: '260px', height: '220px' };
    const brandViewStyle = { width: '140px', height: '101px' };

    useEffect(() => {
        getCategoriesForFetch();
        getBrandsForFetch();
        getProductsForFetch();
        getPharmacySlider();
        totalWishlist();
        totalCart();
    }, [])

    const getPharmacySlider = () => {

        axios
            .get(`${masterUrl}/slider/getSlider`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSlider(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const getCategoriesForFetch = () => {

        axios
            .get(`${masterUrl}/category/getCategories`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetProductCategories(data.data.data);
                    console.log('category data all', data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const getBrandsForFetch = () => {

        axios
            .get(`${masterUrl}/brand/getBrand`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetProductBrand(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const getProductsForFetch = () => {

        axios
            .get(`${ProductURL}/productcrud/getProduct`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetLiveProducts(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const wishlistActions = (getIsWishlistHere, productId) => {
        if (getIsWishlistHere == 1) {

            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
                .then(data => {
                    setGetWishlistTrigger(getWishlistTrigger - 1);
                    totalWishlist();
                })
                .catch(error => {

                });

        }
        else if (getIsWishlistHere == 0) {
            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
                .then(data => {
                    setGetWishlistTrigger(getWishlistTrigger + 1);
                    totalWishlist();
                })
                .catch(error => {

                });

        }
    }

    const wishlistDetails = (productId) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/checkProductWishlist`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        wishlistActions(data.data.data, productId);
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    console.log(result);
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const addToCartDetails = (productId, rate, qtyLimit) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/checkProductCart`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        AddToCartActions(data.data.data, productId, rate,qtyLimit);
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    console.log(result);
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const AddToCartActions = (getIsAddToCartHere, productId, rate,qtyLimit) => {

        if (getIsAddToCartHere == 1) {


            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            axios
                .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
                .then(data => {
                    setGetAddToCartTrigger(getAddToCartTrigger - 1);
                    totalCart();
                })
                .catch(error => {

                });

        }
        else if (getIsAddToCartHere == 0) {

            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId,
                productid: productId,
                producttotal: rate,
                productqty: qtyLimit
            };

            axios
                .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetail, authToken)
                .then(data => {
                    setGetAddToCartTrigger(getAddToCartTrigger + 1);
                    totalCart();
                })
                .catch(error => {

                });

        }
    }

    /** Cart and wishlist */

    const totalWishlist = () => {

        if (sessionUserId) {

            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/productWishlist`, productDetail)
                .then(data => {
                    setTotalUserWishlist(data.data.data);
                })
                .catch(error => {

                });

        }
        else {
            setTotalUserWishlist([]);
        }

    }

    const totalCart = () => {


        if (sessionUserId) {

            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/productCart`, productDetail)
                .then(data => {
                    setTotalUserCart(data.data.data);
                })
                .catch(error => {

                });

        }
        else {
            setTotalUserCart([]);
        }

    }

    const checkInWishlist = (id) => {

        if (totalUserWishlist.length != 0) {
            for (var i = 0; i < totalUserWishlist.length; i++) {
                if (totalUserWishlist[i].productid == id) {
                    return 1;
                    getProductsForFetch();
                    break;
                }
            }
            return 0;
        }
        else {
            return 0;
        }

    }

    const checkInCart = (id) => {
        if (totalUserCart.length != 0) {

            for (var i = 0; i < totalUserCart.length; i++) {
                if (totalUserCart[i].productid == id) {
                    return 1;
                    getProductsForFetch();
                    break;
                }
            }
            return 0;

        }
        else {
            return 0;
        }
    }


    return (
        <>
            <main class="main-wrapper">
                <div class="axil-main-slider-area main-slider-style-2">
                    <div class="container">
                        <div class="slider-offset-left">
                            <div class="row row--20">
                                <div class="col-lg-12 sliderOption">

                                    {/* <Carousel slidesToShow={getSlider.length} infiniteLoop>

                                        {getSlider.map((options, index) => {

                                            return <div>
                                                <img src={`${baseurl}/uploadFiles/slider/${options.slider}`} />
                                            </div>

                                        })}

                                    </Carousel> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="container categoryContainer categoryContainer2">
                    <div className="section-title-wrapper" style={{ marginBottom: '0px' }}>
                        <span className="title-highlighter highlighter-primary" style={{ color: "rgb(225, 36, 84)" }}> <ShoppingBasketIcon />&nbsp; Categories</span>
                        <h2 className="title">Browse by Category</h2>
                    </div>
                    <Carousel2 cols={5} rows={1} gap={10} loop>

                        {getProductCategories.map((options, index) => {
                            return <Carousel2.Item>
                                <div className="categrie-product sal-animate">
                                    <a href={`/onlinePharmacy/0/${options._id}`} tabindex={index}>
                                        <LoadPreviewImage imagePath={'category'} image={options.categoryImg} style={brandViewStyle} />
                                        <h6 class="cat-title" style={{ color: 'rgb(225, 36, 84)' }}>{
                                            options.category.length > 12
                                                ?
                                                options.category.charAt(0).toUpperCase()+ options.category.slice(1).toLowerCase().substring(0, 17) + "..."
                                                :
                                                options.category.charAt(0).toUpperCase()+ options.category.slice(1).toLowerCase()
                                        }
                                        </h6>
                                    </a>
                                </div>
                            </Carousel2.Item>
                        })}

                    </Carousel2>
                </div>

                <div className="container categoryContainer" style={{ padding: '45px 0' }}>
                    <div className="section-title-wrapper">
                        <span className="title-highlighter highlighter-primary" style={{ color: "rgb(225, 36, 84)" }}> <ShoppingBasketIcon />&nbsp; New Products</span>
                        <h2 className="title">Browse by New Products</h2>
                    </div>
                    <Carousel2 cols={4} rows={1} gap={10} loop>

                        {getLiveProducts.reverse().slice(0, 8).map((options, index) => {

                            var wishlistIsHere = checkInWishlist(options._id);
                            var CartIsHere = checkInCart(options._id);

                            return <Carousel2.Item>
                                <div className="slick-single-layout" style={{
                                    height: '420px',
                                    width: '260px',
                                    borderStyle: 'groove', borderColor: 'rgb(225, 36, 84)', borderWidth: '1.5px'
                                }}>
                                    <div className="axil-product product-style-three">
                                        <div className="thumbnail" style={{ height: '200px' }}>
                                            <div className="row" style={{ padding: "25px" }}>
                                                <a href={`/onlinePharmacyView/${options._id}`}>
                                                    <LoadPreviewImage imagePath={'product'} image={options.image1} style={productViewStyle} />
                                                </a>
                                            </div>
                                            <div className="row" style={{ padding: "-15px" }}></div>
                                            <div className="product-hover-action">
                                                <ul className="cart-action">
                                                    <li className="wishlist"><a href="#" onClick={() => wishlistDetails(options._id)}>
                                                        {wishlistIsHere == 1 ? <i className="far fa-heart" style={{ fontWeight: 600, color: 'rgb(225, 36, 84)' }}></i> : <i className="far fa-heart"></i>}
                                                    </a></li>
                                                    <li className="select-option"><a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)}>
                                                        {CartIsHere == 1 ? 'Remove' : 'Add to Cart'}
                                                    </a></li>
                                                    <li className="quickview"><a href={`/onlinePharmacyView/${options._id}`}><i
                                                        className="far fa-eye"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-content" style={{ marginTop: '80px',textAlign:'center' }}>
                                            <center><div className="inner" style={{ width: "280px" }}>

                                                <center><h5 className="title">
                                                    <a href={`/onlinePharmacyView/${options._id}`}>
                                                        {
                                                            options.product.length > 25
                                                                ?
                                                                options.product.charAt(0).toUpperCase()+ options.product.slice(1).toLowerCase().substring(0, 25) + "..."
                                                                :
                                                                options.product.charAt(0).toUpperCase()+ options.product.slice(1).toLowerCase()
                                                        }

                                                    </a>
                                                </h5>
                                                </center>
                                                <div className="product-price-variant">
                                                    <center>
                                                        <span className="price current-price">₹ {parseFloat(options.rate).toFixed(2)}</span>
                                                        <br /><span class="price old-price" style={{ fontSize: "15px", color: "red" }}>MRP: ₹ {parseFloat(options.mrp).toFixed(2)}</span>
                                                        {
                                                            options.discountrate != 0.00 && options.discountrate > 0
                                                                ?

                                                                <>
                                                                    <span style={{ fontSize: "15px", color: "#1aab2a" }}>₹ {parseFloat(options.discountrate).toFixed(2)} Off</span>
                                                                </>

                                                                :
                                                                null
                                                        }

                                                        <br />


                                                        {
                                                            CartIsHere == 1
                                                                ?
                                                                <a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "18px", color: "white", backgroundColor: "green", position: "relative", borderRadius: "4px" }}>Remove</a>
                                                                :
                                                                <a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "18px", color: "white", backgroundColor: "#ff497c", position: "relative", borderRadius: "4px" }}>Add</a>
                                                        }
                                                    
                                                    </center>
                                                </div>
                                            </div>
                                            </center>
                                        </div>
                                    </div>

                                </div>
                            </Carousel2.Item>

                        })}

                    </Carousel2>
                </div>

                <div class="container containerMessage">
                    <div class="service-area">
                        <div class="container">
                            <div class="row" style={{ padding: '10px' }}>
                                <div class="col" style={{ margin: '12px' }}>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="service-box">
                                                <div class="icon">
                                                    <img src="assets/images/icons/service1.png" alt="Service" />
                                                </div>
                                                <h6 class="title">Fast &amp; Secure Delivery</h6>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="service-box">
                                                <div class="icon">
                                                    <img src="assets/images/icons/service2.png" alt="Service" />
                                                </div>
                                                <h6 class="title">100% Guarantee On Product</h6>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="service-box">
                                                <div class="icon">
                                                    <img src="assets/images/icons/service5.png" alt="Service" />
                                                </div>
                                                <h6 class="title">Next Level Pro Quality</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col" style={{ margin: "30px" }}></div>

                <div className="container categoryContainer categoryContainer2">
                    <div className="section-title-wrapper" style={{ marginBottom: '0px' }}>
                        <span className="title-highlighter highlighter-primary" style={{ color: "rgb(225, 36, 84)" }}> <ShoppingBasketIcon />&nbsp; Brands</span>
                        <h2 className="title">Browse by Brands</h2>
                    </div>
                    <Carousel2 cols={5} rows={1} gap={10} loop>

                        {getProductBrand.map((options, index) => {

                            return <Carousel2.Item>
                                <div className="categrie-product sal-animate">
                                    <a href={`/onlinePharmacy/${options._id}/0`} tabindex={index}>
                                        <LoadPreviewImage imagePath={'brand'} image={options.brandImg} style={brandViewStyle} />
                                        <h6 class="cat-title" style={{ color: 'rgb(225, 36, 84)' }}>{
                                            options.brandname.length > 12
                                                ?
                                                options.brandname.charAt(0).toUpperCase()+ options.brandname.slice(1).toLowerCase().substring(0, 17) + "..."
                                                :
                                                options.brandname.charAt(0).toUpperCase()+ options.brandname.slice(1).toLowerCase()
                                        }
                                        </h6>
                                    </a>
                                </div>
                            </Carousel2.Item>

                        })}

                    </Carousel2>
                </div>

                <div className="container categoryContainer" style={{ padding: '45px 0' }}>
                    <div className="section-title-wrapper">
                        <span className="title-highlighter highlighter-primary" style={{ color: "rgb(225, 36, 84)" }}> <ShoppingBasketIcon />&nbsp; Feature Products</span>
                        <h2 className="title">Browse by Products</h2>
                    </div>
                    <Carousel2 cols={4} rows={1} gap={10} loop>

                        {getLiveProducts.slice(0, 8).map((options, index) => {
                            var wishlistIsHere = checkInWishlist(options._id);
                            var CartIsHere = checkInCart(options._id);
                            return <Carousel2.Item>
                                <div className="slick-single-layout" style={{ height: 'auto', borderStyle: 'groove', borderColor: 'rgb(225, 36, 84)', borderWidth: '1.5px' }}>
                                    <div className="axil-product product-style-three">
                                        <div className="thumbnail" style={{ height: '240px' }}>
                                            <div className="row" style={{ padding: "25px" }}>
                                                <a href={`/onlinePharmacyView/${options._id}`}>
                                                    <LoadPreviewImage imagePath={'product'} image={options.image1} style={{ width: "260px", height: "220px" }} />
                                                </a>
                                            </div>

                                            <div className="product-hover-action">
                                                <ul className="cart-action">
                                                    <li className="wishlist"><a href="#" onClick={() => wishlistDetails(options._id)}>
                                                        {wishlistIsHere == 1 ? <i className="far fa-heart" style={{ fontWeight: 600, color: 'rgb(225, 36, 84)' }}></i> : <i className="far fa-heart"></i>}
                                                    </a></li>
                                                    <li className="select-option"><a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)}>
                                                        {CartIsHere == 1 ? 'Remove' : 'Add to Cart'}
                                                    </a></li>
                                                    <li className="quickview"><a href={`/onlinePharmacyView/${options._id}`}><i
                                                        className="far fa-eye"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-content">
                                            <center> <div className="inner" style={{ width: "230px" }}>
                                                <center><h5 className="title">
                                                    <a href={`/onlinePharmacyView/${options._id}`}>
                                                        {
                                                            options.product.length > 25
                                                                ?
                                                                options.product.charAt(0).toUpperCase()+ options.product.slice(1).toLowerCase().substring(0, 25) + "..."
                                                                :
                                                                options.product.charAt(0).toUpperCase()+ options.product.slice(1).toLowerCase()
                                                        }

                                                    </a>
                                                </h5></center>
                                                <div className="product-price-variant">
                                                    <center>
                                                        <span className="price current-price">₹ {parseFloat(options.rate).toFixed(2)}</span>
                                                        <br /><span class="price old-price" style={{ fontSize: "15px", color: "red" }}>MRP: ₹ {parseFloat(options.mrp).toFixed(2)}</span>
                                                        {
                                                            options.discountrate != 0.00 && options.discountrate > 0
                                                                ?
                                                                <span style={{ fontSize: "15px", color: "#1aab2a" }}>₹ {parseFloat(options.discountrate).toFixed(2)} Off</span>
                                                                :
                                                                null
                                                        }

                                                        <br />


                                                        {
                                                            CartIsHere == 1
                                                                ?
                                                                <a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "18px", color: "white", backgroundColor: "green", position: "relative", borderRadius: "4px" }}>Remove</a>
                                                                :
                                                                <a href="#" onClick={() => addToCartDetails(options._id, options.rate, options.qtyLimit ? options.qtyLimit : 1)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "18px", color: "white", backgroundColor: "#ff497c", position: "relative", borderRadius: "4px" }}>Add</a>
                                                        }

                                                    </center>
                                                </div>
                                            </div> </center>
                                        </div>
                                    </div>
                                </div>
                            </Carousel2.Item>

                        })}

                    </Carousel2><br /><br />
                    <div class="row">
                        <div class="col-lg-12 text-center mt--20 mt_sm--0">
                            <a href="/onlinePharmacy/0/0" class="axil-btn btn-bg-lighter btn-load-more">View All Products</a>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );

};

export default FrontPage;