import React, { useState } from 'react';
import SingleGalleryItem from './GalleryDesign';
import portfolioData from './FacilityGallery';
// import SimpleReactLightbox from 'simple-react-lightbox'

const arr = portfolioData;
const uniqueItem = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PortfolioThreeColGallery = () => {
    const [galleryItems, setGalleryItems] = useState(portfolioData);
    const [filterGalleryItems, setFilterGalleryItems] = useState(uniqueItem);
    const [active, setActive] = useState('all')
    // console.log(galleryItems)

    // filter item
    const filterItems = (category) => {
        setActive(category)
        if (category === 'all') {
            return setFilterGalleryItems(uniqueItem)
        }
        const remainingItem = galleryItems.filter((item) => {
            return item.category === category
        })
        setFilterGalleryItems(remainingItem)

    }


    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>LYSANDER LAB</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Lab facilities</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
           <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h4>Lab Facilities</h4>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Fully automated bio chemistry analyser for doing ALL Bio chemistry investigation and enzymatic test.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>24 hours Lab</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Most reliable and high sensitive machine</li>
                            <li className="textAlign"><i class="fa fa-check"></i>"Minividas" is a German machine and all hormones test can be run including vitamins.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>We do external quality control with CMC vellore and our thyroid and other hormones results are equal to their standard.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>We also have Haematology machine for doing CBC (complete Blood count)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>18 parameters comes under CBC</li>
                        </ul>
                    </div>
                </div>
           </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>

                    <div id="portfolio-grid" className="row row-portfolio">

                        {
                            filterGalleryItems.map((item, index) => {
                                return <SingleGalleryItem key={item.id} galleryItem={item} index={index}
                                    filterGalleryItems={filterGalleryItems} column="4" />
                            })
                        }

                    </div>

                </div>
            </section>
        </>
    );
};

export default PortfolioThreeColGallery;