import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

import { ReactSession } from 'react-client-session';

const Sidebar = ({ show, handleClose }) => {
   const Home = <NavLink to="#">Pages</NavLink>

   const sessionUserId = ReactSession.get("userid");

   const setLogout = () => {
      ReactSession.set("userid", '');
      ReactSession.set("username", '');
      ReactSession.set("email", '');
      ReactSession.set("phone", '');
      ReactSession.set("token", '');
      window.location.href = "/";
   }

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>

                  <Collapsible trigger={Home} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={true}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li style={{background: "white",}}><NavLink to={"/Pharmacy"} style={{color: "black",fontWeight: "900"}}>Pharmacy</NavLink></li>
                        <li><NavLink to="/founder">Founder</NavLink></li>
                        <li><NavLink to="/hospitalfacility">Hospital Facility</NavLink></li>
                        <li><NavLink to="/contact">Contact</NavLink></li>
                        {
                           sessionUserId
                              ?
                              <li><NavLink to="#" onClick={setLogout}>Logout</NavLink></li>
                              :
                              <li><NavLink to="/login">Sign In</NavLink></li>
                        }

                     </ul>
                  </Collapsible>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;