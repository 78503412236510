import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import RegisteArea from './RegisteArea/RegisteArea';

//import Header from '../LayoutPages/Header/Header';
import Header from '../LayoutPages/Header/Header';
import Footer from '../LayoutPages/Footer/Footer';

import axios from 'axios';
import { userURL } from "../config";

import { ReactSession } from 'react-client-session';

const Register = () => {

    const token = ReactSession.get("token");

    if (token) {
        window.location.href = "/shop/1/1/0/0/0/0/0";
    }

    let { id } = useParams();

    const [emailValue, setEmailValue] = useState();
    const [showEmailError, setShowEmailError] = useState(false);

    const setInputEmailValue = event => {

        const result = event.target.value;
        setEmailValue(result);

    };

    const checkEmailOtp = () => {

        if (emailValue) {

            var productDetail = {
                userId: id,
                emailOtp: emailValue
            };

            axios
                .post(`${userURL}/user/emailOtpValidation`, productDetail)
                .then(data => {
                    if (data.data.message == "Done") {
                    
                        window.location.href = "/phoneOtp/" + id;

                    }
                    else {

                        setShowEmailError(true);

                    }

                })
                .catch(error => {

                });

        }
        else {
            setShowEmailError(true);
        }

    };

    return (
        <>
            <Header />
          {/** <CommonPageHeader title="Email OTP" subtitle="Email OTP" /> */}  

            <section className="login-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="basic-login">
                                <h3 className="text-center">Enter Email OTP</h3>
                                <form>
                                    
                                <center><div className="field"><input required id="name" type="number" style={{width: "300px",marginBottom: 0}} value={emailValue} onChange={setInputEmailValue} className="" placeholder='Enter Email OTP' autoComplete="off" maxlength="5" /></div></center>

                                    {showEmailError ? <center><p style={{ color: "red" }}>Please Enter Valid OTP**</p></center> : null}
                                    <div className="mt-10"></div>
                                    <center><button type='button' className="primary_btn theme-btn-2" style={{width:'200px'}} onClick={checkEmailOtp}>Verify OTP</button></center>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br /><br /><br /><br />

            <Footer />
        </>
    );
};

export default Register;