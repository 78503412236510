import React from 'react';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/Header';
import FounderArea from './FounderArea/FounderArea';

const Appointment = () => {

    return (
        <>
            <HomeHeader/>
            <FounderArea/>
            <Footer/>
        </>
    );
};

export default Appointment;