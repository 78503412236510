
const portfolioData = [
    {
        id: 1,
        img: 'img/project/paramedical/img1.jpg',
        category: 'general',
    },

    {
        id: 2,
        img: 'img/project/paramedical/img2.jpg',
        category: 'general',
    },
    {
        id: 3,
        img: 'img/project/paramedical/img3.jpg',
        category: 'general',
    },
    {
        id: 4,
        img: 'img/project/paramedical/img4.jpg',
        category: 'general',
    },
    {
        id: 5,
        img: 'img/project/paramedical/img5.jpg',
        category: 'general',
    },
    {
        id: 6,
        img: 'img/project/paramedical/img6.jpg',
        category: 'general',
    },
    {
        id: 7,
        img: 'img/project/paramedical/img7.jpg',
        category: 'general',
    },
    {
        id: 8,
        img: 'img/project/paramedical/img8.jpg',
        category: 'general',
    },
    {
        id: 9,
        img: 'img/project/paramedical/img9.jpg',
        category: 'general',
    },
    {
        id: 10,
        img: 'img/project/paramedical/img10.jpg',
        category: 'general',
    },
    {
        id: 11,
        img: 'img/project/paramedical/img11.jpg',
        category: 'general',
    },
    {
        id: 12,
        img: 'img/project/paramedical/img12.jpg',
        category: 'general',
    },
    
]
export default portfolioData;