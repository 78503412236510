import React, { useState } from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
import HomeHeader from '../../LayoutPages/Header/ShopHeader';
import ShopBanner from './ShopBanner/ShopBanner';

import Footer from '../../LayoutPages/Footer/Footer';

import { UserContext } from '../../LayoutPages/Header/UserContext';

import ShopCategoryHeader from '../../LayoutPages/Header/ShopCategoryHeader';
import Pharmacy from './Pharmacy/FrontPage';
import PharmacyFooter from '../../LayoutPages/Footer/PharmacyFooter';
/*
import '../../../assets/css/vendor/bootstrap.min.css';
import '../../../assets/css/vendor/font-awesome.css';
import '../../../assets/css/vendor/flaticon/flaticon.css';
import '../../../assets/css/vendor/slick.css';
import '../../../assets/css/vendor/slick-theme.css';
import '../../../assets/css/vendor/jquery-ui.min.css';
import '../../../assets/css/vendor/sal.css';
import '../../../assets/css/vendor/magnific-popup.css';
import '../../../assets/css/vendor/base.css';
import '../../../assets/css/style.min.css';
*/
const ShopPage = () => {

    const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
    const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

    return (
        <>
            <UserContext.Provider value={{getWishlistTrigger,setGetWishlistTrigger,getAddToCartTrigger,setGetAddToCartTrigger}}>
                <ShopCategoryHeader/>
                <Pharmacy/>
            </UserContext.Provider>
            <PharmacyFooter/>
        </>
    );
};

export default ShopPage;