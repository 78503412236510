import React from 'react';

const Insurance = () => {           
    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>Insurance</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Insurance</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
           <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <h4>INSURER NAME</h4>
                        <ul class="professinals-list pt-30">
                            <li className="textAlign"><i class="fa fa-check"></i>Aditya Birla Health Insurance Co. Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Aditya Birla SunLife Insurance Company Limited (formerly known as Birla SunLife Insurance Company Limited).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>HDFC Ergo Health Insurance Limited (formerly known as Apollo Munich Health Insurance Co. Ltd).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Bajaj Allianz General Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Bharti AXA General Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Cholamandalam MS General Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Navi General Insurance Limited (Formerly known as DHFL General Insurance Limited).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Edelweiss General Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Go Digit General Insurance Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>HDFC Ergo General Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>ICICI Lombard General Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>IFFCO-TOKIO General Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>IndiaFirst Life Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Liberty General Insurance Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>LIC Of India.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Magma HDI General Insurance Company Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>ManipalCigna Health Insurance Company Limited (Formerly known as Cigna TTK Health Insurance Co. Ltd).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>MaxBupa Health Insurance Co.Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>National Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Raheja QBE General Insurance Company Limited.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Reliance General Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Reliance Nippon Life Insurance Co Ltd (Formerly known as Reliance Life Insurance Co. Ltd).</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Care Health Insurance Limited (formerly known as Religare Health Insurance Co. Ltd)</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Royal Sundaram General Insurance Company Limited</li>
                            <li className="textAlign"><i class="fa fa-check"></i>PNB MetLife India Insurance Company Limited</li>
                            <li className="textAlign"><i class="fa fa-check"></i>SBI Life Insurance Company Limited</li>
                            <li className="textAlign"><i class="fa fa-check"></i>SBI General Insurance Company Limited</li>
                            <li className="textAlign"><i class="fa fa-check"></i>Tata AIG General Insurance Company LTD</li>
                            <li className="textAlign"><i class="fa fa-check"></i>The New India Assurance Co. Ltd</li>
                            <li className="textAlign"><i class="fa fa-check"></i>The Oriental Insurance Co. Ltd.</li>
                            <li className="textAlign"><i class="fa fa-check"></i>United India Insurance Co. Ltd.</li>
                        </ul>
                    </div>
                </div>
           </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>

                    

                </div>
            </section>
        </>
    );
};

export default Insurance;