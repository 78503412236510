import React from 'react';
import { Link } from 'react-router-dom';
import HomeSingleTeam from '../HomeSingleTeam/HomeSingleTeam';

const HomeOurTeam = () => {
   return (
      <>
         <section className="team-area pt-115 pb-55">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-10">
                     <div className="section-title pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon back-icon-left" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h2>Our Team</h2>
                           <h1>A Professional & Care Provider</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <HomeSingleTeam image="image/doctors/image1.png" title="DR.RAJULAN LYSANDER, M.B.B.S.,M.S(E.N.T)" subtitle="ENT SURGEON" width="210px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image2.png" title="DR.SIGITH LYSANDER, M.B.B.S.D.ORTHO.," subtitle="ORTHOPEDIC SURGEON" width="270px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image3.png" title="DR.VIMALESH LYSANDER M.B.B.S.,M.D.(U.S.A)" subtitle="GENERAL MEDICINE (MD)" width="280px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image4.png" title="DR.AJITHA SIGITH, M.B.B.S." subtitle="GENERAL MEDICINE" width="328px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image5.png" title="DR.STEFFEN LYSANDER, M.B.B.S.M.D" subtitle="GENERAL MEDICINE (MD)" width="246px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image6.png" title="DR.RANJITHA LYSANDER, B.D.S" subtitle="DENTAL" width="250px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image7.png" title="DR.NISCHAL LYSANDER, M.B.B.S.M.E.M" subtitle="EMERGENCY MEDICINE" width="330px" height="430px"/>
                  <HomeSingleTeam image="image/doctors/image8.png" title="DR.ANEESHA LYSANDER, M.B.B.S.,D.F.M" subtitle="GENERAL MEDICINE" width="185px" height="430px"/>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeOurTeam;