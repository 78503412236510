import React from 'react';
import { Link } from 'react-router-dom';

const HomeFooter = () => {
    return (
        <>
            <footer>
                <div className="footer-top primary-bg pt-115 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="footer-contact-info mb-30">
                                    <div className="emmergency-call fix">
                                        <div className="emergency-call-icon f-left">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="emmergency-call-text f-left">
                                            <h3>(04562) 243233</h3>
                                        </div>
                                    </div>
                                    <div className="footer-logo mb-35">
                                        <Link to="/"><img src="img/project/logo/logo2.png" alt="" /></Link>
                                    </div>
                                    <div className="footer-contact-content mb-25">
                                        <p></p>
                                    </div>
                                    <div className="footer-emailing">
                                        <ul>
                                            <li><i className="far fa-envelope"></i>lysanderhospital@hotmail.com</li>
                                            <li><i className="far fa-clone"></i>lysanderhospital.com</li>
                                            <li><i className="far fa-flag"></i>140,141,142,143, West Car Street , Virudhunagar - 626 001.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                <div className="footer-widget mb-30">
                                    <div className="footer-title">
                                        <h3>Services</h3>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><a href="/">General Medicine</a></li>
                                            <li><a href="/">General Surgery</a></li>
                                            <li><a href="/">Orthopedic</a></li>
                                            <li><a href="/">ENT</a></li>
                                            <li><a href="/">Dental Care</a></li>
                                            <li><a href="/">24/7 Emergency Care</a></li>
                                            <li><a href="/">Old Age Care</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 offset-xl-1 col-lg-3 d-md-none d-lg-block">
                                <div className="footer-widget mb-30">
                                    <div className="footer-title">
                                        {/* <h3>Quick Links</h3> */}
                                        <h3>Our Policies</h3>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/terms">Terms and Conditions</Link></li>
                                            <li><Link to="/privacy">Privacy Policy</Link></li>
                                            <li><Link to="/shippingCharge">Shipping Policy</Link></li>
                                            <li><Link to="/refund">Refund Policy</Link></li>
                                            <li><Link to="/return">Return Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom pt-25 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-copyright text-center">
                                    <p className="white-color">Copyright by @ Lysander Hospital</p>
                                <div className='row'>
                                    <div className='col'><p className="white-color">Designed by</p></div>
                                    <div className='col'> <p><a href="http://cloudbsoft.com/" target="_blank">
                                        <p style={{ fontStyle: { color: 'green' } }}>cloudbsoft </p></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
        </>
    );
};

export default HomeFooter;