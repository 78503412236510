import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
//import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import BlogDetailsArea from './TermsDetailsArea/TermsDetailsArea';
import Footer from '../../LayoutPages/Footer/Footer';
import HomeHeader from '../../LayoutPages/Header/Header';

const BlogDetails = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="PRIVACY POLICY" subtitle="PRIVACY POLICY" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails;