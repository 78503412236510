import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/ShopHeader';

import { UserContext } from '../LayoutPages/Header/UserContext';

import HomeHeader2 from '../LayoutPages/Header/ShopCategoryHeader';
import Footer2 from '../LayoutPages/Footer/PharmacyFooter';

const NotFound = () => {

  const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
  const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

  return (
    <>

      <UserContext.Provider value={{ getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger }}>
        <HomeHeader2 />

        <div className='pt-120 pb-120'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-8 offset-xl-2'>
                <div className='error-404 not-found mb-20'>
                  <div className='error-404-content text-center'>
                    <h1 className='error-404-title' style={{ color: "#e12454" }}> Thank You </h1>
                    <h2 className='error-title'>Your Order Has Been Placed</h2>
                    {/* <p className='err-text'>Oops! The page you are looking for does not exist. It might have been moved or deleted. </p> */}
                    <Link to="/" className="primary_btn btn-icon ml-0"><span>+</span>BACK TO HOME</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserContext.Provider>
      <Footer2 />
    </>
  );
};

export default NotFound;