import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import axios from 'axios';
import { userURL, baseurl, ProductURL, userDetailsURL, masterUrl } from "../../config";
import Swal from 'sweetalert2';

import { useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { UserContext } from './UserContext';

import { ReactSession } from 'react-client-session';

import LoadPreviewImage from '../../config/LoadPreviewImage';

import { BsFillArrowRightCircleFill } from 'react-icons/bs';

// import '../../../../src/assets/css/shopCategoryHeader.css';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Link } from 'react-router-dom';



const ShopCategoryHeader = () => {

    const { getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger } = useContext(UserContext);

    const [profileNavigation, setProfileNavigation] = useState('');
    const [profileNavigation2, setProfileNavigation2] = useState('');
    const [mobileMenuNavigation, setmobileMenuNavigation] = useState('');
    const [mobileMenuNavigationHide, setmobileMenuNavigationHide] = useState('');
    const [searchBoxOpen, setSearchBoxOpen] = useState('');
    const [getHeaderDatas, setGetHeaderDatas] = useState([]);
    const [getSearchProduct, setGetSearchProduct] = useState([]);
    const [getCategoryData, setGetCategoryData] = useState([]);
    const [liveProductsFilter, setLiveProductsFilter] = useState([]);
    const [getSearchkeyWord, setGetSearchkeyWord] = useState('');
    const [menuBlack, setMenuBlack] = useState('none');
    const [cssChange, setCssChange] = useState('');
    const [cssChange2, setCssChange2] = useState('none');

    const [showHeader, getShowHeader] = useState(0);

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");
    const username = ReactSession.get("username");

    

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Please Login
        </Tooltip>
    );

    useEffect(() => {
        categoryDatas();
        searchProduct();
        GetWishList();
        GetShopingCart();
    }, [])

    const changeSearchBoxOpen = () => {
        if (profileNavigation) {
            setSearchBoxOpen('');
            setmobileMenuNavigationHide('');
        }
        else {
            setSearchBoxOpen('open');
            setmobileMenuNavigationHide('closeMask');
        }
    }

    const closeSearchBoxOpen = () => {
        setSearchBoxOpen('');
        setmobileMenuNavigationHide('');
    }

    const changeProfileNavigation = () => {
        if (profileNavigation) {
            setProfileNavigation('');
        }
        else {
            setProfileNavigation('open');
        }
    }

    const changeProfileNavigation2 = () => {
        if (profileNavigation2) {
            setProfileNavigation2('');
        }
        else {
            setProfileNavigation2('open');
        }
    }

    const changemobileMenuNavigation = () => {
        if (profileNavigation) {
            setmobileMenuNavigation('');
            setmobileMenuNavigationHide('');
            setMenuBlack('none');
        }
        else {
            setmobileMenuNavigation('open');
            setmobileMenuNavigationHide('closeMask');
            setMenuBlack('block');
        }
    }

    const closeMobileMenuNavigation = () => {
        setmobileMenuNavigation('');
        setmobileMenuNavigationHide('');
        setMenuBlack('none');
    }

    const categoryDatas = () => {
        axios
            .get(userURL + '/header/getAllHeaderValues')
            .then(data => {

                if (data.data.status == 200) {
                    setGetHeaderDatas(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }


            })
            .catch(error => {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })

            });
    };

    const searchProduct = () => {
        axios
            .get(`${ProductURL}/productcrud/getProduct`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSearchProduct(data.data.data);
                    console.log('da', data.data.data);
                    setLiveProductsFilter(data.data.data);
                   
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });
    }

    const productSearchByFilter = event => {
        var searchKeyWorkIs = event.target.value;
        setGetSearchkeyWord(searchKeyWorkIs);
        setLiveProductsFilter(getSearchProduct.filter(i => i.product.toLowerCase().includes(searchKeyWorkIs.toLowerCase())));
    }

    const searchProductByCategory = () => {
        axios
            .get(`${masterUrl}/category/getCategories`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetCategoryData(data.data.data);
                    console.log('da', data.data.data);
                    // setLiveProductsFilter(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });
    }

    const productSearchByCategory = event => {
        var searchKeyWorkIs = event.target.value;
        setGetSearchkeyWord(searchKeyWorkIs);
        setLiveProductsFilter(getSearchProduct.filter(i => i.product.toLowerCase().includes(searchKeyWorkIs.toLowerCase())));
    }

    const location = useLocation();

    var isWorking = '';
    if (location.pathname === '/pharmacy') {
        isWorking = '';
    }

    const GetWishList = () => {

        if (sessionToken) {

            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/showWishlist`, productDetail, authToken)
                .then(data => {
                    setGetWishlistTrigger(data.data.data.length);
                })
                .catch(error => {

                });

        }

    };

    const ChangeCSS = (index) => {
        if (cssChange) {

            setCssChange('');
            // setCssChange2('none');
            document.getElementById("newClass" + index).style.display = "none";
        }
        else {

            setCssChange(1);
            //setCssChange2('block');
            document.getElementById("newClass" + index).style.display = "block";
        }
    }

    const GetShopingCart = () => {

        if (sessionToken) {

            const authToken = {
                headers: { Authorization: `Bearer ${sessionToken}` }
            };

            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/showShopingCart`, productDetail, authToken)
                .then(data => {

                    setGetAddToCartTrigger(data.data.data.length);

                })
                .catch(error => {

                });

        }

    };

    const setLogout = () => {

        ReactSession.set("userid", '');
        ReactSession.set("username", '');
        ReactSession.set("email", '');
        ReactSession.set("phone", '');
        ReactSession.set("token", '');
        window.location.href = "/";
    }

    function myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    window.onclick = function (event) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }


    return (
        <>
            <a href="#top" className="back-to-top" id="backto-top"><i className="fal fa-arrow-up"></i></a>
            <header className="header axil-header header-style-2">
                <div className="axil-header-top">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-sm-3 col-5">
                                <div className="header-brand" style={{ width: '250px' }}>
                                    <a href="/" className="logo logo-dark">
                                        <img src="../../../../../../../img/project/logo/lysander_hospital.png" alt="" />
                                    </a>
                                    <a href="/" className="logo logo-light">
                                        <img src="../../../../../../../img/project/logo/lysander_hospital.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-10 col-sm-9 col-7">
                                <div className="header-top-dropdown dropdown-box-style">
                                    <div className="axil-search" style={{ borderWidth: '1px', borderStyle: 'groove' }}>
                                        <button type="submit" className="icon wooc-btn-search">
                                            <SearchIcon />
                                        </button>
                                        <input type="search" className="placeholder product-search-input" onClick={changeSearchBoxOpen} name="search2" id="search2" value="" maxlength="128" placeholder="What are you looking for...." autocomplete="off" />
                                    </div>
                                    <div className="header-action">
                                        <ul className="action-list">
                                            <li className="axil-search d-sm-none d-block" style={{ marginRight: 0 }}>
                                                <a href="#" className="header-search-icon" title="Search" onClick={changeSearchBoxOpen}>
                                                    <i className="flaticon-magnifying-glass"></i>
                                                </a>
                                            </li>
                                            <li className="shopping-cart">
                                                <a href="/wishList" className="cart-dropdown-btn">
                                                    <span className="cart-count">{getWishlistTrigger}</span>
                                                    <i className="flaticon-heart"></i>
                                                </a>
                                            </li>
                                            <li className="shopping-cart">
                                                <a href="/shoppingCart" className="cart-dropdown-btn">
                                                    <span className="cart-count">{getAddToCartTrigger}</span>
                                                    <i className="flaticon-shopping-cart"></i>
                                                </a>
                                            </li>
                                            { }
                                            <li className="my-account" onClick={changeProfileNavigation}>

                                                {sessionToken ?
                                                    (
                                                        <>
                                                            <a href="javascript:void(0)">
                                                                <i className="flaticon-person" style={{ fontSize: '30px' }}></i>
                                                                <p style={{ fontSize: '12px' }}>{username}</p>
                                                            </a>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                            >
                                                                <a href="/login">
                                                                    <i className="flaticon-person"></i>
                                                                </a>
                                                            </OverlayTrigger>

                                                        </>
                                                    )
                                                }

                                                <div className={'my-account-dropdown ' + profileNavigation}>

                                                    {sessionToken ?
                                                        <>
                                                            <span className="title"><i class="fal fa-user"></i> {username}</span>
                                                            <ul>
                                                                <li>
                                                                    <a href="/profileDetails">My Account</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/orderdetails">Order</a>
                                                                </li>
                                                            </ul>
                                                           <div className="login-btn">
                                                                <a href="#" onClick={setLogout} className="axil-btn" style={{ backgroundColor: 'rgb(225, 36, 84)', color: 'white' }}>Logout</a>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }

                                                </div>



                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="axil-mainmenu aside-category-menu" >
                    <div className="container">
                        <div className="header-navbar">

                            {
                                isWorking
                                    ?
                                    <div className="header-nav-department">
                                        <aside className="header-department">
                                            <button className="header-department-text department-title" onClick={changemobileMenuNavigation}>
                                                <span className="icon"><i className="fal fa-bars"></i></span>
                                                <span className="text">Categories</span>
                                            </button>
                                            <nav className={'department-nav-menu ' + mobileMenuNavigation}>
                                                <button className="sidebar-close" style={{ display: menuBlack }} onClick={closeMobileMenuNavigation}><i className="fas fa-times"></i></button>
                                                <ul className="nav-menu-list">

                                                    {getHeaderDatas.slice(0, 7).map((categoryOptions, categoryIndex) => (

                                                        categoryOptions.subcategory.length != 0 ?
                                                            <li>
                                                                <a href={`/onlinePharmacy/0/${categoryOptions.categoryId}`} className="nav-link has-megamenu" onClick={() => ChangeCSS(categoryIndex)}>
                                                                    <span className="menu-icon">
                                                                        <LoadPreviewImage imagePath={'category'} image={categoryOptions.categoryImg} alt="" />
                                                                    </span>
                                                                    <span className="menu-text">{categoryOptions.categoryName}</span>
                                                                </a>
                                                                <div className={`department-megamenu megamenu-mobile-toggle`} id={`newClass${categoryIndex}`}>
                                                                    <div className="department-megamenu-wrap">
                                                                        <div className="department-submenu-wrap">

                                                                            {
                                                                                categoryOptions.subcategory.map((subcategoryData, j) =>
                                                                                    <><div className="department-submenu"><h3 className="submenu-heading">{subcategoryData.subCategoryName}</h3>
                                                                                        {
                                                                                            subcategoryData.productType.length != 0 ?
                                                                                                <ul>

                                                                                                    {
                                                                                                        subcategoryData.productType.map((productTypeData, k) =>
                                                                                                            <li><a href={`/onlinePharmacy/0/${categoryOptions.categoryId}`}>{productTypeData.productType}</a></li>
                                                                                                        )
                                                                                                    }

                                                                                                </ul>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    </>

                                                                                )

                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            :
                                                            <li>
                                                                <a href={`/onlinePharmacy/0/${categoryOptions.categoryId}`} className="nav-link">
                                                                    <span className="menu-icon">
                                                                        <LoadPreviewImage imagePath={'category'} image={categoryOptions.categoryImg} alt="" />
                                                                    </span>
                                                                    <span className="menu-text">{categoryOptions.categoryName}</span>
                                                                </a>
                                                            </li>
                                                    ))}

                                                    {
                                                        getHeaderDatas.length > 7 ?
                                                            <li>
                                                                <a href="#" className="nav-link">
                                                                    <span className="menu-text">See more ...</span>
                                                                </a>
                                                            </li>
                                                            :
                                                            null
                                                    }
                                                </ul>
                                            </nav>
                                            <div className={mobileMenuNavigationHide}></div>
                                        </aside>
                                    </div>
                                    :
                                    <div className="header-nav-department" >
                                        <aside className="header-department" >
                                            <button className="header-department-text header-department-text2 department-title" onClick={changemobileMenuNavigation}>
                                                <span className="icon"><i className="fal fa-bars"></i></span>
                                                <span className="text">Categories</span>
                                            </button>
                                            <nav className={'department-nav-menu ' + mobileMenuNavigation} style={{ display: menuBlack, overflow: 'scroll' }}>
                                                <button className="sidebar-close" style={{ display: menuBlack }} onClick={closeMobileMenuNavigation}><i className="fas fa-times"></i></button>
                                                <div class="dropdown dropdown" style={{ marginTop: '-18px' }} >
                                                    {getHeaderDatas.slice(0, 5).map((categoryOptions, categoryIndex) => (
                                                        categoryOptions.subcategory.length != 0 ?
                                                            <>
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <div className='row'>
                                                                        <div className='col-lg-4 col-sm-6 col-xd-6'>
                                                                            <LoadPreviewImage imagePath={'category'} image={categoryOptions.categoryImg} alt="Department" style={{ width: '80px', height: '45px' }} />
                                                                        </div>
                                                                        <div className='col-lg-4 col-sm-6 col-xd-6'>
                                                                            <p style={{ color: 'white', fontSize: '14px', fontWeight: 'bold' }}>{
                                                                                categoryOptions.categoryName.length > 14
                                                                                    ?
                                                                                    categoryOptions.categoryName.charAt(0).toUpperCase() + categoryOptions.categoryName.slice(1).toLowerCase().substring(0, 13) + "..."
                                                                                    :
                                                                                    categoryOptions.categoryName.charAt(0).toUpperCase() + categoryOptions.categoryName.slice(1).toLowerCase()
                                                                            }
                                                                                &nbsp;
                                                                                <BsFillArrowRightCircleFill /></p>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2" style={{ width: '240px' }}>
                                                                    {
                                                                        categoryOptions.subcategory.map((subcategoryData, j) =>
                                                                            <>
                                                                                <h6 style={{ color: 'pink' }}>{subcategoryData.subCategoryName.charAt(0).toUpperCase() + subcategoryData.subCategoryName.slice(1).toLowerCase()}</h6>
                                                                                <div className='col' styel={{ margin: '10px' }}></div>
                                                                                {subcategoryData.productType.length != 0 ?

                                                                                    <ul style={{}}>

                                                                                        {
                                                                                            subcategoryData.productType.map((productTypeData, k) =>
                                                                                                <>
                                                                                                    <li>
                                                                                                        <a href={`/onlinePharmacy/0/${categoryOptions.categoryId}`}>
                                                                                                            <p style={{ color: 'white', fontSize: '13px', marginBottom: '-10px' }} className="">{productTypeData.productType.charAt(0).toUpperCase() + productTypeData.productType.slice(1).toLowerCase()}</p>                                                                                                    </a>
                                                                                                    </li>

                                                                                                </>


                                                                                            )
                                                                                        }

                                                                                    </ul>

                                                                                    :
                                                                                    null}

                                                                            </>

                                                                        )}


                                                                </ul>
                                                                <div className='col' style={{ margin: '-14px' }}></div>
                                                            </>
                                                            :

                                                            <>
                                                                <a href={`/onlinePharmacy/0/${categoryOptions.categoryId}`} >
                                                                    <button class="btn btn-secondary dropdown-toggle" type="button">

                                                                        <LoadPreviewImage imagePath={'category'} image={categoryOptions.categoryImg} alt="Department" />
                                                                        <br />
                                                                        <p style={{ color: 'white', fontSize: '12px', fontWeight: 'bold' }}>{categoryOptions.categoryName.charAt(0).toUpperCase() + categoryOptions.categoryName.slice(1).toLowerCase()}</p>
                                                                    </button>
                                                                </a>
                                                                <div className='col' style={{ margin: '-14px' }}></div>

                                                            </>
                                                    ))}
                                                    {
                                                        getHeaderDatas.length > 7 ?
                                                            <li>
                                                                <a href="/CategoryViewPage" className="nav-link">
                                                                    <span className="menu-text">See more ...</span>
                                                                </a>
                                                            </li>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </nav>
                                            <div className={mobileMenuNavigationHide}></div>
                                        </aside>
                                    </div>
                            }

                            <div className={`header-main-nav ${profileNavigation2}`} style={{ marginTop: '-35px' }}>
                                <nav className="mainmenu-nav">
                                    <button className="mobile-close-btn mobile-nav-toggler" onClick={changeProfileNavigation2}><i className="fas fa-times" onClick={changeProfileNavigation2}></i></button>
                                    <div className='col' style={{ margin: '8px' }}></div>
                                    <ul className="mainmenu">
                                        <li>
                                            <Link to={"/pharmacy"}>Home</Link>
                                        </li>
                                        <li>
                                            <a href="/onlinePharmacy/0/0">Shop</a>
                                        </li>
                                    </ul>
                                    <div className='col-sm' style={{ margin: '-35px' }}></div>
                                    <div className="mobile-nav-brand" style={{ marginLeft: '25px' }}>
                                        <a href="/" className="logo">
                                            <img src="../../../../../../../img/project/logo/lysander_hospital.png" alt="Site Logo" height='50px' />
                                        </a>
                                    </div>
                                </nav>
                            </div>
                            <div className="header-action">
                                <ul className="action-list">
                                    <li className="axil-mobile-toggle">
                                        <button className="menu-btn mobile-nav-toggler" onClick={changeProfileNavigation2}>
                                            <i className="flaticon-menu-2"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

            <div className={'header-search-modal ' + searchBoxOpen} id="header-search-modal">
                <button className="card-close sidebar-close" onClick={closeSearchBoxOpen}><i className="fas fa-times"></i></button>
                <div className="header-search-wrap">
                    <div className="card-header">
                        <form action="#">
                            <div className="input-group" style={{ borderWidth: '1px', borderStyle: 'groove' }}>
                                <input type="search" className="form-control" name="prod-search" id="prod-search"
                                    placeholder="Search Product Here ...." value={getSearchkeyWord} onChange={productSearchByFilter} />
                                <button type="submit" className="axil-btn btn-bg-primary"><i className="far fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                    <div className="card-body">
                        <div className="psearch-results">

                            {
                                getSearchkeyWord != ''
                                    ?
                                    <div>
                                        {
                                            liveProductsFilter.map((options, index) => (

                                                <div>
                                                    {
                                                        options.product != ''
                                                            ?
                                                            <div className="axil-product-list">
                                                                <div className="thumbnail">
                                                                    

                                                                    <a href={`/onlinePharmacyView/${options._id}`}>
                                                                        
                                                                        <LoadPreviewImage imagePath={'product'} image={options.image1} alt="" style={{ width: '150px', height: '120px' }} />
                                                                        {/* <img src={`${baseurl}/uploadFiles/product/${options.image1}`} alt="Yantiti Leather Bags" style={{ width: '120px' }} /> */}
                                                                    </a>
                                                                </div>
                                                                <div className="product-content">




                                                                    <h6 className="product-title">
                                                                        <a href={`/onlinePharmacyView/${options._id}`}>
                                                                            {options.product.charAt(0).toUpperCase() + options.product.slice(1).toLowerCase()}
                                                                            </a></h6>
                                                                    <div className="product-price-variant">
                                                                        <span className="price current-price">Rs.{options.rate}</span>
                                                                        <span className="price old-price">Rs.{options.mrp}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                                <center><h3>Product Not Found</h3></center>
                                                            </>
                                                    }
                                                </div>




                                            ))
                                        }
                                    </div>
                                    :
                                    <>
                                        <center><h3>Search Product Here ....</h3></center>
                                    </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default ShopCategoryHeader;