import React from 'react';
import Footer from '../LayoutPages/Footer/Footer';
import HomeHeader from '../LayoutPages/Header/Header';
import ShoppingCartArea from './ShoppongCartArea/ShoppingCartArea';

const ShoppingCart = () => { 
    return (
        <>
            <HomeHeader/>
            <ShoppingCartArea/>
            <Footer/>
        </>
    );
};

export default ShoppingCart;