import React from 'react';
import HomeSIngleService from '../HomeSIngleService/HomeSIngleService';

const HomeServices = () => {
   return (
      <>
         <section className="servcies-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel home_ser_title">
                           <h2>Departments</h2>
                           <h1>Departments and Services</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="1" path="/services/1" title="General Medicine" content="The General Medicine department of a well-established hospital has been serving the community..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="2" path="/services/2" title="General Surgery" content="The General Surgery department of a well-established hospital has been providing surgical care..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="3" path="/services/3" title="Orthopedic" content="The orthopedic department at our hospital is 60-year-old and is a testament to the longevity..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="4" path="/services/4" title="ENT" content="ENT (Ear, Nose, and Throat) department of a our hospital is dedicated to..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="10" path="/services/10" title="Other Specialities" content="Our hospital is dedicated to providing our patients with a comprehensive range of..." />
                  <HomeSIngleService height="380px" format="png" heightStyle="122px" widthStyle="120px" icon="6" path="/services/6" title="Anaesthetic Treatment & Procedures" content="The 24-hour emergency department of a hospital with round the clock doctors..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="8" path="/services/8" title="Accident & Emergency Care 24/7" content="Undertake emergency 'RTA' coordinated with other departments in timely measures." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="7" path="/services/7" title="Dental Care" content="The dental department of a hospital is a vital component of the overall healthcare services..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="220px" icon="9" path="/services/9" title="Old Age Care" content="In the hustle and bustle of modern hospitals, there is something to be said for the old-fashioned..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="12" path="/services/12" title="Insurance " content="Health insurance policies help you to get a cashless hospitalisation facility. You can route your policy..." />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="11" path="/hospitalfacility" title="Hospital Facilities" content="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                  <HomeSIngleService height="380px" format="jpeg" heightStyle="122px" widthStyle="120px" icon="13" path="/services/13" title="For Ambulance Call" content="He availability of an ambulance service 24/7 in a hospital is crucial to providing patients..." />

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;