import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import RegisteArea from './RegisteArea/RegisteArea';

//import Header from '../LayoutPages/Header/Header';
import Header from '../LayoutPages/Header/Header';
import Footer from '../LayoutPages/Footer/Footer';
import { ReactSession } from 'react-client-session';

const Register = () => {

    const token = ReactSession.get("token");

    if (token) {
        window.location.href = "/shop/1/1/0/0/0/0/0";
    }

    return (
        <>
            <Header />
            <CommonPageHeader title="Register Page" subtitle="Register" />
            <RegisteArea />
            <Footer />
        </>
    );
};

export default Register;