import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

import { ReactSession } from 'react-client-session';
import axios from 'axios';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { userURL, userDetailsURL,website } from "../../config";

const HomeHeader = () => {

   useEffect(() => {

      showAllCartProduct();
      showAllWishlistProduct();

   }, [])

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   const sessionUserId = ReactSession.get("userid");
   const username = ReactSession.get("username");
   const sessionToken = ReactSession.get("token");

   const [getProductDetails1, setGetProductDetails1] = useState([]);
   const [getProductDetails2, setGetProductDetails2] = useState([]);

   const showAllCartProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showCartDetails`, productDetail, authToken)
         .then(data => {
            console.log(data.data.data);
            setGetProductDetails2(data.data.data);
         })
         .catch(error => {

         });

   }

   const showAllWishlistProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showWishlistDetails`, productDetail, authToken)
         .then(data => {
            setGetProductDetails1(data.data.data);
         })
         .catch(error => {

         });

   }


   const setLogout = () => {
      ReactSession.set("userid", '');
      ReactSession.set("username", '');
      ReactSession.set("email", '');
      ReactSession.set("phone", '');
      ReactSession.set("token", '');
      window.location.href = "/";
      for (var pi = 0; pi < getProductDetails2.length; pi++) {
         ReactSession.set(`addToCartProduct${getProductDetails2[pi].productid._id}`, '');
      }
      for (var pi2 = 0; pi2 < getProductDetails1.length; pi2++) {
         ReactSession.set(`wishlistProduct${getProductDetails1[pi2].productid._id}`, '');
      }

    
   }

   return (
      <>
         <header>
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                        <div className="header-info">
                           <span style={{fontSize: '15px'}}><i className="fas fa-phone"></i> (04562) 243233</span>
                           <span style={{fontSize: '15px'}}><i className="fas fa-envelope"></i> lysanderhospital@hotmail.com</span>
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-5 col-md-4">
                        <div className="header-top-right-btn f-right">
                           <Link to="/contact" className="primary_btn">Make Appointment</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}>
               <div className="container menu_wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                        {/* <div className="logo pos-rel">
                           <Link to="/"><img src={`${website}img/project/logo/lysander_hospital.png`} alt="" /></Link>
                        </div> */}
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header__menu f-right">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home</Link></li>
                                 <li><Link to={"/pharmacy"} style={{ color: "white", background: "rgb(225, 36, 84)", padding: "10px" }}>Pharmacy</Link></li>
                                 <li><Link to="/founder">Founder</Link></li>
                                 {/**<li><Link to="/doctors">Doctors +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/doctors">Doctors</Link></li>
                                       <li><Link to="/makeappointment">Make Appointment</Link></li>
                                    </ul>
                                 </li> */}
                                 <li><Link to="/hospitalfacility">Hospital Facility +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/insurance">Insurance</Link></li>
                                       <li><Link to="/surgeries">Surgeries</Link></li>
                                       <li><Link to="/hospitalfacility">Hospital Facility</Link></li>
                                       <li><Link to="/lab">Lab</Link></li>
                                       <li><Link to="/paramedical">Paramedical</Link></li>
                                       <li><Link to="/gallery">Gallery</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/contact">Contact</Link></li>

                                 {
                                    sessionUserId ?
                                       <li><Link to="/hospitalfacility">
                                          <PersonOutlineIcon style={{ color: "#e12454", fontSize: "30px" }} />
                                          &nbsp;<span>{username}</span>
                                       </Link>
                                          <ul className="submenu" style={{ width: "100px" }}>
                                             <li><Link to="/profileDetails">Profile</Link></li>
                                             <li><Link to="/orderdetails">Orders</Link></li>
                                             <li>

                                             <div className="login-btn">
                                                                <a href="#" onClick={setLogout} className="axil-btn" >Logout</a>
                                                            </div>
                                             </li>
                                          </ul>
                                       </li>
                                       :
                                       <li><Link to="/login">Sign In</Link></li>
                                 }

                              </ul>
                           </nav>
                        </div>

                        <div className="side-menu-icon d-lg-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent" style={{paddingLeft: "100px"}}>
                              <i className="fas fa-bars"></i> </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeHeader;