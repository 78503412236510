const portfolioData = [
    {
        id: 1,
        img: 'img/project/hospitalFacility/img1.jpg',
        category: 'general',
    },
    {
        id: 2,
        img: 'img/project/hospitalFacility/img2.jpg',
        category: 'general',
    },
    {
        id: 3,
        img: 'img/project/hospitalFacility/img3.jpg',
        category: 'general',
    },
    // {
    //     id: 4,
    //     img: 'img/project/hospitalFacility/img5.png',
    //     category: 'general',
    // },
]
export default portfolioData;