import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import axios from 'axios';
import { userURL } from "../config";

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Header from '../LayoutPages/Header/Header';
import Footer from '../LayoutPages/Footer/Footer';

import Swal from 'sweetalert2';


const RegisteArea = () => {


    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showEmailError, setShowEmailError] = useState(false);
    const [showMobileError, setShowMobileError] = useState(false);
    const [showConfirmError, setShowConfirmError] = useState(false);

    const onSubmit = (data) => {


        Swal.fire({
            title: 'Are you sure?',
            text: 'Your Password Will Send Your Email?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {

                var productDetail = {
                    // username: data.fusername,
                    email: data.femailAdd,
                    // phonenumber: data.fphonenumber
                };

                axios
                    .post(`${userURL}/user/forgotpassword`, productDetail)
                    .then(data => {
                        console.log(data)

                        if (data.data.success == true) {

                            Swal.fire('Success', 'Your Password is Send In Your Email', 'success')
                            .then((result) => {
                                console.log(result);
                                if (result) {
                                    window.location.href = "/login";
                                } else {
                                    
                                }
                            });
                        }
                        else {
                            Swal.fire('Oops...', 'Please Check Your Details', 'error');
                        }

                    })
                    .catch(error => {

                    });

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Are Cancelled This Action',
                    'error'
                )
            }
        })

    };


    return (
        <>

            <Header />
            <CommonPageHeader title="Forgot Password" subtitle="Forgot Password" />

            <section className="login-area pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="basic-login">
                                <h3 className="text-center mb-60">Forgot Password</h3>

                                <Form onSubmit={handleSubmit(onSubmit)}>

                                    {/* <Form.Field>
                                        <label htmlFor="name" className='mb-2' >Username <span>**</span></label>
                                        <input id="name" type="text"{...register("fusername", { required: true })} autoComplete="off" placeholder="Enter Username..." />
                                    </Form.Field>
                                    {errors.fusername && <p style={{ color: "red" }}>User name is required</p>} */}

                                    <Form.Field>

                                        <label htmlFor="email-id" className='mb-2'>Email Address <span>**</span></label>
                                        <input id="email-id" name="email" {...register("femailAdd", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} autoComplete="off" type="text"
                                            placeholder="Email address..." />

                                    </Form.Field>
                                    {errors.femailAdd && <p style={{ color: "red" }}>Please Enter Valid Email</p>}
                                    {showEmailError ? <p style={{ color: "red" }}>Email Already Present</p> : null}

                                    {/* <Form.Field>
                                        <label htmlFor="phone" className='mb-2' >Phone <span>**</span></label>
                                        <input id="phone" name="phone" {...register("fphonenumber", { required: true, minLength: 10, maxLength: 10 })} autoComplete="off" type="number" placeholder="Enter Phone Number..." />
                                    </Form.Field>
                                    {errors.fphonenumber && <p style={{ color: "red" }}>Please Enter Valid phone number</p>}
                                    {showMobileError ? <p style={{ color: "red" }}>Phone number Already Present</p> : null} */}

                                    <div className="mt-10"></div>
                                    <button type='submit' className="primary_btn theme-btn-2 w-100">Get Password</button>
                                    <div className="or-divide"><span>or</span></div>
                                    <Link to="/login"><button className="primary_btn btn-icon-green w-100">login Now</button></Link>

                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default RegisteArea;