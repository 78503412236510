import React from 'react';
import { Link } from 'react-router-dom';

import '../../privacy.css';

const BlogDetailsLeftSide5 = ({ details_topBar }) => {
    return (
        <>
            <div className="col-lg-12" style={{ textAlign: "justify" }}>
                <article className="postbox post format-image mb-40">


                    <div className="postbox__text bg-none">
                        <h3 className="blog-title">
                            RETURN POLICY
                        </h3>

                        <p className="para1 m-t-1-5">
                            1. For Return intimation, please visit https://lysanderhospital.com/contact.<br />
                            2. LysanderHospital customer care team will verify the claim made by the customer within 72 (seventy-two) business hours from the time of receipt of complaint.<br />
                            3. Once the claim is verified as genuine and reasonable, LysanderHospital will initiate the collection of product(s) to be returned.<br />
                            4. The customer will be required to pack the product(s) in original manufacturer’s packaging.<br />
                            5. Refund will be completed within 30 (thirty) days from date of reverse pick up (if required).<br />
                        </p>
                        {/* <p className="para1 m-t-1">
                            You are advised to carefully read this Privacy Notice before using or availing any of our products and/or services.
                        </p> */}
                        <br />


                    </div>

                </article>
            </div>
        </>
    );
};

export default BlogDetailsLeftSide5;