import React from 'react';
import { Link } from 'react-router-dom';

import '../../privacy.css';

const BlogDetailsLeftSide4 = ({ details_topBar }) => {
    return (
        <>
            <div className="col-lg-12" style={{ textAlign: "justify" }}>
                <article className="postbox post format-image mb-40">


                    <div className="postbox__text bg-none">
                        <h3 className="blog-title">
                            REFUND POLICY
                        </h3>

                        <p className="para1 m-t-1-5">
                            In all the above cases, if the claim is found to be valid, Refund will be made as mentioned below:<br/>
                            1. Order placed through online wallet will be credited to the wallet; and <br/>
                            2. Order placed through cash on delivery will be refunded through fund transfer to customer bank account.                        </p>
                        {/* <p className="para1 m-t-1">
                            You are advised to carefully read this Privacy Notice before using or availing any of our products and/or services.
                        </p> */}
                        <br />


                    </div>

                </article>
            </div>
        </>
    );
};

export default BlogDetailsLeftSide4;