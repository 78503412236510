import React, { useState } from 'react';
import FounderContent from '../FounderContent/FounderContent';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const AppointmentArea = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">
                                <h1>Founder</h1>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Founder</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />

            <ModalVideo channel='youtube' autoplay isOpen={isOpen}
                videoId="I3u3lFA9GX4" onClose={() => setOpen(false)} />

            <FounderContent area_header={
                <div className="about-left-side pos-rel mb-30">
                    <div className="medical-icon-brand-2 medical-icon-brand-3">
                        <img src="img/about/medical-brand-icon-border.png" alt="" />
                    </div>
                    <div className="about-front-img pos-rel">
                        <img src="img/project/about/ly.png" alt="" />
                    </div>
                    <div className="about-shape">
                        <img src="img/about/about-shape.png" alt="" />
                    </div>
                </div>
            } />
        </>
    );
};

export default AppointmentArea;