import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import axios from 'axios';

import { CommonUserApi } from "../../config";

const ContactFormArea = () => {

   const [userName, setName] = useState();
   const [userEmail, setEmail] = useState();
   const [userPhone, setPhone] = useState();
   const [userSubject, setSubject] = useState();
   const [userMsg, setMessage] = useState();

   const contactFormSubmit = () => {
      if (userName && userEmail && userPhone && userSubject && userMsg) {
         var productDetail = {
            name: userName,
            email: userEmail,
            phone: userPhone,
            subject: userSubject,
            message: userMsg
         };

         axios
            .post(`${CommonUserApi}/userDetails/contactFormMailSend`, productDetail)
            .then(data => {
               setName('');
               setEmail('');
               setPhone('');
               setSubject('');
               setMessage('');
               Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Mail Send Successfully',
              })
            })
            .catch(error => {
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: "Please Try Again Later",
              })
            });
      }
   }

   const formUserName = event => {
      var result = event.target.value;
      setName(result);
   }

   const formUserEmail = event => {
      var result = event.target.value;
      setEmail(result);
   }

   const formUserPhone = event => {
      var result = event.target.value;
      setPhone(result);
   }

   const formUserSubject = event => {
      var result = event.target.value;
      setSubject(result);
   }

   const formUserMsg = event => {
      var result = event.target.value;
      setMessage(result);
   }

   return (
      <>
         <section className="contact-form-area gray-bg pt-100 pb-100">
            <div className="" style={{padding: '5px',boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px',borderRadius: '25px'}}>
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-55">
                       
                        </div>
                     </div>
                  </div>
                  <div className="contact-form">
                     <form id="contact-form" action="#">
                        <div className="row">
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30" style={{borderWidth: '1px',borderStyle: 'groove'}}>
                                 <input type="text" name="name" value={userName} placeholder="Your Name" onChange={formUserName} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30" style={{borderWidth: '1px',borderStyle: 'groove'}}>
                                 <input type="text" name="email" value={userEmail} placeholder="Your Email" onChange={formUserEmail} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box phone-icon mb-30" style={{borderWidth: '1px',borderStyle: 'groove'}}>
                                 <input type="text" name="phone" value={userPhone} placeholder="Your Phone" onChange={formUserPhone} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box subject-icon mb-30" style={{borderWidth: '1px',borderStyle: 'groove'}}>
                                 <input type="text" name="subject" value={userSubject} placeholder="Your Subject" onChange={formUserSubject} />
                              </div>
                           </div>
                           <div className="col-lg-12">
                              <div className="form-box message-icon mb-30" style={{borderWidth: '1px',borderStyle: 'groove'}}>
                                 <textarea name="message" id="message" cols="30" rows="10"
                                    placeholder="Your Message" onChange={formUserMsg}>{userMsg}</textarea>
                              </div>                              
                           </div>
                           <div className="col-lg-4">

                           </div>
                           <div className="col-lg-3">
                           <div className="contact-btn text-center">
                                 <button className="primary_btn btn-icon ml-0" type="button" onClick={contactFormSubmit}><span>+</span> Submit</button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <p className="ajax-response text-center"></p>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactFormArea;