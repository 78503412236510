import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL } from "../../pages/config";
import { ReactSession } from 'react-client-session';

import { UserContext } from '../../pages/LayoutPages/Header/UserContext';

import Swal from 'sweetalert2';

const SingleShop = ({ notAllowed, cartAllowed, key, image, producttype, title, mrp, originalrate, productId }) => {

    const { getWishlistTrigger, setGetWishlistTrigger,getAddToCartTrigger,setGetAddToCartTrigger } = useContext(UserContext);

    const [productWishAllowed, setProductWishAllowed] = useState();
    const [shopingAddToCart, setShopingAddToCart] = useState();

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");

    //alert(notAllowed);
    //alert(cartAllowed);

    useEffect(() => {

        if (!ReactSession.get(`wishlistProduct${productId}`)) {
            if (parseInt(notAllowed) == 1) {
                ReactSession.set(`wishlistProduct${productId}`, 1);
                setProductWishAllowed(1);
            }
            else {
                setProductWishAllowed('');
            }
        }
        else if (ReactSession.get(`wishlistProduct${productId}`)) {
            ReactSession.set(`wishlistProduct${productId}`, 1);
            setProductWishAllowed(1);
        }

        if (!ReactSession.get(`addToCartProduct${productId}`)) {
            if (parseInt(cartAllowed) == 1) {
                ReactSession.set(`addToCartProduct${productId}`, 1);
                setShopingAddToCart(1);
            }
            else {
                setShopingAddToCart('');
            }
        }
        else if (ReactSession.get(`addToCartProduct${productId}`)) {
            ReactSession.set(`addToCartProduct${productId}`, 1);
            setShopingAddToCart(1);
        }

    }, [])


    const addtocartDetails = () => {

        const addToCartProduct = ReactSession.get(`addToCartProduct${productId}`);

        if (sessionToken) {

            if (addToCartProduct) {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                axios
                    .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
                    .then(data => {
                        ReactSession.set(`addToCartProduct${productId}`, '');
                        setShopingAddToCart('');
                        //window.location.reload(false);
                        setGetAddToCartTrigger(getAddToCartTrigger-1);
                    })
                    .catch(error => {

                    });

            }
            else {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId,
                    producttotal: originalrate
                };

                axios
                    .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetail, authToken)
                    .then(data => {
                        ReactSession.set(`addToCartProduct${productId}`, 1);
                        setShopingAddToCart(1);
                        //window.location.reload(false);
                        setGetAddToCartTrigger(getAddToCartTrigger+1);
                    })
                    .catch(error => {

                    });

            }

        }
        else {
            
            Swal.fire('Oops..', 'Please Login Your Account', 'error')
            .then((result) => {
               console.log(result);
               if (result) {
                  window.location.href = "/login";
               } else {

               }
            });

        }

    }

    const wishlistDetails = () => {

        const wishlistProduct = ReactSession.get(`wishlistProduct${productId}`);

        if (sessionToken) {

            if (wishlistProduct) {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                axios
                    .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
                    .then(data => {
                        ReactSession.set(`wishlistProduct${productId}`, '');
                        setProductWishAllowed('');
                        //window.location.reload(false);
                        setGetWishlistTrigger(getWishlistTrigger-1);
                    })
                    .catch(error => {

                    });

            }
            else {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                axios
                    .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
                    .then(data => {
                        ReactSession.set(`wishlistProduct${productId}`, 1);
                        setProductWishAllowed(1);
                        //window.location.reload(false);
                        setGetWishlistTrigger(getWishlistTrigger+1);
                    })
                    .catch(error => {

                    });

            }

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    console.log(result);
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });

        }

    }

    return (
        <>
            <div className="col-lg-4 col-md-6">
                <div className="product mb-40">
                    <div className="product__img">
                        <Link to={"/shopDetails/" + productId}><img src={image} alt="" className="shopBannerImg" /></Link>
                        <div className="product-action text-center">
                            {
                                shopingAddToCart == 1 ?

                                    <><a style={{ background: "red", color: "white", cursor: "pointer" }} onClick={addtocartDetails}><i className="fas fa-shopping-cart"></i></a></>

                                    :

                                    <><a style={{ background: "white", color: "black", cursor: "pointer" }} onClick={addtocartDetails}><i className="fas fa-shopping-cart"></i></a></>

                            }

                            {
                                productWishAllowed == 1 ?
                                    <>
                                        <a style={{ background: "red", color: "white", cursor: "pointer" }} onClick={wishlistDetails}><i className="fas fa-heart"></i></a>
                                    </>
                                    :

                                    <>
                                        <a style={{ background: "white", color: "black", cursor: "pointer" }} onClick={wishlistDetails}><i className="fas fa-heart"></i></a>
                                    </>
                            }

                            <Link to={"/shopDetails/" + productId} style={{ background: "white", color: "black" }}><i className="fas fa-expand"></i></Link>

                        </div>
                    </div>
                    <div className="product__content text-center pt-30">
                        <span className="pro-cat"><a href={"/shopDetails/" + productId}>{producttype}</a></span>
                        <h4 className="pro-title"><Link to={"/shopDetails/" + productId}>{title}</Link></h4>
                        <div className="price">
                            <span>&#x20B9;{originalrate}</span>
                            <span className="old-price">&#x20B9;{mrp}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleShop;