import React, { useState, useEffect, useContext } from 'react';

import { Carousel } from 'react-responsive-carousel';

import axios from 'axios';
import { masterUrl, baseurl, ProductURL, userDetailsURL, userURL } from "../../../config";
import Swal from 'sweetalert2';
import { ReactSession } from 'react-client-session';

import { UserContext } from '../../../LayoutPages/Header/UserContext';

import { useParams } from 'react-router-dom';

import LoadPreviewImage from "../../../config/LoadPreviewImage";

const PharmacyShopPage = () => {

    const { getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger } = useContext(UserContext);

    const [getProductCategories, setGetProductCategories] = useState([]);
    const [getProductBrand, setGetProductBrand] = useState([]);
    const [getLiveProducts, setGetLiveProducts] = useState([]);
    const [getSlider, setGetSlider] = useState([]);
    const [getProductTags, setGetProductTags] = useState([]);

    const [filterNavigation, setFilterNavigation] = useState('');
    const [mobileMenuNavigationHide, setmobileMenuNavigationHide] = useState('');

    const [getCategoryFilter, setGetCategoryFilter] = useState('');
    const [getBrandFilter, setGetBrandFilter] = useState('');
    const [getProductTagFilter, setGetProductTagFilter] = useState('');

    const [liveProductsFilters, setLiveProductsFilters] = useState([]);

    const [getProductLimit, setGetProductLimit] = useState(12);
    const [getIsWishlistProduct, setGetIsWishlistProduct] = useState('');

    const [loader, setLoader] = useState(false);

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");

    let { brandId } = useParams();
    let { cateId } = useParams();

    const [totalUserWishlist, setTotalUserWishlist] = useState([]);
    const [totalUserCart, setTotalUserCart] = useState([]);

    const [categoryValues, setCategoryValues] = useState([]);
    const [brandsValues, setBrandsValues] = useState([]);
    const [productsTagsValues, setProductsTagsValues] = useState([]);

    const [getSearchCategory, setGetSearchCategory] = useState([]);
    const [getSearchkeyWordCategory, setGetSearchkeyWordCategory] = useState('');

    const [getSearchBrand, setGetSearchBrand] = useState([]);
    const [getSearchkeyWordBrand, setGetSearchkeyWordBrand] = useState('');

    const [getSearchProductTag, setGetSearchProductTag] = useState([]);
    const [getSearchkeyWordProductTag, setGetSearchkeyWordProductTag] = useState('');


    const [originalProductQuantity, setOriginalProductQuantity] = useState('');

    const getCategoriesForFetch = async () => {
        setLoader(true);

        await axios
            .get(`${masterUrl}/category/getCategories`)
            .then(data => {

                if (data.data.status == 200) {
                    setLoader(false);

                    setGetProductCategories(data.data.data);

                }
                else {
                    setLoader(true);

                    Swal.fire({
                        icon: 'info',
                        text: 'Please Wait..!',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        timer: 1000
                    })
                    
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    useEffect(() => {
        getCategoriesForFetch();
        getBrandsForFetch();
        getPharmacySlider();
        getProductsTagForFetch();
        totalWishlist();
        totalCart();
        searchCategory();
        searchBrand();
        searchProductTags();
        // categorySerachFilterDataShow();
    }, [])

    useEffect(() => {
        getProductsForFetch();
    }, [getProductLimit])

    const changeSearchBoxOpen = () => {
        if (filterNavigation) {
            setFilterNavigation('');
            setmobileMenuNavigationHide('');
        }
        else {
            setFilterNavigation('open');
            setmobileMenuNavigationHide('closeMask');
        }
    }

    const closeMobileMenuNavigation = () => {
        setFilterNavigation('');
        setmobileMenuNavigationHide('');
    }

    const getPharmacySlider = () => {

        axios
            .get(`${masterUrl}/slider/getSlider`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSlider(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

   

    const getBrandsForFetch = async () => {

        await axios
            .get(`${masterUrl}/brand/getBrand`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetProductBrand(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue3',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const allProductFilter = async () => {

        if (brandsValues.length != 0 || categoryValues.length != 0 || productsTagsValues.length != 0) {

            const productDetail = {
                brandChecked: brandsValues.length == 0 ? '' : brandsValues,
                category: categoryValues.length == 0 ? '' : categoryValues,
                productTagsChecked: productsTagsValues.length == 0 ? '' : productsTagsValues,
                productTypeChecked: '',
                subCategoryChecked: ''
            };
            await axios
                .post(`${userURL}/product/getProductByFilter`, productDetail)
                .then(data => {

                    if (data.data.status == 200) {
                        setGetLiveProducts(data.data.data);
                    }

                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: ''
                    })
                });


        }
        else {
            getProductsForFetch();
        }

    }

    const getProductsForFetch = async () => {
        const productDetail = {
            categoryId: cateId,
            brandId: brandId
        };

        await axios
            .post(`${ProductURL}/productcrud/getProductForWeb`, productDetail)
            .then(data => {

                if (data.data.status == 200) {
                    setGetLiveProducts(data.data.data);
                    setLiveProductsFilters(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue4',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const getProductsTagForFetch = async () => {

        await axios
            .get(`${masterUrl}/producttag/getProducttag`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetProductTags(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue5',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    };

    const categoryFilterApply2 = (checked, value, categoryid) => {
        // alert( checked);
        if (checked) {
            categoryValues.push(categoryid);
            // alert( categoryValues);

        }
        else {
            const index = categoryValues.indexOf(categoryid);
            if (index > -1) {
                categoryValues.splice(index, 1);
            }
            // alert( index);
        }
        allProductFilter();
    }

    const brandFilterApply2 = (checked, value, id) => {
        if (checked) {
            brandsValues.push(id);
        }
        else {
            const index = brandsValues.indexOf(id);
            if (index > -1) {
                brandsValues.splice(index, 1);
            }
        }
        allProductFilter();
    }

    const productTagFilterApply2 = (checked, value, id) => {

        if (checked) {
            productsTagsValues.push(id);
        }
        else {
            const index = productsTagsValues.indexOf(id);
            if (index > -1) {
                productsTagsValues.splice(index, 1);
            }
        }
        allProductFilter();

    }

    const categoryFilterApply = (checked, value, categoryid) => {
        if (checked) {
            setGetCategoryFilter(categoryid);
            setGetLiveProducts(liveProductsFilters.filter(
                i => {
                    if (getBrandFilter && !(getProductTagFilter)) {
                        return (
                            i.brand.includes(getBrandFilter) || i.categoryrefid.includes(categoryid)
                        )
                    }
                    else if (getBrandFilter && getProductTagFilter) {
                        return (
                            i.brand.includes(getBrandFilter) || i.producttag.includes(getProductTagFilter) || i.categoryrefid.includes(categoryid)
                        )
                    }
                    else if (!(getBrandFilter) && getProductTagFilter) {
                        return (
                            i.brand.includes(getBrandFilter) || i.categoryrefid.includes(categoryid)
                        )
                    }
                    else {
                        return (
                            i.categoryrefid.includes(categoryid)
                        )
                    }
                }
            ));
        }
        else {
            setGetCategoryFilter('');
            setGetLiveProducts(liveProductsFilters);
        }
    }

    const brandFilterApply = (checked, value, id) => {

        if (checked) {
            setGetBrandFilter(id);
            setGetLiveProducts(liveProductsFilters.filter(
                i => {
                    if (getCategoryFilter && !(getProductTagFilter)) {
                        return (
                            i.brand.includes(id) || i.categoryrefid.includes(getCategoryFilter)
                        )
                    }
                    else if (getCategoryFilter && getProductTagFilter) {
                        return (
                            i.brand.includes(id) || i.categoryrefid.includes(getCategoryFilter) || i.producttag.includes(getProductTagFilter)
                        )
                    }
                    else if (!(getCategoryFilter) && getProductTagFilter) {
                        return (
                            i.brand.includes(id) || i.producttag.includes(getProductTagFilter)
                        )
                    }
                    else {
                        return (
                            i.brand.includes(id)
                        )
                    }
                }
            ));
        }
        else {
            setGetBrandFilter('');
            setGetLiveProducts(liveProductsFilters);
        }

    }

    const productTagFilterApply = (checked, value, id) => {

        if (checked) {
            setGetProductTagFilter(id);
            setGetLiveProducts(liveProductsFilters.filter(
                i => {
                    if (getCategoryFilter && !(getBrandFilter)) {
                        return (
                            i.producttag.includes(id) || i.categoryrefid.includes(getCategoryFilter)
                        )
                    }
                    else if (getCategoryFilter && getBrandFilter) {
                        return (
                            i.producttag.includes(id) || i.brand.includes(getBrandFilter) || i.categoryrefid.includes(getCategoryFilter)
                        )
                    }
                    else if (!(getCategoryFilter) && getBrandFilter) {
                        return (
                            i.producttag.includes(id) || i.brand.includes(getBrandFilter)
                        )
                    }
                    else {
                        return (
                            i.producttag.includes(id)
                        )
                    }
                }
            ));
        }
        else {
            setGetProductTagFilter('');
            setGetLiveProducts(liveProductsFilters);
        }

    }

    const loadMoreProducts = () => {
        setGetProductLimit(getProductLimit * 2);
    }

    const wishlistActions = async (getIsWishlistHere, productId) => {

        if (sessionToken) {
            if (getIsWishlistHere == 1) {

                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                await axios
                    .post(`${userDetailsURL}/userDetails/deleteWishlist`, productDetail, authToken)
                    .then(data => {
                        setGetWishlistTrigger(getWishlistTrigger - 1);
                        totalWishlist();
                    })
                    .catch(error => {

                    });

            }
            else if (getIsWishlistHere == 0) {
                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                await axios
                    .post(`${userDetailsURL}/userDetails/addWishlist`, productDetail, authToken)
                    .then(data => {
                        setGetWishlistTrigger(getWishlistTrigger + 1);
                        totalWishlist();
                    })
                    .catch(error => {

                    });

            }
        }
    }

    const wishlistDetails = async (productId) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            await axios
                .post(`${userDetailsURL}/userDetails/checkProductWishlist`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        wishlistActions(data.data.data, productId);
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const addToCartDetails = async (productId, originalRate) => {

        if (sessionToken) {

            const productDetail = {
                userid: sessionUserId,
                productid: productId
            };

            await axios
                .post(`${userDetailsURL}/userDetails/checkProductCart`, productDetail)
                .then(data => {
                    if (data.data.status == 200) {
                        AddToCartActions(data.data.data, productId, originalRate);
                        console.log('data.data.datadata.data.datadata.data.datadata.data.data', data.data.data, productId, originalRate)
                    }
                })
                .catch(error => {

                });

        }
        else {

            Swal.fire('Oops..', 'Please Login Your Account', 'error')
                .then((result) => {
                    if (result) {
                        window.location.href = "/login";
                    } else {

                    }
                });


        }
    }

    const AddToCartActions = async (getIsAddToCartHere, productId, originalrate) => {



        if (sessionToken) {

            if (getIsAddToCartHere == 1) {


                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };

                const productDetail = {
                    userid: sessionUserId,
                    productid: productId
                };

                await axios
                    .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
                    .then(data => {
                        setGetAddToCartTrigger(getAddToCartTrigger - 1);
                        totalCart();
                    })
                    .catch(error => {

                    });

            }
            else if (getIsAddToCartHere == 0) {

                var productDetail = {
                    id: productId
                };

                await axios
                    .post(`${userURL}/product/getProductById`, productDetail)
                    .then(data => {
                        console.log('121221122121211221', data.data.data[0].qtyLimit);
                        setOriginalProductQuantity(data.data.data[0].qtyLimit)
                    })


                console.log('121221122121211221ss', originalProductQuantity);


                const authToken = {
                    headers: { Authorization: `Bearer ${sessionToken}` }
                };
                var productDetail = {
                    id: productId
                };
                await axios
                    .post(`${userURL}/product/getProductById`, productDetail)
                    .then(data => {
                        console.log('121221122121211221rtrtr', data.data.data[0].qtyLimit);
                        setOriginalProductQuantity(data.data.data[0].qtyLimit)


                        const productDetails = {
                            userid: sessionUserId,
                            productid: productId,
                            producttotal: originalrate,
                            productqty: data.data.data[0].qtyLimit
                        };

                        axios
                            .post(`${userDetailsURL}/userDetails/addShopingCart`, productDetails, authToken)
                            .then(data => {
                                setGetAddToCartTrigger(getAddToCartTrigger + 1);
                                totalCart();
                            })
                            .catch(error => {

                            });
                    })
            }
        }
    }

    const totalWishlist = () => {

        if (sessionUserId) {

            const productDetail = {
                userid: sessionUserId
            };

            axios
                .post(`${userDetailsURL}/userDetails/productWishlist`, productDetail)
                .then(data => {
                    setTotalUserWishlist(data.data.data);
                })
                .catch(error => {

                });
        }

    }

    const totalCart = async () => {

        if (sessionUserId) {

            const productDetail = {
                userid: sessionUserId
            };

            await axios
                .post(`${userDetailsURL}/userDetails/productCart`, productDetail)
                .then(data => {
                    setTotalUserCart(data.data.data);
                })
                .catch(error => {

                });

        }

    }

    const checkInWishlist = (id) => {

        if (totalUserWishlist.length != 0) {
            for (var i = 0; i < totalUserWishlist.length; i++) {
                if (totalUserWishlist[i].productid == id) {
                    return 1;
                    getProductsForFetch();
                    break;
                }
            }
            return 0;
        }
        else {
            return 0;
        }

    }

    const checkInCart = (id) => {
        if (totalUserCart.length != 0) {

            for (var i = 0; i < totalUserCart.length; i++) {
                if (totalUserCart[i].productid == id) {
                    return 1;
                    getProductsForFetch();
                    break;
                }
            }
            return 0;

        }
        else {
            return 0;
        }
    }

    const allreset = () => {
        window.location = '/onlinePharmacy/0/0';
    }

    const searchCategory = () => {
        axios
            .get(`${masterUrl}/category/getCategories`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSearchCategory(data.data.data);
                }
                // else {
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Network Issue6',
                //         text: 'Please Check Your Connection',
                //         footer: ''
                //     })
                // }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });
    }

    const categorySearchByFilter = event => {
        var searchKeyWorkIs = event.target.value;
        setGetSearchkeyWordCategory(searchKeyWorkIs);
        const fff = getSearchCategory.filter(i => i.category.toLowerCase().includes(searchKeyWorkIs.toLowerCase()));
        setGetProductCategories(fff);
    }

    const searchBrand = () => {
        axios
            .get(`${masterUrl}/brand/getBrand`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSearchBrand(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue7',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });
    }

    const BarndSearchByFilter = event => {
        var searchKeyWorkIs = event.target.value;
        setGetSearchkeyWordBrand(searchKeyWorkIs);
        // setLiveCategoryFilter(getSearchBrand.filter(i => i.brand.toLowerCase().includes(searchKeyWorkIs.toLowerCase())));
        const fff = getSearchBrand.filter(i => i.brandname.toLowerCase().includes(searchKeyWorkIs.toLowerCase()));
        setGetProductBrand(fff);
    }

    const searchProductTags = () => {
        axios
            .get(`${masterUrl}/producttag/getProducttag`)
            .then(data => {

                if (data.data.status == 200) {
                    setGetSearchProductTag(data.data.data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Issue8',
                        text: 'Please Check Your Connection',
                        footer: ''
                    })
                }

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    footer: ''
                })
            });

    }

    const productTagSearchByFilter = event => {
        var searchKeyWorkIs = event.target.value;
        setGetSearchkeyWordProductTag(searchKeyWorkIs);
        // setLiveCategoryFilter(getSearchBrand.filter(i => i.brand.toLowerCase().includes(searchKeyWorkIs.toLowerCase())));
        const fff = getSearchProductTag.filter(i => i.producttags.toLowerCase().includes(searchKeyWorkIs.toLowerCase()));
        setGetProductTags(fff);
    }


    return (
        <>
            <main className="main-wrapper">

                <div className="axil-breadcrumb-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="inner">
                                    <ul className="axil-breadcrumb">
                                        <li className="axil-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="separator"></li>
                                        <li className="axil-breadcrumb-item active" aria-current="page">Online pharmacy</li>
                                    </ul>
                                    <h1 className="title">Explore category
                                        <div className='col' style={{ margin: '10px' }}></div>

                                        {
                                            getProductCategories.map((options, index) => {
                                                if (cateId != 0) {
                                                    if (cateId == options._id) {
                                                        return (
                                                            // str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                                                            <p style={{ fontSize: '31px' }}>{options.category.charAt(0).toUpperCase() + options.category.slice(1).toLowerCase()}</p>
                                                        );
                                                    }
                                                }
                                            })}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class={mobileMenuNavigationHide}></div>
                <div className="axil-shop-area axil-section-gap bg-color-white" style={{ background: "#f0f0f5" }}>
                    <div style={{ padding: "30px" }}>
                        <div className="row">
                            <div className="col-lg-3" style={{ width: "250px", background: "white", borderRadius: "8px" }}>
                                <div className={"axil-shop-sidebar " + filterNavigation}>
                                    <div className="d-lg-none">
                                        <button className="sidebar-close filter-close-btn" onClick={closeMobileMenuNavigation}><i className="fas fa-times"></i></button>
                                    </div><br />
                                    <h3>Filters</h3><hr /><br />
                                    <div className="toggle-list product-categories active">
                                        <h6 className="title">Categories</h6>
                                        <div className="input-group" style={{ borderWidth: '1px', borderStyle: 'groove' }}>
                                            <input type="search" className="form-control" name="prod-search" id="prod-search" value={getSearchkeyWordCategory} onChange={categorySearchByFilter}
                                                placeholder="Search Category Here ...." />
                                        </div>
                                        <div className='row' style={{ padding: '12px' }}></div>
                                        <div className="shop-submenu">
                                            <ul style={{ overflowY: 'scroll', height: '200px' }}>
                                                {
                                                    getProductCategories.map((options, index) => {
                                                        if (cateId != 0) {
                                                            if (cateId == options._id) {
                                                                return <li><input type="checkbox" name="categoriesFilter" onClick={(event) => categoryFilterApply2(event.target.checked, event.target.value, options._id)} value={options.category} style={{ opacity: 1, position: 'inherit', display: 'inline', width: '30px', height: '13px' }} checked /><span style={{ fontSize: "12px" }}>{options.category.charAt(0).toUpperCase() + options.category.slice(1).toLowerCase()}</span></li>
                                                            }
                                                        }
                                                        else {
                                                            return <li><input type="checkbox" name="categoriesFilter" onClick={(event) => categoryFilterApply2(event.target.checked, event.target.value, options._id)} value={options.category} style={{ opacity: 1, position: 'inherit', display: 'inline', width: '30px', height: '13px' }} /><span style={{ fontSize: "12px" }}>{options.category.charAt(0).toUpperCase() + options.category.slice(1).toLowerCase()}</span></li>
                                                        }
                                                    })}
                                            </ul>
                                        </div>
                                    </div><hr />
                                    <div className="toggle-list product-categories active">
                                        <h6 className="title">Brands</h6>

                                        <div className="input-group" style={{ borderWidth: '1px', borderStyle: 'groove' }}>
                                            <input type="search" className="form-control" name="prod-search" id="prod-search" value={getSearchkeyWordBrand} onChange={BarndSearchByFilter}
                                                placeholder="Search Brand Here ...." />
                                        </div>
                                        <div className='row' style={{ padding: '12px' }}></div>

                                        <div className="shop-submenu">
                                            <ul style={{ overflowY: 'scroll', height: '200px' }}>
                                                {getProductBrand.map((options, index) => {

                                                    if (brandId != 0) {
                                                        if (brandId == options._id) {
                                                            return <li><input type="checkbox" name="brandFIlter" onClick={(event) => brandFilterApply2(event.target.checked, event.target.value, options._id)} value={options.brandname} style={{ opacity: 1, position: 'inherit', display: 'inline', width: '30px', height: '13px' }} checked /><span style={{ fontSize: "12px" }}>{options.brandname.charAt(0).toUpperCase() + options.brandname.slice(1).toLowerCase()}</span></li>
                                                        }
                                                    }
                                                    else {
                                                        return <li><input type="checkbox" name="brandFIlter" onClick={(event) => brandFilterApply2(event.target.checked, event.target.value, options._id)} value={options.brandname} style={{ opacity: 1, position: 'inherit', display: 'inline', width: '30px', height: '13px' }} /><span style={{ fontSize: "12px" }}>{options.brandname.charAt(0).toUpperCase() + options.brandname.slice(1).toLowerCase()}</span></li>
                                                    }

                                                })}
                                            </ul>
                                        </div>
                                    </div><hr />
                                    <div className="toggle-list product-categories active">
                                        <h6 className="title">Product tags</h6>

                                        <div className="input-group" style={{ borderWidth: '1px', borderStyle: 'groove' }}>
                                            <input type="search" className="form-control" name="prod-search" id="prod-search" value={getSearchkeyWordProductTag} onChange={productTagSearchByFilter}
                                                placeholder="Search Producttag Here ...." />
                                        </div>
                                        <div className='row' style={{ padding: '12px' }}></div>

                                        <div className="shop-submenu">
                                            <ul style={{ overflowY: 'scroll', height: '200px' }}>
                                                {getProductTags.map((options, index) => {
                                                    return <li><input type="checkbox" name="productTagFilter" onClick={(event) => productTagFilterApply2(event.target.checked, event.target.value, options.producttags)} value={options.producttags} style={{ opacity: 1, position: 'inherit', display: 'inline', width: '30px', height: '13px' }} /><span style={{ fontSize: "12px" }}>{options.producttags.charAt(0).toUpperCase() + options.producttags.slice(1).toLowerCase()}</span></li>
                                                })}
                                            </ul>
                                        </div>
                                    </div><hr />
                                    <button className="axil-btn btn-bg-primary" onClick={allreset}>All reset</button>
                                </div>
                            </div>
                            <div className="col-lg-9">

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="axil-shop-top mb--40">

                                            <div>
                                                {/** <a href="#" style={{fontSize: '14px',borderStyle: 'groove',padding: '3px'}}>Vitamins Nutrition X</a> */}
                                            </div>
                                            <div className="d-lg-none">
                                                <button className="product-filter-mobile filter-toggle" onClick={changeSearchBoxOpen}><i className="fas fa-filter"></i> FILTER</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loader ?
                                    (<>
                                        <h3 style={{left:'5%'}}>Please Wait....</h3>
                                    </>)
                                    :
                                    (<>
                                        <div className="row row--15">

                                            {getLiveProducts.slice(0, getProductLimit).map((options, index) => {
                                                var wishlistIsHere = checkInWishlist(options._id);
                                                var CartIsHere = checkInCart(options._id);
                                                return <>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="axil-product product-style-one mb--30" style={{ background: "white" }}>
                                                            <div className="thumbnail">
                                                                <a href={`/onlinePharmacyView/${options._id}`}>
                                                                    <LoadPreviewImage imagePath={'product'} image={options.image1} style={{ height: '200px' }} />
                                                                </a>
                                                                <div className="label-block label-right">

                                                                    {
                                                                        options.discountrate != 0.00 && options.discountrate > 0
                                                                            ?
                                                                            <div className="product-badget">₹ {parseFloat(options.discountrate).toFixed(2)} OFF</div>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                                <div className="product-hover-action">
                                                                    <ul className="cart-action">
                                                                        <li className="wishlist"><a href="#" onClick={() => wishlistDetails(options._id)}>
                                                                            {wishlistIsHere == 1 ? <i className="far fa-heart" style={{ fontWeight: 600, color: 'rgb(225, 36, 84)' }}></i> : <i className="far fa-heart"></i>}

                                                                        </a></li>
                                                                        <li className="select-option">

                                                                            <a href="#" onClick={() => addToCartDetails(options._id, options.rate)} style={{ fontSize: "12px", padding: "0", width: "80px", textAlign: "center" }}>

                                                                                {CartIsHere == 1 ? 'Remove' : 'Add to Cart'}

                                                                            </a>
                                                                        </li>
                                                                        <li className="quickview"><a href={`/onlinePharmacyView/${options._id}`}><i className="far fa-eye"></i></a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="product-content">
                                                                <div className="inner">

                                                                    <center>
                                                                        <h5 className="title">
                                                                            <a href={`/onlinePharmacyView/${options._id}`} style={{ fontSize: "14px" }}>
                                                                                {
                                                                                    options.product.length > 25
                                                                                        ?
                                                                                        options.product.charAt(0).toUpperCase() + options.product.slice(1).toLowerCase().substring(0, 17) + "..."
                                                                                        :
                                                                                        options.product.charAt(0).toUpperCase() + options.product.slice(1).toLowerCase()
                                                                                }

                                                                            </a>
                                                                        </h5>
                                                                    </center>

                                                                    <div className="product-price-variant">

                                                                        <center>
                                                                            <span className="price current-price" style={{ fontSize: "12px" }}>₹ {Math.round(options.rate).toFixed(2)}</span>
                                                                            <br /><span class="price old-price" style={{ fontSize: "12px", color: "red" }}>MRP: ₹ {Math.round(options.mrp).toFixed(2)}</span>

                                                                            {
                                                                                options.discountrate != 0.00 && options.discountrate > 0
                                                                                    ?
                                                                                    <>
                                                                                        <span style={{ fontSize: "12px", color: "#1aab2a" }}>₹ {parseFloat(options.discountrate).toFixed(2)} Off</span>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            <br />


                                                                            {
                                                                                CartIsHere == 1
                                                                                    ?
                                                                                    <a href="#" onClick={() => addToCartDetails(options._id, options.rate)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "14px", color: "white", backgroundColor: "green", position: "relative", borderRadius: "4px" }}>Remove</a>
                                                                                    :
                                                                                    <a href="#" onClick={() => addToCartDetails(options._id, options.rate)} style={{ lineHeight: "39px", padding: "0 18px", fontWeight: "600", fontSize: "14px", color: "white", backgroundColor: "#ff497c", position: "relative", borderRadius: "4px" }}>Add</a>
                                                                            }



                                                                        </center>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            })}

                                        </div>
                                    </>)
                                }

                                {
                                    getLiveProducts.length > 12
                                        ?
                                        <div className="text-center pt--20">
                                            <a href="#" className="axil-btn btn-bg-lighter btn-load-more" onClick={loadMoreProducts}>Load more</a>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );

};

export default PharmacyShopPage;