import React from 'react';

import { Link, useParams } from 'react-router-dom';

const ServicesList = () => {

    let { id } = useParams();

    return (
        <>

            <section class="breadcrumb-bg pt-50 pb-80 page_header_bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <div class="page-title">

                                {

                                    id == 1 ?
                                        <h1>General Medicine</h1>
                                        :
                                        id == 2 ?
                                            <h1>General Surgery</h1>
                                            :
                                            id == 3 ?
                                                <h1>Orthopedic</h1>
                                                :
                                                id == 4 ?
                                                    <h1>ENT</h1>
                                                    :
                                                    id == 10 ?
                                                        <h1>Other Specialities</h1>
                                                        :
                                                        id == 6 ?
                                                            <h1>Anaesthetic Treatment & Procedures</h1>
                                                            :
                                                            id == 8 ?
                                                                <h1>Accident & Emergency Care 24/7</h1>
                                                                :
                                                                id == 7 ?
                                                                    <h1>Dental Care</h1>
                                                                    :
                                                                    id == 9 ?
                                                                        <h1>Old Age Care</h1>
                                                                        :
                                                                        id == 12 ?
                                                                            <h1>Insurance</h1>
                                                                            :
                                                                            id == 11 ?
                                                                                <h1>Hospital Facilitate</h1>
                                                                                :
                                                                                id == 13 ?
                                                                                    <h1>For Ambulance Call</h1>
                                                                                    :
                                                                                    null

                                }


                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="page-breadcumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>


                                        {

                                            id == 1 ?
                                                <li class="breadcrumb-item active" aria-current="page">General Medicine</li>
                                                :
                                                id == 2 ?
                                                    <li class="breadcrumb-item active" aria-current="page">General Surgery</li>
                                                    :
                                                    id == 3 ?
                                                        <li class="breadcrumb-item active" aria-current="page">Orthopedic</li>
                                                        :
                                                        id == 4 ?
                                                            <li class="breadcrumb-item active" aria-current="page">ENT</li>
                                                            :
                                                            id == 10 ?
                                                                <li class="breadcrumb-item active" aria-current="page">Other Specialities</li>
                                                                :
                                                                id == 6 ?
                                                                    <li class="breadcrumb-item active" aria-current="page">Anaesthetic Treatment & Procedures</li>
                                                                    :
                                                                    id == 8 ?
                                                                        <li class="breadcrumb-item active" aria-current="page">Accident & Emergency Care 24/7</li>
                                                                        :
                                                                        id == 7 ?
                                                                            <li class="breadcrumb-item active" aria-current="page">Dental Care</li>
                                                                            :
                                                                            id == 9 ?
                                                                                <li class="breadcrumb-item active" aria-current="page">Old Age Care</li>
                                                                                :
                                                                                id == 12 ?
                                                                                    <li class="breadcrumb-item active" aria-current="page">Insurance</li>
                                                                                    :
                                                                                    id == 11 ?
                                                                                        <li class="breadcrumb-item active" aria-current="page">Hospital Facilitate</li>
                                                                                        :
                                                                                        id == 13 ?
                                                                                            <li class="breadcrumb-item active" aria-current="page">For Ambulance Call</li>
                                                                                            :
                                                                                            null

                                        }

                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        {/** <h3>ENT</h3> */}


                        {

                            id == 1 ?
                                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                    The General Medicine department of a well-established hospital has been serving the community for over 60 years with a team of senior doctors who are highly qualified and experienced.
                                    These doctors bring decades of medical knowledge and expertise to the department,
                                    making it one of the most trusted and reliable sources of healthcare in the area.
                                    The department's physicians specialize in the diagnosis and treatment of a wide range of medical conditions, from chronic illnesses like diabetes and hypertension to acute illnesses and injuries.
                                    They use the latest medical technology to ensure that patients receive accurate diagnoses and effective treatment plans.The senior doctors at the General Medicine department are known for their compassionate and patient-centered approach to care.
                                    They take the time to listen to each patient's concerns and work closely with them to develop personalized treatment plans that address their unique needs and goals. In addition to providing exceptional medical care, the department is also dedicated to medical education. The senior doctors in the department are actively involved in teaching and mentoring medical students and residents, as well as conducting studies to advance medical knowledge and improve patient outcomes. Overall, the General Medicine department of this well-established hospital is a pillar of the community, offering high-quality medical care and compassionate support to patients for over 60 years. With senior doctors who are highly qualified and experienced, patients can trust that they are in good hands when seeking medical care at this department.
                                </p>
                                :
                                id == 2 ?
                                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                        The General Surgery department of a well-established hospital has been providing surgical care to patients for over 60 years. With senior doctors who are highly qualified and experienced, this department has earned a reputation as one of the leading surgical departments in the area. The senior doctors in the department bring decades of surgical experience to the table, having performed more than 20,000 successful surgeries at the hospital. They specialize in a wide range of surgical procedures, from routine surgeries such as gallbladder removal and hernia repairs to complex surgeries .
                                        <br /><br />
                                        In addition to their surgical expertise, the senior doctors in the General Surgery department are known for their compassionate and patient-centered approach to care. They take the time to understand each patient's unique needs and concerns and work closely with them to develop personalized treatment plans that achieve the best possible outcomes.
                                        <br /><br />
                                        The department is equipped with the latest surgical technology and equipment, allowing the senior doctors to perform surgeries with the highest degree of precision and safety. They are also actively involved in medical education and research, mentoring the next generation of surgeons.
                                        <br /><br />
                                        Overall, the General Surgery department of this well-established hospital is a cornerstone of surgical care in the community, offering high-quality surgical care and compassionate support to patients for over 60 years. With senior doctors who are highly qualified and experienced, patients can trust that they are in good hands when undergoing surgery at this department.
                                    </p>
                                    :
                                    id == 3 ?
                                        <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                            The orthopedic department at our hospital is 60-year-old and is a testament to the longevity and excellence of the medical field. This department is staffed by a team of highly qualified and experienced doctors who have years of experience in treating orthopedic cases. They are assisted by a team of skilled and dedicated professionals who are committed to providing the best possible care to their patients.
                                            <br /><br />
                                            One of the key features of this department is the modern laminar flow operation theater. This theater is equipped with state-of-the-art technology and equipment to ensure that surgeries are performed with the utmost precision and safety. The senior doctors in this department have conducted over 15,000 successful orthopedic cases in this theater, demonstrating their expertise and dedication to their craft.
                                            <br /><br />
                                            The senior doctors in this department are highly respected in their field and have earned a reputation for their exceptional skills and experience. Patients who come to this department can be assured that they will receive the best possible care from these experts. The senior doctors are always available to answer any questions or concerns that patients may have, and they take the time to explain procedures and treatments in a clear and concise manner.
                                            <br /><br />
                                            In addition to the senior doctors, the hospital also has a team of newer generation highly qualified doctors who come to perform complex surgeries. These doctors bring fresh perspectives and techniques to the department, helping to advance the field of orthopedics even further. They work collaboratively with the senior doctors, sharing knowledge and expertise to ensure that patients receive the best possible care.
                                            <br /><br />
                                            Overall, the orthopedic department in this 60-year-old hospital is a leader in orthopedic care. With a team of highly qualified and experienced senior doctors, state-of-the-art technology and equipment, and a commitment to excellence, patients can be assured that they are receiving the best possible care for their orthopedic needs.
                                        </p>
                                        :
                                        id == 4 ?
                                            <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                ENT (Ear, Nose, and Throat) department of a our hospital is dedicated to providing comprehensive medical care for a range of conditions affecting the head and neck.
                                                The department is staffed by highly trained physicians, surgeons, audiologists, and other healthcare professionals who work together to diagnose and treat a variety of conditions such as hearing loss, sinusitis, tonsillitis, and other conditions of the head and neck. The department is equipped well with equipment and surgical technology to provide patients with the most advanced treatment options available. Whether it's a routine check-up or a complex surgical procedure, the (Ear, Nose, and Throat) department of a our hospital is dedicated to providing comprehensive medical care for a range of conditions affecting the head and neck. The department is staffed by highly trained physicians, surgeons, audiologists, and other healthcare professionals who work together to diagnose and treat a variety of conditions such as hearing loss, sinusitis, tonsillitis, and other conditions of the head and neck.
                                                The department is equipped well with equipment and surgical technology to provide patients with the most advanced treatment options available. Whether it's a routine check-up or a complex surgical procedure, the ENT department is committed to delivering exceptional care to all of its patients.
                                            </p>
                                            :
                                            id == 10 ?
                                                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                    Our hospital is dedicated to providing our patients with a comprehensive range of medical services, including specialties such as plastic surgery, gastroenterology surgery, pediatric surgery, gynecology surgery, and urology surgery. We are proud to have highly qualified visiting surgeons who bring their expertise and experience to our hospital, ensuring that our patients receive the highest quality medical care.
                                                    <br /><br />
                                                    Our visiting plastic surgeons are experts in various types of plastic surgery, including reconstructive surgery, aesthetic surgery, and hand surgery. They use the latest technology and techniques to provide safe and effective plastic surgery to our patients.
                                                    <br /><br />
                                                    Gastroenterology surgery focuses on the diagnosis and treatment of diseases and conditions of the digestive system, including the stomach, liver, and intestines. Our visiting gastroenterology surgeons are experts in performing surgical procedures for treating these conditions, including laparoscopic surgery, minimally invasive surgery, and endoscopic surgery.
                                                    <br /><br />
                                                    Pediatric surgery is a specialty that focuses on surgical procedures for children, from infants to adolescents. Our visiting pediatric surgeons are highly qualified and experienced in performing surgeries for various medical conditions that affect children, including congenital defects and trauma.
                                                    <br /><br />
                                                    Gynecology surgery is a specialty that focuses on surgical procedures for the female reproductive system. Our visiting gynecology surgeons are experts in performing a wide range of surgeries, including hysterectomies, myomectomies, and surgeries for gynecological.
                                                    <br /><br />
                                                    Urology surgery is a specialty that involves the diagnosis and treatment of conditions that affect the urinary tract and male reproductive system. Our visiting urology surgeons are experts in performing surgical procedures for treating these conditions, including prostatectomies, cystectomies, and ureteroscopies.
                                                    <br /><br />
                                                    In summary, our hospital provides patients with access to a comprehensive range of specialties staffed by highly qualified visiting surgeons. Our plastic surgery, gastroenterology surgery, pediatric surgery, gynecology surgery, and urology surgery specialties are dedicated to providing safe and effective medical care to our patients.
                                                </p>
                                                :
                                                id == 6 ?
                                                    <ul class="professinals-list pt-30">
                                                        <li className="textAlign"><i class="fa fa-check"></i>The prime objective of our anaesthetic team is to alleviate pain during and after the surgery.</li>
                                                    </ul>
                                                    :
                                                    id == 8 ?
                                                        <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                            The 24-hour emergency department of a hospital with round the clock doctors and emergency physicians is a vital component of the healthcare services provided to patients. Staffed by highly trained medical professionals, the department is equipped to handle a wide range of medical emergencies, including those related to road traffic accidents (RTA), poisoning, COVID-19, myocardial infarction (MI), and polytrauma.
                                                            <br /><br />
                                                            The doctors and emergency physicians in the department are highly trained in diagnosing and treating patients with RTA-related injuries, such as fractures, head trauma, and spinal cord injuries. They are also equipped to handle cases of poisoning, providing prompt and effective care to patients who have ingested toxic substances.
                                                            <br /><br />
                                                            During the ongoing COVID-19 pandemic, the emergency department plays a critical role in screening, testing, and providing medical care to patients who are infected with the virus. The doctors and emergency physicians in the department have received specialized training and have implemented strict infection control protocols to ensure the safety of patients and staff.
                                                            <br /><br />
                                                            Moreover, the emergency department is well-equipped to diagnose and treat patients with MI, a medical emergency that requires urgent and specialized medical care. They are also trained to provide immediate care to patients with polytrauma, a complex condition that requires multidisciplinary medical attention.
                                                            <br /><br />
                                                            Overall, the 24-hour emergency department with round the clock doctors and emergency physicians is a crucial component of the hospital's healthcare services, providing patients with immediate and life-saving care around the clock. With highly trained medical professionals and advanced medical technology, patients can trust that they will receive the highest quality emergency medical care available for a wide range of medical emergencies.
                                                        </p>
                                                        :
                                                        id == 7 ?
                                                            <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                                The dental department of a hospital is a vital component of the overall healthcare services provided to patients. Staffed by highly trained and experienced dental professionals, the department is equipped with the latest dental technology and equipment to provide a range of dental services to patients.
                                                                <br /><br />
                                                                These services include routine dental check-ups, cleanings, and preventative care, as well as more complex treatments such as restorative work, orthodontics, and cosmetic dentistry. The department also provides emergency dental care for patients who require immediate attention.
                                                                <br /><br />
                                                                In addition to providing exceptional dental care, the department places a strong emphasis on patient education and preventive care. The dental professionals in the department work closely with patients to educate them on good oral hygiene practices and provide them with the tools and resources they need to maintain healthy teeth and gums.
                                                                <br /><br />
                                                                Furthermore, the dental department is integrated with other medical departments in the hospital, enabling patients to receive comprehensive and coordinated care for any dental-related issues that may be affecting their overall health.
                                                                <br /><br />
                                                                Overall, the dental department of a hospital plays a critical role in the overall health and well-being of patients. With highly skilled and experienced dental professionals and advanced dental technology, patients can trust that they will receive the highest quality dental care available.
                                                            </p>
                                                            :
                                                            id == 9 ?
                                                                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                                    In the hustle and bustle of modern hospitals, there is something to be said for the old-fashioned care found in some wards. One such ward is an old care unit in a hospital, where patients receive 24-hour doctor and nursing care in either common wards or separate rooms.
                                                                    <br /><br />
                                                                    This ward is equipped with all the amenities required for a comfortable stay. Patients have access to electricity and water around the clock, which is particularly important for those with medical needs. The common wards and separate rooms provide patients with the privacy and comfort they require during their stay.
                                                                    <br /><br />
                                                                    The doctors on this ward are experienced and qualified, with a wealth of knowledge and expertise to draw from. They are available around the clock to address any concerns or medical needs that may arise. Patients and their families can take comfort in knowing that they are receiving top-notch care.
                                                                    <br /><br />
                                                                    The nursing staff on this ward are equally committed to providing exceptional care. They are skilled and compassionate individuals who understand the importance of going above and beyond to ensure patient comfort and safety. Patients receive regular check-ins, and the nursing staff are always available to provide assistance as needed.
                                                                    <br /><br />
                                                                    Overall, the combination of experienced doctors, skilled nursing staff, and top-notch facilities make this old care ward a special place. Patients can rest assured that they are receiving the best possible care during their stay.

                                                                </p>
                                                                :
                                                                id == 12 ?
                                                                    <ul class="professinals-list pt-30">
                                                                        <li className="textAlign"><i class="fa fa-check"></i>Health insurance policies help you to get a cashless hospitalisation facility. You can route your policy through a TPA.</li>
                                                                    </ul>
                                                                    :
                                                                    id == 11 ?
                                                                        null
                                                                        :
                                                                        id == 13 ?
                                                                            <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                                                He availability of an ambulance service 24/7 in a hospital is crucial to providing patients with immediate medical attention. , the ambulance service is able to quickly respond to medical emergencies and transport patients to the hospital. The availability of an ambulance service 24/7 ensures that patients receive the care they need in a timely and efficient manner, helping to improve patient outcomes and overall healthcare services.
                                                                            </p>
                                                                            :
                                                                            null

                        }



                    </div>
                </div>
            </div>

            <section className="portfolio-area pt-120 pb-90">
                <div className="container">
                    <div className="row">

                        {/* <!-- START PORTFOLIO FILTER AREA --> */}

                        {/* <!-- END PORTFOLIO FILTER AREA --> */}

                    </div>



                </div>
            </section>
        </>
    );
};

export default ServicesList;