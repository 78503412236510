import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
//import Footer from '../../../components/Shared/Footer';
//import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import BlogDetailsArea5 from '../ReturnProcess/returnProcessDetailsArea/ReturnProcessDetailsArea';
import Footer from '../../LayoutPages/Footer/Footer';
import HomeHeader from '../../LayoutPages/Header/Header';

const BlogDetails = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="RETURN POLICY" subtitle="RETURN POLICY" />
         <BlogDetailsArea5/>
         <Footer/>
      </>
   );
};

export default BlogDetails;