import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import DoctorDetailsArea from './DoctorDetailsArea/DoctorDetailsArea';

import HomeHeader from '../../LayoutPages/Header/Header';
import HomeFooter from '../../LayoutPages/Footer/Footer';

const DoctorDetails = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Doctor Details" subtitle="Details" />
            <DoctorDetailsArea/>
            <HomeFooter/>
        </>
    );
};

export default DoctorDetails;