import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl, userDetailsURL, PaymentGatewayURL, RAZOR_PAY_KEY_ID } from "../../../../../src/pages/config";
import { ReactSession } from 'react-client-session';

import CommonPageHeader from '../../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../LayoutPages/Footer/Footer';
import HomeHeader from '../../../LayoutPages/Header/ShopHeader';

import '../../../../tracking.css';

import { UserContext } from '../../../LayoutPages/Header/UserContext';

import HomeHeader2 from '../../../LayoutPages/Header/ShopCategoryHeader';
import Footer2 from '../../../LayoutPages/Footer/PharmacyFooter';

const CheckoutArea = () => {

    const sessionUserId = ReactSession.get("userid");
    const sessionToken = ReactSession.get("token");
    const sessionEmail = ReactSession.get("email");
    const sessionPhone = ReactSession.get("phone");
    const sessionUsername = ReactSession.get("username");

    let { orderid } = useParams();

    const [getProductDetails, setGetProductDetails] = useState([]);
    const [getOrderProductDetails, setGetOrderProductDetails] = useState(1);

    const [userAddress, setUserAddress] = useState('');
    const [userApartment, setUserApartment] = useState('');
    const [userCity, setUserCity] = useState('');
    const [userState, setUserState] = useState('');
    const [userZipcode, setUserZipcode] = useState('');
    const [userEmail, setUserEmail] = useState(sessionEmail);
    const [userPhone, setUserPhone] = useState(sessionPhone);

    const [shippingCost, setShippingCost] = useState(0);

    useEffect(() => {

        showOrderView();

    }, [])

    const showOrderView = () => {
        const orderDetail = {
            orderid: orderid
        };

        axios
            .post(`${PaymentGatewayURL}/showorderdetails`, orderDetail)
            .then(data => {
                console.log(data.data.orderData);
                setShippingCost(data.data.orderData.ShippingCost);
                setUserAddress(data.data.orderData.shippingaddressid.street);
                setUserApartment(data.data.orderData.shippingaddressid.apportment);
                setUserCity(data.data.orderData.shippingaddressid.city);
                setUserState(data.data.orderData.shippingaddressid.state);
                setUserZipcode(data.data.orderData.shippingaddressid.postcode);
                setGetProductDetails(data.data.orderProductData);
                setGetOrderProductDetails(data.data.orderData.shippingstageid);
            })
            .catch(error => {

            });

    }

    var totalAmount = 0;

    for (var i = 0; i < getProductDetails.length; i++) {
        totalAmount = totalAmount + getProductDetails[i].producttotal;
    }

    const [getWishlistTrigger, setGetWishlistTrigger] = useState(0);
    const [getAddToCartTrigger, setGetAddToCartTrigger] = useState(0);

    return (
        <>
            <UserContext.Provider value={{ getWishlistTrigger, setGetWishlistTrigger, getAddToCartTrigger, setGetAddToCartTrigger }}>
                <HomeHeader2 /><br /><br /><br />

                <section className="checkout-area pb-70">
                    <div className="container">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="checkbox-form">
                                        <h3>Billing Details</h3>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="checkout-form-list">
                                                    <label>Address <span className="required">*</span></label>
                                                    <input type="text" value={userAddress} readonly placeholder="Street address" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="checkout-form-list">
                                                    <input type="text" value={userApartment} readonly placeholder="Apartment, suite, unit etc. (optional)" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="checkout-form-list">
                                                    <label>Town / City <span className="required">*</span></label>
                                                    <input type="text" value={userCity} readonly placeholder="Town / City" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>State <span className="required">*</span></label>
                                                    <input type="text" value={userState} readonly placeholder="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Postcode / Zip <span className="required">*</span></label>
                                                    <input type="text" value={userZipcode} readonly placeholder="Postcode / Zip" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Email Address <span className="required">*</span></label>
                                                    <input type="email" value={userEmail} readonly placeholder="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Phone <span className="required">*</span></label>
                                                    <input type="text" value={userPhone} readonly placeholder="Postcode / Zip" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="your-order mb-30 ">
                                        <h3>Your order</h3>
                                        <div className="your-order-table table-responsive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Product</th>
                                                        <th className="product-total">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {

                                                        getProductDetails.map((options, index) => {

                                                            return <>

                                                                <tr className="cart_item">
                                                                    <td className="product-name">
                                                                        {options.productid.product} <strong className="product-quantity"> × {options.productqty}</strong>
                                                                    </td>
                                                                    <td className="product-total">
                                                                        <span className="amount">Rs {options.producttotal}</span>
                                                                    </td>
                                                                </tr>
                                                            </>

                                                        })}

                                                </tbody>
                                                <tfoot>
                                                    <tr className="order-total">
                                                        <th>Order Total</th>
                                                        <td><strong><span className="amount">Rs {parseInt(totalAmount).toFixed(2)}</span></strong>
                                                        </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <th>Shipping Cost</th>
                                                        <td><strong><span className="amount">Rs {shippingCost}</span></strong>
                                                        </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <th>Total Amount</th>
                                                        <td><strong><span className="amount">Rs {Math.round(parseInt(totalAmount) + parseInt(shippingCost)).toFixed(2)}</span></strong>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>

                                    {getOrderProductDetails ?

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                                                    <div className="row justify-content-between">

                                                        {getOrderProductDetails == 1

                                                            ?
                                                            <>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Ordered</p>
                                                                </div>
                                                                <div className="order-tracking">
                                                                    <span className="is-complete"></span>
                                                                    <p>Shipped</p>
                                                                </div>
                                                                <div className="order-tracking">
                                                                    <span className="is-complete"></span>
                                                                    <p>Delivered</p>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {getOrderProductDetails == 2

                                                            ?
                                                            <>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Ordered</p>
                                                                </div>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Shipped</p>
                                                                </div>
                                                                <div className="order-tracking">
                                                                    <span className="is-complete"></span>
                                                                    <p>Delivered</p>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {getOrderProductDetails == 3

                                                            ?
                                                            <>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Ordered</p>
                                                                </div>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Shipped</p>
                                                                </div>
                                                                <div className="order-tracking completed">
                                                                    <span className="is-complete"></span>
                                                                    <p>Delivered</p>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }



                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        null

                                    }


                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </UserContext.Provider>
            <Footer2 />
        </>
    );
};

export default CheckoutArea;