import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import RegisteArea from './RegisteArea/RegisteArea';

//import Header from '../LayoutPages/Header/Header';
import Header from '../LayoutPages/Header/Header';
import Footer from '../LayoutPages/Footer/Footer';

import axios from 'axios';
import { userURL } from "../config";

import { ReactSession } from 'react-client-session';

const Register = () => {

    const token = ReactSession.get("token");

    if (token) {
        window.location.href = "/shop/1/1/0/0/0/0/0";
    }

    let { id } = useParams();

    const [mobileValue, setMobileValue] = useState();

    const setInputMobileValue = event => {

        const result = event.target.value;
        setMobileValue(result);

    };

    function authKeyGeneration(id, userName, email, phone) {

        var productDetail = {
            userId: id,
            userName: userName,
            email: email,
            phone: phone
        };

        axios
            .post(`${userURL}/user/api/tokenCreate`, productDetail)
            .then(data => {

                ReactSession.set("token", data.data.token);

                const token = ReactSession.get("token");

                if (token) {
                    registeredWelcomeMsg(userName, email, phone)
                }



            })
            .catch(error => {

            });

    }

    function registeredWelcomeMsg(userName, email, phone) {
        var userDetail = {
            username: userName,
            email: email,
            phonenumber: phone
        };
        console.log(userDetail);
        axios
            .post(`${userURL}/user/api/registeredWelcomeMsg`, userDetail)
            .then(data => {

                if (data.data.status == 200) {

                    window.location.href = "/";

                }

            })
            .catch(error => {

            });

    }

    const checkMobileOtp = () => {

        var productDetail = {
            userId: id,
            phoneOtp: mobileValue
        };

        axios
            .post(`${userURL}/user/phoneOtpValidation`, productDetail)
            .then(data => {
                //  if (data.data.message == "Done") {

                ReactSession.set("userid", id);
                ReactSession.set("username", data.data.data.username);
                ReactSession.set("email", data.data.data.email);
                ReactSession.set("phone", data.data.data.phone);

                const username = ReactSession.get("username");

                if (username) {
                    authKeyGeneration(id, data.data.data.username, data.data.data.email, data.data.data.phone);
                }

                //  }

            })
            .catch(error => {

            });

    };

    return (
        <>
            <Header />
            {/** <CommonPageHeader title="Phone OTP" subtitle="Phone OTP" /> */}

            <section className="login-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="basic-login">
                                <h3 className="text-center">Enter SMS OTP</h3>
                                <div className='col' style={{ margin: '20px' }}></div>
                                <br />
                                <form>
                                    <center>
                                        <div className="field">
                                            <input required id="name" type="text" style={{ width: "300px", marginBottom: 0 }} placeholder='Enter SMS OTP' value={mobileValue} onChange={setInputMobileValue} className="" autoComplete="off" maxlength="5" />
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <div className='col-g' style={{ margin: '80px' }}></div>
                                        <button type='button' className="primary_btn theme-btn-2" style={{ width: '200px' }} onClick={checkMobileOtp}>Verify OTP</button>
                                    </center>

                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br /><br /><br /><br />

            <Footer />
        </>
    );
};

export default Register;