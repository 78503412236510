import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';
import Swal from 'sweetalert2';
import axios from 'axios';
import { userURL } from "../../config";


const RegisteArea = () => {


   const { register, handleSubmit, formState: { errors } } = useForm();
   const [showEmailError, setShowEmailError] = useState(false);
   const [showMobileError, setShowMobileError] = useState(false);
   const [showConfirmError, setShowConfirmError] = useState(false);

   useEffect(() => {


   }, [])


   const setcheckMailValue = event => {

      if (event.target.value) {
         var productDetail = {
            email: event.target.value
         };

         axios
            .post(`${userURL}/user/getEmailForValidation`, productDetail)
            .then(data => {

               if (data.data.data > 0) {

                  document.getElementById("email-id").value = "";
                  setShowEmailError(true);

               }
               else {

                  setShowEmailError(false);

               }

            })
            .catch(error => {

            });
      }

   }

   const setcheckMobileValue = event => {

      if (event.target.value) {
         var productDetail = {
            phoneno: event.target.value
         };

         axios
            .post(`${userURL}/user/getPhonenoForValidation`, productDetail)
            .then(data => {

               if (data.data.data > 0) {

                  document.getElementById("phone").value = "";
                  setShowMobileError(true);

               }
               else {

                  setShowMobileError(false);

               }

            })
            .catch(error => {

            });
      }

   }

   const onSubmit = (data) => {
      if ((data.fuserpassword == data.fconfirmpassword) && (data.fuserpassword != "" && data.fconfirmpassword != "")) {
         var productDetail = {
            username: data.fusername,
            email: data.femailAdd,
            phonenumber: data.fphonenumber,
            userpassword: data.fuserpassword
         };

         axios
            .post(`${userURL}/user/insertUserDetails`, productDetail)
            .then(data => {
               console.log(data.data);
               if (data.data.status == 200) {

                  //window.location.href = "/emailOtp/" + data.data.lastid;
                  window.location.href = "/phoneOtp/" + data.data.lastid;

               }

            })
            .catch(error => {

            });

      }
      else if((data.fuserpassword != data.fconfirmpassword)) {
         
         document.getElementById("cpass").value = "";
         setShowConfirmError(true);
         
      }

   }

   return (
      <>
         <section className="login-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                     <div className="basic-login">
                        <h3 className="text-center mb-60">Signup</h3>

                        <Form onSubmit={handleSubmit(onSubmit)}>

                           <Form.Field>
                              <label htmlFor="name" className='mb-2' >Username <span>**</span></label>
                              <input id="name" type="text"{...register("fusername", { required: true })} autoComplete="off" placeholder="Enter Username..." />
                           </Form.Field>
                           {errors.fusername && <p style={{ color: "red" }}>User name is required</p>}

                           <Form.Field>

                              <label htmlFor="email-id" className='mb-2'>Email Address <span>**</span></label>
                              <input id="email-id" name="email" {...register("femailAdd", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} autoComplete="off" onBlur={setcheckMailValue} type="text"
                                 placeholder="Email address..." />

                           </Form.Field>
                           {errors.femailAdd && <p style={{ color: "red" }}>Please Enter Valid Email</p>}
                           {showEmailError ? <p style={{ color: "red" }}>Email Already Present</p> : null}

                           <Form.Field>
                              <label htmlFor="phone" className='mb-2' >Phone <span>**</span></label>
                              <input id="phone" name="phone" {...register("fphonenumber", { required: true, minLength: 10, maxLength: 10,  })} autoComplete="off" onBlur={setcheckMobileValue} placeholder="Enter Phone Number..." type="text"/>
                           </Form.Field>
                           {errors.femailAdd && <p style={{ color: "red" }}>Please Enter Valid phone number</p>}
                           {showMobileError ? <p style={{ color: "red" }}>Phone number Already Present</p> : null}

                           <Form.Field>
                              <label htmlFor="pass" className='mb-2'>Password <span>**</span></label>
                              <input id="pass" name="password" {...register("fuserpassword", { required: true })} autoComplete="off" type="password"
                                 placeholder="Enter password..." />
                           </Form.Field>
                           {errors.femailAdd && <p style={{ color: "red" }}>Password is required </p>}

                           <Form.Field>
                              <label htmlFor="cpass" className='mb-2'>Confirm Password <span>**</span></label>
                              <input id="cpass" name="confirmpassword" {...register("fconfirmpassword", { required: true })} autoComplete="off" type="password"
                                 placeholder="Enter password..." />
                           </Form.Field>
                           {errors.femailAdd && <p style={{ color: "red" }}>Confirm password is required</p>}
                           {showConfirmError ? <p style={{ color: "red" }}>Password and Confirm password must be same</p> : null}
                           <div className="mt-10"></div>
                           <button type='submit' className="primary_btn theme-btn-2 w-100">Register Now</button>
                           <div className="or-divide"><span>or</span></div>
                           <Link to="/login"><button className="primary_btn btn-icon-green w-100">login Now</button></Link>

                        </Form>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default RegisteArea;