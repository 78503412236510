import React from 'react';

const HomeFooter = () => {
    return (
        <>
            <div>
                <footer className="axil-footer-area footer-style-2">
                    <div className="footer-top separator-top" style={{background: 'rgb(244, 249, 252)', borderStyle: 'groove', borderWidth: '1px', padding: '10px'}}>
                        <div className="container">
                            <div className="copyright-area copyright-default separator-top">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-xl-4">
                                            <div className="social-share">
                                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                                <a href="#"><i className="fab fa-discord"></i></a>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-12">
                                            <div className="copyright-left d-flex flex-wrap justify-content-center">
                                                <ul className="quick-link">
                                                    <li> All rights reserved by <a target="_blank" href="#">Lysander Hospital</a>.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-12">
                                            <div className="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center">
                                                <ul className="payment-icons-bottom quick-link">
                                                    <li><a href="/terms">Terms and Conditions</a></li>
                                                    <li><a href="/privacy">Privacy Policy</a></li>
                                                    <li><a href="/shippingCharge">Shipping Charge</a></li>
                                                    <li><a href="/refund">Refund Policy</a></li>
                                                    <li><a href="/return">Return Policy</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="modal fade quick-view-product" id="quick-view-modal" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="far fa-times"></i></button>
                            </div>
                            <div className="modal-body">
                                <div className="single-product-thumb">
                                    <div className="row">
                                        <div className="col-lg-7 mb--40">
                                            <div className="row">
                                                <div className="col-lg-10 order-lg-2">
                                                    <div className="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery">
                                                        <div className="thumbnail">
                                                            <img src="assets/images/product/product-big-01.png" alt="Product Images" />
                                                            <div className="label-block label-right">
                                                                <div className="product-badget">20% OFF</div>
                                                            </div>
                                                            <div className="product-quick-view position-view">
                                                                <a href="assets/images/product/product-big-01.png" className="popup-zoom">
                                                                    <i className="far fa-search-plus"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="thumbnail">
                                                            <img src="assets/images/product/product-big-02.png" alt="Product Images" />
                                                            <div className="label-block label-right">
                                                                <div className="product-badget">20% OFF</div>
                                                            </div>
                                                            <div className="product-quick-view position-view">
                                                                <a href="assets/images/product/product-big-02.png" className="popup-zoom">
                                                                    <i className="far fa-search-plus"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="thumbnail">
                                                            <img src="assets/images/product/product-big-03.png" alt="Product Images" />
                                                            <div className="label-block label-right">
                                                                <div className="product-badget">20% OFF</div>
                                                            </div>
                                                            <div className="product-quick-view position-view">
                                                                <a href="assets/images/product/product-big-03.png" className="popup-zoom">
                                                                    <i className="far fa-search-plus"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 order-lg-1">
                                                    <div className="product-small-thumb small-thumb-wrapper">
                                                        <div className="small-thumb-img">
                                                            <img src="assets/images/product/product-thumb/thumb-08.png" alt="thumb image" />
                                                        </div>
                                                        <div className="small-thumb-img">
                                                            <img src="assets/images/product/product-thumb/thumb-07.png" alt="thumb image" />
                                                        </div>
                                                        <div className="small-thumb-img">
                                                            <img src="assets/images/product/product-thumb/thumb-09.png" alt="thumb image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 mb--40">
                                            <div className="single-product-content">
                                                <div className="inner">
                                                    <div className="product-rating">
                                                        <div className="star-rating">
                                                            <img src="assets/images/icons/rate.png" alt="Rate Images" />
                                                        </div>
                                                        <div className="review-link">
                                                            <a href="#">(<span>1</span> customer reviews)</a>
                                                        </div>
                                                    </div>
                                                    <h3 className="product-title">Serif Coffee Table</h3>
                                                    <span className="price-amount">$155.00 - $255.00</span>
                                                    <ul className="product-meta">
                                                        <li><i className="fal fa-check"></i>In stock</li>
                                                        <li><i className="fal fa-check"></i>Free delivery available</li>
                                                        <li><i className="fal fa-check"></i>Sales 30% Off Use Code: MOTIVE30</li>
                                                    </ul>
                                                    <p className="description">In ornare lorem ut est dapibus, ut tincidunt nisi pretium. Integer ante est, elementum eget magna. Pellentesque sagittis dictum libero, eu dignissim tellus.</p>

                                                    <div className="product-variations-wrapper">

                                                        <div className="product-variation">
                                                            <h6 className="title">Colors:</h6>
                                                            <div className="color-variant-wrapper">
                                                                <ul className="color-variant mt--0">
                                                                    <li className="color-extra-01 active"><span><span className="color"></span></span>
                                                                    </li>
                                                                    <li className="color-extra-02"><span><span className="color"></span></span>
                                                                    </li>
                                                                    <li className="color-extra-03"><span><span className="color"></span></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="product-variation">
                                                            <h6 className="title">Size:</h6>
                                                            <ul className="range-variant">
                                                                <li>xs</li>
                                                                <li>s</li>
                                                                <li>m</li>
                                                                <li>l</li>
                                                                <li>xl</li>
                                                            </ul>
                                                        </div>

                                                    </div>

                                                    <div className="product-action-wrapper d-flex-center">
                                                        <div className="pro-qty"><input type="text" value="1" /></div>

                                                        <ul className="product-action d-flex-center mb--0">
                                                            <li className="add-to-cart"><a href="cart.html" className="axil-btn btn-bg-primary">Add to Cart</a></li>
                                                            <li className="wishlist"><a href="wishlist.html" className="axil-btn wishlist-btn"><i className="far fa-heart"></i></a></li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-search-modal" id="header-search-modal">
                    <button className="card-close sidebar-close"><i className="fas fa-times"></i></button>
                    <div className="header-search-wrap">
                        <div className="card-header">
                            <form action="#">
                                <div className="input-group">
                                    <input type="search" className="form-control" name="prod-search" id="prod-search" placeholder="Write Something...." />
                                    <button type="submit" className="axil-btn btn-bg-primary"><i className="far fa-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            <div className="search-result-header">
                                <h6 className="title">24 Result Found</h6>
                                <a href="shop.html" className="view-all">View All</a>
                            </div>
                            <div className="psearch-results">
                                <div className="axil-product-list">
                                    <div className="thumbnail">
                                        <a href="single-product.html">
                                            <img src="assets/images/product/electric/product-09.png" alt="Yantiti Leather Bags" />
                                        </a>
                                    </div>
                                    <div className="product-content">
                                        <div className="product-rating">
                                            <span className="rating-icon">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fal fa-star"></i>
                                            </span>
                                            <span className="rating-number"><span>100+</span> Reviews</span>
                                        </div>
                                        <h6 className="product-title"><a href="single-product.html">Media Remote</a></h6>
                                        <div className="product-price-variant">
                                            <span className="price current-price">$29.99</span>
                                            <span className="price old-price">$49.99</span>
                                        </div>
                                        <div className="product-cart">
                                            <a href="cart.html" className="cart-btn"><i className="fal fa-shopping-cart"></i></a>
                                            <a href="wishlist.html" className="cart-btn"><i className="fal fa-heart"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="axil-product-list">
                                    <div className="thumbnail">
                                        <a href="single-product.html">
                                            <img src="assets/images/product/electric/product-09.png" alt="Yantiti Leather Bags" />
                                        </a>
                                    </div>
                                    <div className="product-content">
                                        <div className="product-rating">
                                            <span className="rating-icon">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fal fa-star"></i>
                                            </span>
                                            <span className="rating-number"><span>100+</span> Reviews</span>
                                        </div>
                                        <h6 className="product-title"><a href="single-product.html">Media Remote</a></h6>
                                        <div className="product-price-variant">
                                            <span className="price current-price">$29.99</span>
                                            <span className="price old-price">$49.99</span>
                                        </div>
                                        <div className="product-cart">
                                            <a href="cart.html" className="cart-btn"><i className="fal fa-shopping-cart"></i></a>
                                            <a href="wishlist.html" className="cart-btn"><i className="fal fa-heart"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="cart-dropdown" id="cart-dropdown">
                    <div className="cart-content-wrap">
                        <div className="cart-header">
                            <h2 className="header-title">Cart review</h2>
                            <button className="cart-close sidebar-close"><i className="fas fa-times"></i></button>
                        </div>
                        <div className="cart-body">
                            <ul className="cart-item-list">
                                <li className="cart-item">
                                    <div className="item-img">
                                        <a href="single-product.html"><img src="assets/images/product/electric/product-01.png" alt="Commodo Blown Lamp" /></a>
                                        <button className="close-btn"><i className="fas fa-times"></i></button>
                                    </div>
                                    <div className="item-content">
                                        <div className="product-rating">
                                            <span className="icon">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </span>
                                            <span className="rating-number">(64)</span>
                                        </div>
                                        <h3 className="item-title"><a href="single-product-3.html">Wireless PS Handler</a></h3>
                                        <div className="item-price"><span className="currency-symbol">$</span>155.00</div>
                                        <div className="pro-qty item-quantity">
                                            <input type="number" className="quantity-input" value="15" />
                                        </div>
                                    </div>
                                </li>
                                <li className="cart-item">
                                    <div className="item-img">
                                        <a href="single-product-2.html"><img src="assets/images/product/electric/product-02.png" alt="Commodo Blown Lamp" /></a>
                                        <button className="close-btn"><i className="fas fa-times"></i></button>
                                    </div>
                                    <div className="item-content">
                                        <div className="product-rating">
                                            <span className="icon">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </span>
                                            <span className="rating-number">(4)</span>
                                        </div>
                                        <h3 className="item-title"><a href="single-product-2.html">Gradient Light Keyboard</a></h3>
                                        <div className="item-price"><span className="currency-symbol">$</span>255.00</div>
                                        <div className="pro-qty item-quantity">
                                            <input type="number" className="quantity-input" value="5" />
                                        </div>
                                    </div>
                                </li>
                                <li className="cart-item">
                                    <div className="item-img">
                                        <a href="single-product-3.html"><img src="assets/images/product/electric/product-03.png" alt="Commodo Blown Lamp" /></a>
                                        <button className="close-btn"><i className="fas fa-times"></i></button>
                                    </div>
                                    <div className="item-content">
                                        <div className="product-rating">
                                            <span className="icon">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </span>
                                            <span className="rating-number">(6)</span>
                                        </div>
                                        <h3 className="item-title"><a href="single-product.html">HD CC Camera</a></h3>
                                        <div className="item-price"><span className="currency-symbol">$</span>200.00</div>
                                        <div className="pro-qty item-quantity">
                                            <input type="number" className="quantity-input" value="100" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="cart-footer">
                            <h3 className="cart-subtotal">
                                <span className="subtotal-title">Subtotal:</span>
                                <span className="subtotal-amount">$610.00</span>
                            </h3>
                            <div className="group-btn">
                                <a href="cart.html" className="axil-btn btn-bg-primary viewcart-btn">View Cart</a>
                                <a href="checkout.html" className="axil-btn btn-bg-secondary checkout-btn">Checkout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeFooter;